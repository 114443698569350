import {
  ON_ADD_BILLING,
  ON_ADD_SHIPING,
  ON_ADD_ORDER,
  CLEAR_CHECKOUT
} from "../actions/types";

const Checkout = (
  state = {
    billingValues: null,
    shippingValues: null,
    fob: null,
    tradeAssurance: null,
    cuponCode: null
  },
  action
) => {
  switch (action.type) {
    case ON_ADD_BILLING:
      return {
        ...state,
        billingValues: action.payload
      };
    case ON_ADD_SHIPING:
      return {
        ...state,
        billingValues: state.billingValues,
        shippingValues: action.payload
      };
    case ON_ADD_ORDER:
      return {
        ...state,
        billingValues: state.billingValues,
        fob: action.payload.fob,
        tradeAssurance: action.payload.tradeAssurance,
        cuponCode: action.payload.cuponCode
      };
    case CLEAR_CHECKOUT:
      return {
        ...state,
        billingValues: null,
        fob: null,
        tradeAssurance: null,
        cuponCode: null
      };
    default:
      return state;
  }
};

export default Checkout;

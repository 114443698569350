import _ from "lodash";
export const isImporter = user => {
  console.log("user", user);
  if (user) {
    const isImporter = _.some(user.roles, r => r.name === "importer");
    if (!isImporter) {
      return false;
    }
    return true;
  } else {
    return false;
  }
};

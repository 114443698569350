import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import getSymbolFromCurrency from "currency-symbol-map";
import axios from "axios";
import _ from "lodash";
export default function Price({ price }) {
  const { currency } = useSelector(state => state.currency);
  const [selectedPrice, setSelectedPrice] = useState(price);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (currency.code !== "USD") {
      setLoading(true);
      axios
        .get("https://data.fixer.io/api/convert", {
          params: {
            access_key: "d01ed1c97e356b8c40f749ea118f1803",
            from: "USD",
            to: currency.code,
            amount: price
          }
        })
        .then(res => {
          setLoading(false);
          if (res.data && res.data.result) {
            setSelectedPrice(res.data.result);
          } else {
            setSelectedPrice(price);
          }
        })
        .catch(e => {
          setLoading(false);
          setSelectedPrice(price);
        });
    } else {
      setLoading(false);
      setSelectedPrice(price);
    }
  }, [currency, price]);
  return (
    <React.Fragment>
      {loading ? (
        "loading..."
      ) : (
        <React.Fragment>
          <span> {getSymbolFromCurrency(currency.code)} </span>
          <span> {_.round(selectedPrice, 2)} </span>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

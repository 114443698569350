import {
  SEARCH,
  IMG_SEARCH,
  CHANGE_IN_STOCK,
  CHANGE_OUT_STOCK,
  CHANGE_READY_TO_SHIP,
  CHANGE_NOT_TREADY_TO_SHIP,
  CHANGE_UNDER_500,
  CLEAR_CAT
} from "../actions/types";

const INITIAL_STATE = {
  term: "",
  categorie: null,
  img: null,
  imgUrl: null,
  inStock: false,
  outStock: false,
  readyToShip: false,
  notReady: false,
  isCategorySelected: false
};

function searchReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SEARCH:
      if (action.payload.categorie === "All Categories") {
        return {
          ...state,
          term: action.payload.term,
          categorie: null
        };
      }
      console.log(console.log(action.payload));
      return {
        ...state,
        term: action.payload.term,
        categorie: action.payload.categorie
          ? action.payload.categorie.slug
          : null
      };
    case IMG_SEARCH:
      return {
        ...state,
        img: action.payload,
        imgUrl: action.imgUrl
      };
    case CLEAR_CAT:
      return {
        ...state,
        categorie: null
      };
    case "ON_SELECT_CATEGORY":
      return {
        ...state,
        isCategorySelected: true
      };
    case "ON_LEAVE_SEARCH_CATEGORY_CLEAR":
      return {
        ...state,
        isCategorySelected: false
      };
    case CHANGE_IN_STOCK:
      return {
        ...state,
        inStock: !state.inStock,
        outStock: false
      };
    case CHANGE_OUT_STOCK:
      return {
        ...state,
        outStock: !state.outStock,
        inStock: false
      };
    case CHANGE_READY_TO_SHIP:
      return {
        ...state,
        readyToShip: !state.readyToShip,
        notReady: false
      };
    case CHANGE_NOT_TREADY_TO_SHIP:
      return {
        ...state,
        notReady: !state.notReady,
        readyToShip: false
      };
    default:
      return state;
  }
}

export default searchReducer;

export default class cartItem {
  constructor(
    quantity,
    id,
    isSample,
    description,
    sample_quantity,
    price,
    isEditable,
    rfqId,
    milestoneId,
    fob_price,
    customize_option,
    shipment,
    name,
    images,
    minimum_quantity,
    range_price_from,
    range_price_to,
    sample_price,
    rating,
    regular_price,
    slug,
    url,
    stock,
    company_name,
    company_mail,
    company_phone_number,
    exporter_name,
    exporter_title,
    company_location,
    trade_accurance,
    fob,
    trade_value
  ) {
    this.quantity = quantity;
    this.id = id;
    this.isSample = isSample;
    this.company_location = company_location;
    this.rfqId = rfqId;
    this.company_name = company_name;
    this.company_mail = company_mail;
    this.company_phone_number = company_phone_number;
    this.exporter_name = exporter_name;
    this.milestoneId = milestoneId;
    this.fob_price = fob_price;
    this.isEditable = isEditable;
    this.sample_price = sample_price;
    this.customize_option = customize_option;
    this.name = name;
    this.sample_quantity = sample_quantity;
    this.shipment = shipment;
    this.description = description;
    this.images = images;
    this.minimum_quantity = minimum_quantity;
    this.exporter_title = exporter_title;
    this.range_price_to = range_price_to;
    this.range_price_from = range_price_from;
    this.rating = rating;
    this.regular_price = regular_price;
    this.slug = slug;
    this.stock = stock;
    this.url = url;
    this.trade_accurance = trade_accurance;
    this.fob = fob;
    this.trade_value = trade_value;
    this.price = price;
  }
}

import React from "react";
class ErrorBoundary extends React.Component {
  state = { hasError: false };
  static getDerivedStateFromError(error) {
    console.log("fucke error", error);
    return { hasError: true };
  }
  render() {
    if (this.state.hasError) {
      return (
        <div className="container">
          <div className="mt-5 text-center">
            <img
              style={{ height: "70vh" }}
              src={require("../../assets/svg/bug_fixing_oc7a.svg")}
            />
            <h2> Sorry. </h2>
            <p> Opps ! Looks like somthing went wrong </p>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;

const tr = {
  nav: {
    home: "Anasayfa",
    services: "Hizmetler",
    aboutUs: "Hakkımızda ",
    connectUs: "İletişim",
    plans: "Üyelik Planları",
    abuse: "Kötüye Kullanım Bildirimi",
    news: "HABERLER",
    currancy: {
      dollar: "Dolar",
    },
    auth: "Oturum Aç / Kayıt",
    login: "Oturum Aç",
    reqister: "Kayıt",
  },
  searchErrorMessage: "Metin Girme veya Resim Yükleme",
  categorie: "Kategoriler",
  search: "Arama",
  otherCategories: "Diğer Kategoriler",
  allCategories: "Tüm Kategoriler",
  share: "Paylaş ",
  login: {
    login: "Oturum Aç",
    signup: "Kaydol",
    email: "E-Mail",
    password: "Parola",
    forgetPassword: "Parolanızı mı unuttunuz?",
    required: "Sorgula",
    emailNotValid: "Geçersiz e-posta adresi",
    google: "Google",
    profile: "Profil",
    logout: "Çıkış Yap",
    loginMessage: "Giriş Mesajı",
  },
  sectionsTitle: {
    hotDeals: "SICAK TEKLİFLER",
    forLimitTime: "Sınırlı Süresi Olan",
    bestSeller: "En Çok Satanlar",
    dealsOf: "FIRSATLARI",
    theMonth: "AYIN",
    productsCategories: "ÜRÜN KATEGORİLERİ",
    readyToShip: "GÖNDERİME HAZIR",
    specialItems: "Özel Ürünler",
  },
  bestQuotationForm: {
    bestQuotationTitle: "En İyi Fiyat Teklifi",
    name: "ad",
    phone: "Telefon numarası",
    country: "ülke",
    email: "E-posta",
    productName: "Ürün Adı",
    unitAmount: "Miktar",
    description: "Tanım",
    addressTo: "Gönder",
    paymentTerms: "Ödeme Koşulları",
    submitButtonText: "İstek",
    Date_per_each_mile_stone: "Dönüm Noktası",
    hint:
      "Bir ürünle mi ilgileniyorsunuz? Formu doldurun ve size hakkında bilgi almak istediğiniz ürüne yönelik ayrıntılı fiyatlandırma göndereceğiz.",
    requestPricing: "Fiyatlandırma Talebi",
    date: "Tarih",
    cachOnDelivery: "Kapıda Ödeme",
    inAdvance: "Peşin Ödeme",
    byInstallment: "Taksitle Ödeme",
  },
  brands: { title: "Başarı Ortakları" },
  featurs: {
    importing: {
      title: "İTHALAT",
      text:
        "Ürün tedariği yapmak için birçok ülkeye yorucu seyahatler yapmanıza ve bunun için zaman harcamanıza artık gerek yok; uluslararası ticaret için çevrimiçi toptan platformumuzu kullanarak ürün tedariğine başlamanız yeterli! GIT-ZONE.com geniş yelpazedeki ürün kategorilerine ve farklı lokasyonlardan binlerce tedarikçiye ulaşmanızı sağlıyor.",
    },
    exporting: {
      title: "İHRACAT",
      text:
        "Ürününüze uluslararası sergileme imkanı verin ve çevrimiçi pazarda satışa başlayın. Toptan çevrimiçi pazarlar, ürün sergileme ve karlılığı yükseltme konusunda kanıtlanmış bir sicile sahiptir. GIT-ZONE.com, ürünlerinizi sergileyebileceğiniz ve alıcılarla kolay ve güvenli şekilde iletişim kurabileceğiniz bir platform sağlıyor.buradan ulaşabilirsiniz. ",
    },
    investment: {
      title: "YATIRIM",
      text:
        "Ortadoğu’nun ilk e-ticaret platformu olan GIT-ZONE.com Afrika ve Avrupa’yla birlikte dünyanın tüm bölgelerini kapsıyor. Herhangi bir ülkede herhangi bir işe yatırım yapmak istiyorsanız, size doğru iş planını sunarak hayallerinizi gerçekleştirmenize destek oluyoruz.",
    },
    tradeAssurance: {
      title: "TİCARET GÜVENCESİ",
      text:
        "Ticarete GÜVEN’i yeniden getiriyoruz. İster alıcı, ister satıcı olun denetlemeyi sizin adınıza biz yapıyoruz. Ticaret Güvencesi hizmetimiz tüm taraflar için ticari işlemlerde sahtekarlığı önlüyor!",
    },
  },

  shipmentQuotation: {
    title: "Tasarruf edin ve GIT-ZONE.com ile gönderin",
    productName: "Ürün adı",
    categories: "KATEGORİLERİ",
    quantity: "Miktar",
    toLocation: "Nereye",
    fromLocation: "Nereden",

    productLink: "Ürün Linki",
    requestShipment: "Gönderi Talebi",
    hint:
      "RFS: Ürün sevkiyatınızda size yardım edeceğiz. Formu doldurun ve size sevkiyata yönelik ayrıntılı fiyatlandırma göndereceğiz.",
    shippingMethods: {
      shippingMethods: "Kargo Seçenekleri",
      landTransportation: "Kara Yolu Taşımacılığı",
      airTransportation: " Hava Yolu Taşımacılığı",
      oceanTransportation: "Deniz Yolu Taşımacılığı",
    },
    /*submitButtonText: "Kaydet"*/
    submitButtonText: "İhracatçı’dan Fiyat Tektifi Talebi",
  },
  newsLetter: {
    title: "Gelişmelerden haberdar olmak için üye ol!",
    form: { email: "E-posta adresinizi girin", submit: "Kaydet" },
    keepInTouch: "Takip Et!",
  },
  footer: {
    info:
      "Hem yerel hem de küresel piyasalarda alıcılar ve tedarikçiler arasında bağlantı kurmak için Orta Doğu’daki ilk E-ticaret platformudur.",
    company: {
      title: "GIT-ZONE.com Hakkında",
      siteMap: "Site Haritası",
      manual: "Kullanım Rehberi",
      tutorial: "Kılavuz",
      aboutUs: "Hakkımızda",
    },
    policyInfo: {
      title: "GIT-ZONE.com Politikaları",
      faq: "Sıkça Sorulan Sorular ",
      privacy: "Gizlilik Politikası",
      terms: "Şartlar",
      plans: "Üyelik Planları",
    },
  },
  formValidation: {
    productRequried: "Ürün Adı Gerekli",
    categoryRequired: "Kategori Gerekli",
    quantityRequired: "Gerekli Miktar",
    fromLocationRequired: "Nereden ",
    toLocationRequired: "Nereye",
    shippingMothodRequired: "Nakliye Yöntemi Gerekli",
    unitAmountRequired: "Birim Miktarı",
    descriptionRequired: "Açıklama Gerekli",
    addressRequired: "Adres Gerekli",
    emailRequired: "E-posta Adresi: (Gerekli",
    emailInvalid: "E-posta Geçerli Değil",
    passwordRequired: "Şifre gerekli",
    paymentTermsRequired: "Ödeme Koşulları Gerekli",
    dateRequired: "Tarih Gerekli",
    productUrlRequired: "Ürün URL'si Gerekli",
    urlNotValid: "URL Geçerli Değil",
  },
  conntactus: {
    leaveMessage: "Sorunuz var mı? Yardımcı olalım!",
    title: "Destek Ekibine İhtiyacınız Olursa, Talebinizle İlgileneceğiz !",
    fristName: "Ad Soyad",
    email: "Email",
    phoneNumber: "Telefon Numarası",
    messageType: "Mesaj Türü",
    subject: "Konu",
    yourMessage: "Mesajınız",
    branch: "Şube",
    address: "Adres",
    phone: "Telefon",
    messageTypeOp: {
      sales: "Satış",
      markting: "Pazarlama",
      customerService: "Müşteri Hizmetleri",
      operations: "Operasyonlar",
    },
    validation: {
      emailRequired: "E-posta Gerekli",
      fullNameRequire: "Tam Ad Gerekli",
      emailInvalid: "E-posta Geçerli Değil",
      phoneNumber: "Telefon Gerekli",
      messageType: "Mesaj Türü Gerekli",
      subjectRequired: "Konu Gerekli",
      messageRequired: "Mesaj gerekli",
    },
    submitButton: "Mesaj gönder",
    //successMessage: "Mesajınız Gönderildi"
    successMessage:
      "Mesajınız gönderilmiştir. Size en kısa süre içerisinde geri dönüş yapacağız.",
  },
  path: {
    home: "Anasayfa",
    news: "HABERLER",
    contactUs: "İletişim",
    aboutUs: " Hakkımızda ",
    privacyPolicy: "Gizlilik Politikası",
    terms: "Şartlar ve Koşullar",
    services: "Hizmetler",
    checkout: "Çıkış Yap",
    search: "Arama",
    products: "Ürünler",
    wishlist: "İstek Listesi",
    cart: "Sepet",
    faq: "Sıkça Sorulan Sorular",
    compare: "KARŞILAŞTIRMA",
  },
  product: {
    addToCompare: "KARŞILAŞTIRMA LİSTESİNE EKLE",
    protectOrder: "Siparişlerinizi ödemeden teslimata kadar koruyun",
    tradeAssurance: "TİCARET GÜVENCESİ",
    startOrder: " Siparişe Başla",
    shipping: "Nakliye",
    showCase: "Ürün fiyatlandırması özelleştirme veya diğer sorular için:",
    share: "Paylaş",
    shortDescription: "Kısa Açıklama",
    piecesMinOrder: "Asgari Sipariş Miktarı: Adet",
    pieceMinOrder: "Parça (Minumum Sipariş)",
    rfqExporter: "İHRACATÇI’DAN FİYAT TEKLİFİ TALEBİ",
    productDetails: "Ürün Detayları",
    companyData: "Şirket Verileri",
    companyProfile: "Şirket Profili",
    verifiedCompany: "Tescilli Şirket",
    businessType: "İş Tipi",
    totalEmployees: "Bütün Personeller",
    yearEstablished: "Kuruluş Yılı",
    productCertification: "Ürün Sertifikası",
    trademarks: "Ticari Markalar",
    productDescription: "Ürün Açıklaması",
    productionCapacity: "Üretim Kapasitesi",
    qualityControl: "Kalite Kontrol",
    RDCapacity: "AR-GE Kapasitesi",
    tradeCapacity: "Ticaret Kapasitesi",
    reviews: "Yorumlar",
    addReview: "Yorum Ekle",
    reviewShowCase:
      " Yorumunuz web üzerinde herkese açık olarak yayınlanacak. Daha fazla bilgi edin",
    rating: "Değerlendirme",
    yourReview: " Yorumun",
    relatedProduct: "İlgili Ürünler",
    forOnePrice: "Parça Başına Fiyat",
    minPice: " Parça başına",
    stockInAndOut: "Stok Girişi / Çıkışı",
    avilable: "Mevcut",
    notAvilable: "Mevcut değil",
    quantity: "Miktar",
    piece: "Parça",
    sample: "Numune :",
    sampleShowCase: " Numune Gösterimi",
    buySample: "Numune Satın Al",
    companyOverview: "Şirket Genel Bakışı",
    companyName: "Şirket Adı",
    companyMail: "Şirket E-postası",
    companyPhone: "Şirket Telefonu",
    companyLocation: "Şirketin Konumu",
    title: "Başlık",
    submitReview: "Kaydet",
    notFound: "Bulunamadı",
    exporterName: "İHRACAT Adı",
    customization: "Özelleştirme :",
    customizationLogo: "Şahsi Logo ",
    free: "Bedava",
    customizationPackaging: " Kişisel Paketleme ",
  },
  abuseReport: {
    title: "Kötü Kullanım Bildirimi",
    fullname: "Ad Soyad",
    email: "E-posta",
    phone: "Telefon numarası.",
    order: "Sipariş numarası",
    abuseType: "Kötüye Kullanım Türü",
    types: {
      importer: "İthalatçı",
      exporter: "İhracatçı",
      tradeAccurance: "TİCARET GÜVENCESİ",
      investment: "YATIRIM",
      shipment: "Gönderim",
      others: "Diğer",
    },
    evidence: "Kanıtlayan URL",
    abuseDetails: "Kötüye kullanım detayları",
    validation: {
      fullName: "Tam Ad Gerekli",
      orderRquired: "Sipariş Gerekli",
      emailRequired: "E-posta Gerekli",
      emailNotValid: "E-posta Geçerli Değil",
      evidenceRequired: "Kanıt Gerekli",
      abuseDetailsRequired: "Kötüye Kullanım Ayrıntıları Gerekli",
      phone: "Telefon Gerekli",
    },
    submitButton: "Gönder",
    //successMessage: "Raporunuz Başarıyla Gönderildi"
    successMessage:
      "Raporunuz gönderilmiştir. Sorunu inceleyip size en kısa zamanda bilgi vereceğiz.",
  },
  popUps: {
    loginSuccess: "Başarılı Giriş",
    submitSuccess:
      "Talebiniz alınmıştır. Teklifiniz kısa süre içerisinde gönderilecektir.",
    commingSoon: "Çok Yakında........",
  },
  checkout: {
    billing: "Faturalandırma",
    shiping: "Nakliye",
    order: "Sipariş",
    payment: "Ödeme",
    billingDetails: "Fatura Detayları",
    diffrentAddress: "Farklı Adres",
    next: "Sonraki",
    fristName: "Ad",
    lastName: "Soyad",
    companyName: "Şirket Adı",
    country: "Ülke",
    streetAddress: "Cadde",
    apartment: "Apartman, Bina  ",
    city: "Şehir",
    state: "Bölge/Ülke",
    zib: "Posta Kodu",
    orderNote: "Sipariş Notu",
    validation: {
      fristName: "Ad Gerekli",
      lastName: "Soyadı Gerekli",
      country: "Ülke Gerekli",
      street: "Cadde Gerekli",
      city: "Şehir Gerekli",
    },
  },
  compare: {
    noProductAdded: "Ürün Eklenemedi",
    hideSameFeatures: "Aynı Özellikleri Gizle",
    fillterBy: "Filtrele",
    hilightDeifference: "Farkı Öne Çıkar",
    compareError: "Yalnızca aynı kategorideki ürünleri karşılaştırabilirsiniz",
    allFeatures: "Tüm Özellikler",
    productFeature: "ÜRÜN VİTRİNİ",
    exporterFeature: "İhracatçı Özellikleri",
    price: "Fiyat",
    rangePriceFrom: "Fiyat Başlangıcı",
    rangePriceTo: "Fiyat Aralığı",
    samplePrice: "Örnek Fiyat",
    minOrderQuentity: " Minumum Sipariş Miktarı",
    fobPrice: "FOB Fiyat",
    customeOption: "Özel Seçenek",
    rate: "Oran",
    companyName: "Şirket Adı",
    exporterTitle: "İhracatçı Ünvanı",
    exporterName: "İhracatçı Adı",
    companyPhone: "Şirket Telefonu",
    companyLocation: "Şirketin Konumu",
    companyMail: "Şirket Email",
  },
  cart: {
    productDetails: "Ürün Detayları",
    quantity: "Miktar",
    price: "Fiyat",
    samplePrice: "Örnek fiyat",
    total: "Toplam",
    romove: "Kaldırmak",
    checkout: "Çıkış Yapmak",
    proceedWithExporter: "İhracatçıyla devam ettir",
    useCoupon: "Kupon Kullan",
    tradeAccuranceOrder: "TİCARET GÜVENCESİ Emri",
    peice: "Parça",
    rfqRequest: "RFQ TALEBİ",
    withoutShipment: "Gönderimsiz",
  },
  wishlistP: {
    addToCompare: "KARŞILAŞTIRMA LİSTESİNE EKLE",
    selectAll: "Hepsini seç",
    contactExporter: "İHRACATÇI İLE İLETİŞİM",
    defaultSorting: "Varsayılan Sıralama",
    sortByRate: "Derecelendirmeye Göre",
  },
  faq: {
    frequentlyAskedQuestions: "Sıkça Sorulan Sorular",
    text:
      "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Odio\n    consequatur aliquam, amet natus dolores ad vel quis, dolorem eius\n    autem voluptatibus necessitatibus ipsa modi consectetur molestiae!\n    Sed nesciunt repellendus unde.",
    qaExporter: "İhracatçılar",
    qaImporter: "İthalatçılar",
    qaInvestor: "Yatırım",
  },
  countinuReading: "Okumaya devam et",
  or: "veya",
  wishlistEmpty: "İstek Listesi Boş",
  cartEmpty: "Sepet Boş",
  ok: "Tamam",
  seeMore: "Daha Fazla Gör ...",
  seeLess: "Daha Az Gör ...",
  loginFirst: "Öncelikle Giriş Yapmalısınız",
  addToCart: "SEPETE EKLE",
  learnmore: "Detaylı Bilgi",
  signupNow: "Üye Ol!",
  addedToCart: "Sepete Eklendi",
  searchP: {
    filterBy: "Filtrele",
    stockStatus: "Stok Durumu",
    searchShowCase: "Arama Sonuçları",
    for: "için",
    in: "içinde",
    inStock: "Stokta var",
    companys: "şirketler",

    outStock: "Stokta yok",
    rangePrice: "FİYAT ARALIĞI",
    quantity: "Miktar",
    location: "Konum",
    readyToShip: "GÖNDERİME HAZIR",
    ready: "Hazır",
    notReady: "Hazır değil",
    less: "Altında",
    rate: "Oran",
    above: "Üzeri",
    showing: "Gösterilen",
    priceMinOrder: "Parça (Minumum Sipariş)",
    of: "kapalı",
    sorting: {
      default: "VARSAYILAN SIRALAMA",
      byName: "İSME GÖRE SIRALAMA",
      byPrice: "FİYATA GÖRE SIRALAMA",
      bypopularity: "POPÜLERİTEYE GÖRE SIRALAMA",
      newest: "EN YENİ",
      bestReview: "DEĞERLENDİRMELERE GÖRE SIRALAMA",
      priceHighToLow: "AZALAN FİYATA GÖRE SIRALAMA",
      priceLowToHigh: "ARTAN FİYATA GÖRE SIRALAMA",
      byRate: "ORANA GÖRE",
    },
    products: "Ürün",
    productEmpaty: "Ürün Bulunamadı",
    countrys: {
      egypt: "Mısır",
      china: "Çin",
      russia: "Rusya",
      germany: "Almanya",
      other: "Diğer",
    },
  },
  rfqModal: {
    amount: "Miktar",
    productDescription: "Ürün Tanımı",
    payment: "Ödeme ",
    productPercent: "Ürün Yüzdesi",
    mony: "Para",
    country: "Ülke",
    city: "Şehir",
    address: "Adres",
    zib: "Posta Kodu",
    phone: "Telefon",
    shippingInfo: "Nakliye Bilgisi",
    from: "itibaren",
    submitRfq: "RFQ Gönder",
    to: "için",
    note: "Sevkiyat ile İlgili Notlar",
    description: "Açıklama",
    typeOfTransportation: "Ulaşım Tipi",
    paymentType: "Ödeme Şekli",
    specialSpecifications: "Özel Şartnameler",
    validation: {
      amountMustMoreOne: "Tutar 1'den Fazla Olmalıdır",
      amount: "Miktar Gerekli",
      productDetails: "Ürün Detayları Gerekli",
      payment: "Ödeme Gerekli",
      productPercent: "Ürün Yüzdesi Gerekli",
      mony: "Para Gerekli",
      address: "Adres Gerekli",
      city: "Şehir Gerekli",
      country: "Ülke Gerekli",
      phone: "Telefon Gerekli",
      shippingFromRequired: "Nereden Kısmı Gerekli",
      shippingToRequired: "Nereye Kısmı Gerekli",
      typeOfTransportationRequired: "Ulaşım Türü Gerekli",
      paymentType: "Ödeme Şekli Gerekli",
    },
  },
  permistionMessages: {
    mustImporter: "Önce İthalatçı Olarak Giriş Yapmalısınız",
  },
  plans: {
    plans: "Üyelik Planları",
    subscription: "Üye Ol!",
    choosePlan: "Plan Seç!",
  },
  tradeAssuranceReq: {
    name: "Ad",
    email: "E-mail",
    request: "İstek",
    url: "Url",
    phone: "Telefon Numarası",
    joinUs: "Bize katıl",
    requestQuotation: "Sitirlemek",
    reqTradeAssurance: "TİCARET GÜVENCESİ isteyin",
    validation: {
      phoneRequired: "Telefon Gerekli",
      nameRequired: "İsim (gerekli)",
      emailRequired: "E-mail Gerekli ",
      emailNotValid: "E-posta Geçerli Değil",
      request: "Talep Gerekli",
      urlNotValid: "URL Geçerli Değil",
    },
  },
  //utils: { thanks: "Teşekkürler" },
  utils: {
    thanks:
      "Aboneliğiniz gerçekleşmiştir. En son güncellemeler gelen kutunuza gönderilmiştir.",
  },
  services: {
    importing: "İTHALAT",
    exporting: "İHRACAT",
    investment: "YATIRIM",
    tradeAssurance: "Ticaret Güvencesi",
    showCase: `İşinizi daha ileri seviyeye taşımanıza yardımcı olmak için tasarlanmıştır. Bu bölümde, sağlıklı ticareti kolaylaştırmak için platformumuz aracılığıyla sunduğumuz hizmetleri daha detaylı inceleyebilirsiniz.																									
`,
  },
  aboutUs: {
    welcome: "GIT-ZONE’a Hoşgeldiniz!",
    showCase: `GIT-ZONE sadece yerel piyasalarda değil, 																									
    uluslararası piyasalarda da satıcılar ile alıcılar arasında köprü kuran Ortadoğu’nun ilk e-ticaret platformudur. GIT-ZONE e-ticaret platformu, alıcılar, satıcılar ve yatırımcılar için güvenli, teknoloji odaklı ve sürdürülebilir bir ticaret ortamı oluşturmak üzere dizayn edilmiştir. `,
    letter: `																									
      GIT-ZONE e-ticaret platformunun değer önermesi, rekabetçi fiyatlarla sunulan geniş ürün yelpazesinde, taraflar arasında sağlanan etkin ve kolay iletişimde, alıcılar için adet/hacim sınırlaması olmayan sipariş politikasında ve satış sonrasını kapsayan ürün ve hizmet kalitesinde bulunmaktadır. 																									
      `,
    showCae2: `Belirli bir kategori veya ülke sınırlaması olmayan GIT-ZONE e-ticaret platformunda alıcı ve satıcılar sınırsız ticareti deneyimleyebilir. Elektronikten giyime, aksesuarlara, tarım ürünlerine ve daha fazlasına uzanan ürün kategorilerine 																									
      `,
    here: "BURADAN",
    showcae3:
      "göz atabilirsiniz. Eğer alıcı, satıcı veya yatırımcı iseniz işinizi bir üst seviyeye taşımanıza destek olabiliriz.",
    contactUsNow: "BİZİMLE İLETİŞİME GEÇİN !",
    vision: "Vizyon",
    visionDetails: `Sınırsız ticareti sadece Ortadoğu’ya değil, Afrika’ya ve dünyanın geri kalanına doğru genişleterek alıcılar ile satıcılar arasında ticaret köprüsü oluşturmak. Bu vizyon, ancak kullanıcı dostu ve yüksek teknolojili platformumuz ile sağlıklı ticareti daha kolay hale getirerek gerçekleştirilebilir. `,
    mission: "Misyon",
    missionDetails: `Biz, GIT-ZONE’a ilk kayıt olduğunuz andan itibaren ticari işlemlerinizin artmasına büyük önem veriyoruz. İster satıcı, alıcı veya yatırımcı olun, misyonumuz, ticari süreçlerinizi kolaylaştıracak araçları size sunarak, işlemlerinizi zamanında ve etkili biçimde gerçekleştirmenizi sağlamaktır. `,
    values: "Değerlerimiz",
    valuesDetails: `Değerlerimizi açık biçimde paylaşarak, bizi daha yakından tanımanızı ve iş yapma biçimimizi bilmenizi isteriz. `,
    quality: "Kalite",
    qualityD: `Ticaret Güvencesi sistemimizle, size sahtekarlığı önleyen ve riski azaltan bir ticari işlem garantisi veriyoruz.`,
    diversity: "Çeşitlilik",
    diversityD: `Geniş bir yelpazede ticari ürün kategorisi sağlıyoruz. Kullanıcılarımızın yararı için tüm ürün kategorileri rekabetçi fiyatlarla sunulmaktadır. `,
    passion: "Tutku",
    passionD: `Büyük düşünüyoruz ve işinizi büyütüyoruz!`,
    importing: "İTHALAT",
    importingD: `Ürün tedariği yapmak için birçok ülkeye yorucu seyahatler yapmanıza ve bunun için zaman harcamanıza artık gerek yok; uluslararası ticaret için çevrimiçi toptan platformumuzu kullanarak ürün tedariğine başlamanız yeterli! GIT-ZONE.com geniş yelpazedeki ürün kategorilerine ve farklı lokasyonlardan binlerce tedarikçiye ulaşmanızı sağlıyor.`,
    learnMore: "Detaylı Bilgi",
    tradeAssurance: "TİCARET GÜVENCESİ",
    tradeAssuranceD: ` Ticarete GÜVEN’i yeniden getiriyoruz. İster alıcı, ister satıcı olun denetlemeyi sizin adınıza biz yapıyoruz. Ticaret Güvencesi hizmetimiz tüm taraflar için sahte ticari işlemleri önlüyor !`,
    signUpNow: `Üye Ol!`,
    exporter: "İhracat",
    exporterD: `Ürününüze uluslararası sergileme imkanı verin ve çevrimiçi pazarda satışa başlayın. Toptan çevrimiçi pazarlar, ürün sergileme ve karlılığı yükseltme konusunda kanıtlanmış bir sicile sahiptir. GIT-ZONE.com, ürünlerinizi sergileyebileceğiniz ve alıcılarla kolay ve güvenli şekilde iletişim kurabileceğiniz bir platform sağlıyor.buradan ulaşabilirsiniz`,
    investment: "YATIRIM",
    investmentD: `Ortadoğu’nun ilk e-ticaret platformu olan GIT-ZONE.com Afrika ve Avrupa’yla birlikte dünyanın tüm bölgelerini kapsıyor. Herhangi bir ülkede herhangi bir işe yatırım yapmak istiyorsanız, size doğru iş planını sunarak hayallerinizi gerçekleştirmenize destek oluyoruz.`,
  },
  map: {
    capital: "",
    language: "Dil",
    area: "Bölge",
    growthRate: "Büyüme Oranı",
    moreInfo: "Daha Fazla Bilgi",
    askforConsultation: "Danışmak için Sorunuz",
    downloadAsPDF: "PDF olarak indir",
    submit: "Kaydet",
    phone: "Numara",
    note: "Not",
    population: "Nüfus",
    currency: "Para Birimi",
  },
  privacyPolicy: {
    _1: {
      title: "GIT-ZONE.com Gizlilik ve Güvenliğinize Önem Veriyor!",
      body: `GIT-ZONE.com'un temel misyonlarından biri, tüm iş ortakları için güvenli, teknoloji odaklı bir e-ticaret platformu oluşturmak olduğundan, kişisel bilgilerinizi korumak ve platformumuzda yaptığınız ticari işlemlerin her adımını güvence altına almak istiyoruz. Platformun bu bölümü gizlilik politikamızı gösterir ve toplanan kişisel bilgilerinizin nasıl ve nerede kullanılabileceğini ayrıntılı olarak açıklar.`,
    },
    _2: {
      title: "Hangi Bilgileri Topluyoruz ve Onları Nasıl Koruyoruz?",
      body_1: `GIT-ZONE.com'a kayıt olurken, adınız, gönderim/nakliye adresiniz, e-posta adresiniz ve/veya telefon numaranız ve diğer benzer bilgiler gibi birtakım kişisel bilgileri sağlamanız istenecektir. Ayrıca, kimliğinizi doğrulayabilmemiz için geçerli bir kimlik belgesi talep etmemiz gerekebilir, kredi kartınız ve/veya banka hesap bilgileriniz gibi sizden bazı finansal bilgileri de almamız gerekecektir. Bu finansal bilgileri faturalandırma amacıyla kullanıyoruz. Siteye kaydolduktan sonra, sitenin hiçbir yerinde hiçbir kişisel bilgi (finansal bilgiler dahil) yayınlamamalısınız.`,
      body_2: `GIT-ZONE.com'da kendi kullanıcı adınız ve parolanızın güvenliğinden sorumlusunuz. Güçlü bir şifre seçmenizi ve sık sık değiştirmenizi öneririz. Lütfen birden fazla web sitesinde aynı giriş bilgilerini (e-posta ve şifre) kullanmayınız.`,
      body_3: `Bununla birlikte, güvenli bir sunucu kullanımı da dahil olmak üzere çeşitli güvenlik önlemleri uyguluyoruz. Herhangi bir işlemden sonra özel bilgileriniz (kredi kartları, finans, vb.) sunucularımızda saklanmaz. Sunucularımız ve web sitemiz, sizi çevrimiçi korumak için rutin olarak güvenlik taramasından geçirilir.`,
    },
    _3: {
      title: "Telif Hakkı",
      body:
        "رBu sitede yer alan veya GIT-ZONE.com portalı üzerinde bulunan metin, grafik, logo, buton simgeleri, resimler, ses klipleri, video klipler, dijital indirmeler, veri derlemeleri ve yazılım gibi tüm içerikler, GIT-ZONE.com veya içerik sağlayıcılarının mülkiyetindedir ve uluslararası telif hakkı yasaları ile korunmaktadır. GIT-ZONE.com sitesine dahil edilen veya sunulan tüm içerik, GIT-ZONE.com'un özel mülkiyetindedir ve uluslararası telif hakkı yasalarıyla korunmaktadır. Herhangi bir GIT-ZONE.com hizmetinde kullanılan tüm yazılımlar GIT-ZONE.com'un veya yazılım tedarikçilerinin mülkiyetindedir ve uluslararası telif hakkı yasalarıyla korunmaktadır.",
    },
    _4: {
      title: "3. Parti Linkler",
      body: ` Web sitemize üçüncü parti ürün veya hizmetler ekleyebilir veya web sitemiz üzerinde sunabiliriz. Bu üçüncü parti ürün / hizmetler, ürünün sevkiyatı, ödeme tahsilatı, dolandırıcılık koruması ve / veya ödeme riskinin azaltılması için kullanılabilir. Bu üçüncü parti sitelerin ayrı ve bağımsız gizlilik politikaları vardır. Bu nedenle, bu bağlantılı sitelerin içeriği ve faaliyetleri hakkında hiçbir sorumluluğumuz veya yükümlülüğümüz yoktur. Bununla birlikte, sitemizin bütünlüğünü korumaya ve bu siteler hakkında sizden gelen her türlü geri bildirimi memnuniyetle karşılamaya çalışıyoruz.`,
    },
    _5: {
      title: "Çerez Kullanıyor Muyuz?",
      body_1: `Evet. Çerezler, bir sitenin veya servis sağlayıcının web tarayıcınız üzerinden bilgisayarınızın sabit sürücüsüne aktardığı küçük dosyalardır (ayarlarınız aracılığıyla izin verdiyseniz). Bu, siteler veya servis sağlayıcı sistemlerinin tarayıcınızı tanımasını ve belirli bilgileri yakalamasını ve hatırlamasını sağlar.`,
      body_2: `Çerezleri, alışveriş sepetinizdeki öğeleri hatırlamamıza ve işlememize, gelecekteki ziyaretleriniz için tercihlerinizi anlamamıza ve kaydetmemize olanak verdiği için, gelecekte sizin için daha iyi bir site deneyimi sunabilmek, site trafiği ve site etkileşimi hakkında toplu veriler derleyebilmek için kullanıyoruz. Çerezleri kullanmamızın kişisel veya özel bilgiler içermediğinden ve virüs içermediğinden emin olabilirsiniz. Çerezler hakkında daha fazla bilgi edinmek için `,
      body_3: ` adresine gidebilirsiniz veya tarayıcınızdan çerezleri kaldırma hakkında bilgi edinmek için,adresine gidebilirsiniz.`,
      body_4: `Kullanıcıların GIT-ZONE.com portalıyla nasıl etkileşime girdiğini daha iyi anlamamıza yardımcı olmak için Google, Inc. (“Google”) tarafından sağlanan bir web analizi hizmeti olan Google Analytics'i kullanıyoruz. Google Analytics, web sitemizin kullanımı hakkında bilgi toplamak için çerezleri kullanır. Bu bilgiler, raporları derlemek ve web sitemiz ve onunla ilişkili hizmetleri geliştirmemize yardımcı olacak araçlar oluşturmak için kullanılır. Raporlar, bireysel ziyaretçileri tanımlamadan web sitesi trendlerini açıklar. Google çerezimiz tarafından web sitemizi kullanımınızla ilgili olarak (IP adresiniz dahil) oluşturulan bilgiler Google tarafından ABD'deki sunucularda saklanır. Google ayrıca bu bilgileri yasaların gerektirdiği yerlerde kullanabilir veya Google adına bilgileri işleyen üçüncü partilere aktarabilir. Google, IP adresinizi Google tarafından tutulan diğer verilerle ilişkilendirmez.`,
    },
    _6: {
      title: "GIT-ZONE.com’da Kötüye Kullanım Raporlaması",
      body: `Bu Kullanıcı Sözleşmesi’nin herhangi bir şekilde ihlaliyle karşılaşmanız durumunda tarafımızı bilgilendirin. Fikri mülkiyet haklarınızın ihlal edildiğine inanıyorsanız, lütfen GIT-ZONE.com'u bilgilendirin. GIT-ZONE.com, bu Kullanıcı Sözleşmesi veya yasalar uyarınca herhangi bir kullanıcının hak ve hukukuna ihlal getirmeksizin, kötüye kullanan bir kullanıcının siteye erişimini ve/veya siteye üyeliğini sınırlayabilir, askıya alabilir, geri çekebilir veya barındırdığı içeriği kaldırabilir. Ayrıca, GIT-ZONE.com herhangi bir sorun oluşturan veya olası yasal yükümlülükler yaratan, üçüncü tarafların fikri mülkiyet haklarını ihlal eden veya Kullanıcı Sözleşmesi veya politikalarımızla tutarsız hareket eden kullanıcılara karşı başka teknik ve/veya yasal adımlar atmayı seçebilir.`,
    },
  },
};

export default tr;

import { createGlobalStyle } from "styled-components";

const globalStyle = createGlobalStyle`

    * {
         font-family: ${props =>
           props.ar ? "Tajawal, sans-serif !important" : "gunar"};        
    }
    .error {
        text-align: ${props => props.ar && "right"};   
    }
    .list-unstyled {
    padding-right:  ${props => props.ar && 0};
}
    }
    .custom-checkbox{
        padding-right: 1.5rem;
    }
    .custom-control-label::before , .custom-control-label::after{
        right: -1.5rem;
    }
`;

export default globalStyle;

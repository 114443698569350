import {
  ADD_TO_COMPARE,
  REMOVE_FROM_COMPARE,
  SELECT_ALL_TO_COMPARE,
  ADD_MULTIPLE_TO_COMPARE,
  HIDE_SAME_FEATURE,
  HILIGHT_DIFFERENCE,
  ADD_TO_CART,
  DELETE_FROM_COMPARE,
  OPEN_MODAL
} from "./types";
import _ from "lodash";
import strings from "../../Localization/LNG";

export const addToCompare = product => (dispath, getState) => {
  const compareProducts = getState().compare.items;
  const CProduct = Object.values(compareProducts);
  if (CProduct.length > 0) {
    const selectedPro = CProduct[0].active_categories;
    const active_cat = product.active_categories;
    let isDiffrentCat;
    _.map(selectedPro, product => {
      const isHave = _.some(active_cat, p => {
        return p.slug === product.slug;
      });
      if (isHave) {
        isDiffrentCat = true;
      }
    });
    // if (!isDiffrentCat) {
    //   dispath({
    //     type: OPEN_MODAL,
    //     payload: strings.compare.compareError
    //   });
    //   return;
    // }
  }
  dispath({ type: ADD_TO_COMPARE, payload: product });
};

export const disSelectItem = product => {
  return { type: REMOVE_FROM_COMPARE, payload: product };
};

export const selectAll = () => {
  return { type: SELECT_ALL_TO_COMPARE };
};

export const addAllToCompare = () => (dispatch, getState) => {
  const state = getState().wishlist.selectedWishList;
  dispatch({ type: ADD_MULTIPLE_TO_COMPARE, payload: state });
};

export const hideSameFearure = () => {
  return { type: HIDE_SAME_FEATURE };
};

export const onHilightDifference = () => {
  return { type: HILIGHT_DIFFERENCE };
};

export const addAllToCart = () => (dispatch, getState) => {
  const state = getState().compare.selectedProduct;
  const newState = Object.values(state);
  newState.forEach(e => {
    dispatch({ type: ADD_TO_CART, payload: e });
  });
};
export const deleteFromCompare = productId => {
  return { type: DELETE_FROM_COMPARE, payload: productId };
};

import axiso from "../../services/axios";
import {
  LOGIN_USER,
  AUTH_ERROR,
  LOADING,
  SIGNOUT,
  CLEAR_ALL,
  CLEAR_WISHLIST,
  LOGOUT_IFRAME,
  USER_FILE_NOT_UPLODED,
  OPEN_MODAL,
  CLOSE_LOGOUT_IFRAME
} from "./types";


export const login = user => async dispatch => {
  dispatch({ type: LOADING });
  try {
    const response = await axiso.post("/api/user/login", user);
    dispatch({ type: LOGIN_USER, payload: response.data });
    localStorage.setItem("token", `Bearer ${response.data.data.token}`);
    dispatch({ type: CLOSE_LOGOUT_IFRAME });
    if (response.data.data.docs_uploaded === false) {
      dispatch({ type: USER_FILE_NOT_UPLODED });
    } else if (!response.data.data.confirmed) {
      dispatch({
        type: OPEN_MODAL,
        payload: "you need to confirm your email address "
      });
    }
  } catch (error) {
    if (error.response.data.errors.confirmation) {
      dispatch({
        type: AUTH_ERROR,
        payload: error.response.data.errors.confirmation[0]
      });
      return;
    }
    if (error.response.data.errors) {
      if (error.response.data.errors.email[0]) {
        dispatch({
          type: AUTH_ERROR,
          payload: error.response.data.errors.email[0]
        });
        return;
      }
    }
    dispatch({ type: AUTH_ERROR, payload: "Email or password not correct " });
  }
};

export const setProfile = (token) => async (dispatch, getState) => {
  try {
    localStorage.setItem("token", `Bearer ${token}`);
    const response = await axiso.get("/api/profile", {
      headers: {
        Authorization: `${localStorage.getItem("token")}`
      }
    });
    dispatch({ type: LOGIN_USER, payload: response.data });
  } catch (error) {
    localStorage.removeItem("token");
    localStorage.removeItem("persist:root");
    return { type: SIGNOUT };
  }
};

export const getProfile = () => async (dispatch, getState) => {
  try {
    const response = await axiso.get("/api/profile", {
      headers: {
        Authorization: `${localStorage.getItem("token")}`
      }
    });
    dispatch({ type: LOGIN_USER, payload: response.data });
  } catch (error) {
    localStorage.removeItem("token");
    localStorage.removeItem("persist:root");
    return { type: SIGNOUT };
  }
};

export const signout = () => dispatch => {
  localStorage.removeItem("token");
  localStorage.removeItem("persist:root");
  dispatch({ type: SIGNOUT });
  dispatch({ type: CLEAR_ALL });
  dispatch({ type: CLEAR_WISHLIST });
};

export const openLogoutIframe = () => {
  return { type: LOGOUT_IFRAME };
};

export const closeLogoutIframe = () => {
  return { type: CLOSE_LOGOUT_IFRAME };
};

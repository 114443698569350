import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";

import axios from "../../services/axios";
import strings from "../../Localization/LNG";
import { useSelector } from "react-redux";
import LoginForm from "../auth/Login/LoginForm";
import { useEffect } from "react";
export default function SupplierModal({ isOpen, product, onClose, isCart }) {
  const isAuth = useSelector(state => state.user.isAuth);
  const lng = useSelector(state => state.lng);
  console.log(product);
  return (
    <Modal
      size="lg"
      scrollable={true}
      show={isOpen}
      centered={true}
      className="exporter-content"
      keyboard={true}
      onHide={onClose}
      keyboard={true}
      onEscapeKeyDown={onClose}
      aria-labelledby="example-custom-modal-styling-title"
    >
      <button
        style={{ fontSize: "2rem" }}
        type="button"
        className="close"
        onClick={onClose}
        aria-label="Close"
      >
        <span aria-hidden="true" className="float-right mr-3">
          &times;
        </span>
      </button>
      {isAuth ? (
        <>
          <div className="mt-3 mb-3">
            <h3 className="rfq-title text-center font-weight-bold">
              {strings.wishlistP.contactExporter}
            </h3>
          </div>
          <div
            className={`mt-3 ${lng === "arabic" ? "pr-5 text-right" : "pl-5"}`}
          >
            <div className="row">
              {product && product.company_name ? (
                <div className="col">
                  <h5 className=" font-weight-bold main-color">
                    {strings.product.companyName}
                  </h5>
                  <p style={pStyle}> {product && product.company_name} </p>
                </div>
              ) : null}
              {product && product.company_location ? (
                <div className="col">
                  <h5 className="font-weight-bold main-color">
                    {strings.product.companyLocation}
                  </h5>
                  <p style={pStyle}> {product && product.company_location} </p>
                </div>
              ) : null}
            </div>
            <div className="row">
              {product && product.company_mail ? (
                <div className="col">
                  <h5 className="mt-3 font-weight-bold main-color">
                    {strings.product.companyMail}
                  </h5>
                  <p style={pStyle}>
                    {product && product.company_mail
                      ? product.company_mail
                      : strings.product.notFound}
                  </p>
                </div>
              ) : null}
              {product && product.company_phone_number ? (
                <div className="col">
                  <h5 className="mt-3 font-weight-bold main-color">
                    {strings.product.companyPhone}
                  </h5>
                  <p style={pStyle}>
                    {product && product.company_phone_number
                      ? product.company_phone_number
                      : strings.product.notFound}
                  </p>
                </div>
              ) : null}
            </div>
            <h5 className="row">
              {product && product.exporter_name ? (
                <div className="col">
                  <h5 className="mt-3 font-weight-bold main-color">
                    {strings.product.exporterName}
                  </h5>
                  <p style={pStyle}>{product.exporter_name}</p>
                </div>
              ) : null}
              <h5 className="col">
                {product && product.exporter_title ? (
                  <>
                    <h5 className="mt-3 font-weight-bold main-color">
                      {strings.product.title}
                    </h5>
                    <p style={pStyle}>{product.exporter_title}</p>
                  </>
                ) : null}
              </h5>
            </h5>
          </div>
        </>
      ) : (
        <>
          <div className="mt-3 mb-3 pr-4 pl-4">
            <h5 className="text-center font-weight-bold">
              {strings.loginFirst}
            </h5>
          </div>
          <LoginForm />
        </>
      )}
    </Modal>
  );
}

const pStyle = { fontSize: "1rem" };

import {
  ADD_TO_CART,
  REMOVE_FROM_CART,
  INCREASE_QUANTITY,
  DECREASE_QUANTITY,
  CHANGE_QUANTITY,
  MULTI_ADD_TO_CART,
  CLEAR_ALL
} from "../actions/types";
import CartItem from "../../models/cart-item";

const INITIAL_STATE = {
  items: {},
  totalAmount: 0
};

const cartReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_TO_CART:
      const product = action.payload;
      console.log(product);
      if (state.items[product.id]) {
        //    we arealdy have cart for this product so we can increase quantury
        const updatedCartItem = new CartItem(
          state.items[product.id].quantity + 1,
          product.id,
          product.isSample,
          product.description,
          product.sample_quantity,
          product.price,
          product.isEditable ? true : false,
          product.rfqId,
          product.milestoneId,
          product.fob_price,
          product.customize_option,
          product.shipment,
          product.name,
          product.images,
          product.minimum_quantity,
          product.range_price_from,
          product.range_price_to,
          product.sample_price,
          product.rating,
          product.regular_price,
          product.slug,
          product.url || null,
          product.stock,
          product.company_name,
          product.company_mail,
          product.company_phone_number,
          product.exporter_name,
          product.exporter_title,
          product.company_location,
          action.trade_accurance,
          action.fob,
          product.trade_value || null
        );
        return {
          ...state,
          items: { ...state.items, [product.id]: updatedCartItem }
        };
      } else {
        const newCartItem = new CartItem(
          action.quentity || product.minimum_quantity,
          product.id,
          product.isSample,
          product.description,
          product.sample_quantity,
          product.price,
          product.isEditable ? true : false,
          product.rfqId,
          product.milestoneId,
          product.fob_price,
          product.customize_option,
          product.shipment,
          product.name,
          product.images,
          product.minimum_quantity,
          product.range_price_from,
          product.range_price_to,
          product.sample_price,
          product.rating,
          product.regular_price,
          product.slug,
          product.url || null,
          product.stock,
          product.company_name,
          product.company_mail,
          product.company_phone_number,
          product.exporter_name,
          product.exporter_title,
          product.company_location,
          action.trade_accurance,
          action.fob,
          product.trade_value || null
        );

        return {
          ...state,
          items: { ...state.items, [action.payload.id]: newCartItem }
        };
      }
    case REMOVE_FROM_CART:
      const updatedCartItems = { ...state.items };
      delete updatedCartItems[action.payload];
      return {
        ...state,
        items: updatedCartItems
      };
    case DECREASE_QUANTITY:
      const selectedProduc = action.payload;
      const selectedQuentity = state.items[selectedProduc.id].quantity;
      const quentity = selectedQuentity <= 1 ? 1 : selectedQuentity - 1;
      const decreaseCartQuantity = new CartItem(
        quentity,
        selectedProduc.id,
        state.items[selectedProduc.id].isSample,
        selectedProduc.description,
        selectedProduc.sample_quantity,
        state.items[selectedProduc.id].price,
        state.items[selectedProduc.id].isEditable,
        selectedProduc.rfqId,
        selectedProduc.milestoneId,
        selectedProduc.fob_price,
        selectedProduc.customize_option,
        state.items[selectedProduc.id].shipment,
        selectedProduc.name,
        selectedProduc.images,
        selectedProduc.minimum_quantity,
        selectedProduc.range_price_from,
        selectedProduc.range_price_to,
        selectedProduc.sample_price,
        selectedProduc.rating,
        selectedProduc.regular_price,
        selectedProduc.slug,
        selectedProduc.url || null,
        selectedProduc.stock,
        selectedProduc.company_name,
        selectedProduc.company_mail,
        selectedProduc.company_phone_number,
        selectedProduc.exporter_name,
        selectedProduc.exporter_title,
        selectedProduc.company_location,
        state.items[selectedProduc.id].trade_accurance,
        state.items[selectedProduc.id].fob,
        state.items[selectedProduc.id].trade_value
      );
      return {
        ...state,
        items: { ...state.items, [selectedProduc.id]: decreaseCartQuantity }
      };
    case CHANGE_QUANTITY:
      const selectProduct = action.payload;
      const newProductQu = new CartItem(
        action.quantity,
        selectProduct.id,
        selectProduct.isSample,
        selectProduct.description,
        selectProduct.sample_quantity,
        selectProduct.price,
        state.items[selectProduct.id].isEditable,
        selectProduct.rfqId,
        selectProduct.milestoneId,
        selectProduct.fob_price,
        selectProduct.customize_option,
        selectProduct.shipment,
        selectProduct.name,
        selectProduct.images,
        selectProduct.minimum_quantity,
        selectProduct.range_price_from,
        selectProduct.range_price_to,
        selectProduct.sample_price,
        selectProduct.rating,
        selectProduct.regular_price,
        selectProduct.slug,
        selectProduct.url || null,
        selectProduct.stock,
        selectProduct.company_name,
        selectProduct.company_mail,
        selectProduct.company_phone_number,
        selectProduct.exporter_name,
        selectProduct.exporter_title,
        selectProduct.company_location,
        state.items[selectProduct.id].trade_accurance,
        state.items[selectProduct.id].fob,
        state.items[selectProduct.id].trade_value
      );
      return {
        ...state,
        items: { ...state.items, [selectProduct.id]: newProductQu }
      };
    case CLEAR_ALL:
      return {
        ...state,
        items: {},
        totalAmount: 0
      };
    default:
      return state;
  }
};

export default cartReducer;

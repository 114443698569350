import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import {
  faCcMastercard,
  faCcDiscover,
  faCcAmex,
  faCcVisa
} from "@fortawesome/free-brands-svg-icons";
function CopyRight() {
  const lng = useSelector(state => state.lng);
  return (
    <div className="copy-right text-white" style={{ background: "#1F1E1E" }}>
      <div className="container">
        <div className="row">
          <div
            className={`col ${
              lng == "arabic" ? "text-right" : "text-left"
            } mt-4 info`}
          >
            © COPYRIGHT - GIT-ZONE.com
          </div>
          <div className="col mt-4">
            <div className={lng === "arabic" ? "text-left" : "text-right"}>
              <div className="payment-icon mr-2 d-inline-block">
                <FontAwesomeIcon size="2x" icon={faCcMastercard} />
              </div>
              <div className="payment-icon mr-2 d-inline-block">
                <FontAwesomeIcon size="2x" icon={faCcDiscover} />
              </div>
              <div className="payment-icon mr-2 d-inline-block">
                <FontAwesomeIcon size="2x" icon={faCcAmex} />
              </div>
              <div className="payment-icon mr-2 d-inline-block">
                <FontAwesomeIcon size="2x" icon={faCcVisa} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CopyRight;

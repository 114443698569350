import {
  GET_PRODUCTS,
  LOADING_UI,
  LOADING_PRODUCT_DETAILS,
  GET_PRODUCT,
  PRODUCT_DETAILS_ERROR
} from "./types";
import axios from "../../services/axios";

export const getProducts = () => async dispatch => {
  dispatch({ type: LOADING_UI });
  const response = await axios.get("/api/home/products");
  dispatch({ type: GET_PRODUCTS, payload: response.data.data });
};

export const getProduct = slug => async dispatch => {
  new Promise(async (resolve, reject) => {
    try {
      dispatch({ type: LOADING_PRODUCT_DETAILS });
      const res = await axios.get(`/api/product/${slug}`);
      dispatch({ type: GET_PRODUCT, payload: res.data });
      return resolve(res.data);
    } catch (error) {
      reject(error);
    }
  });
  // try {
  //   dispatch({ type: LOADING_PRODUCT_DETAILS });
  //   const res = await axios.get(`/api/product/${slug}`);
  //   dispatch({ type: GET_PRODUCT, payload: res.data });
  // } catch (error) {
  //   dispatch({
  //     type: PRODUCT_DETAILS_ERROR
  //   });
  // }
};

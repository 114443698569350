import { combineReducers } from "redux";
import lngReducer from "./lngReducer";
import userReducer from "./user";
import categoriesReducer from "./categoriesReducer";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import productsReducer from "./productsReducer";
import bannersAndBrands from "./bannersAndBrandsReducer";
import uiReducer from "./uiReducer";
import cartReducer from "./cartReducer";
import wishListReducer from "./wishListReducer";
import compareReducer from "./compareReducer";
import searchReducer from "./searchReducer";
import Checkout from "./CheckoutReducer";
import URLS_Reducer from "./URL_Reducers";
import filterReducer from "./searchFilterReducer";
import currencyReducer from "./currencyReducer";

const rootreducer = combineReducers({
  lng: lngReducer,
  user: userReducer,
  categories: categoriesReducer,
  products: productsReducer,
  bannersAndBrands: bannersAndBrands,
  cart: cartReducer,
  wishlist: wishListReducer,
  ui: uiReducer,
  compare: compareReducer,
  search: searchReducer,
  checkout: Checkout,
  urls: URLS_Reducer,
  filter: filterReducer,
  currency: currencyReducer
});

export default persistReducer(
  {
    whitelist: ["cart", "compare", "wishlist", "user", "currency"],
    key: "root",
    storage: storage
  },
  rootreducer
);

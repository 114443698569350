import React, { Component } from "react";
import Strings from "../../Localization/LNG";
import { setLng } from "../../store/actions/lngAction";
import { slide as Menu } from "react-burger-menu";
import { openLoginModal } from "../../store/actions/UiAction";
import "./navbar.css";
import { connect } from "react-redux";
import Login from "../auth/Login/Login";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGlobeEurope,
  faChevronDown,
  faBars,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { Zoom } from "react-reveal";
import { openModal } from "../../store/actions/UiAction";
import { signout } from "../../store/actions/Login";
import strings from "../../Localization/LNG";
import CurrencySelect from "./CurrencySelect";
class NavbarComponent extends Component {
  state = {
    lng: null,
    currency: strings.nav.currancy.dollar,
    isOpen: false,
    localization: [
      { name: "english", label: strings.nav.languages.english },
      { name: "arabic", label: strings.nav.languages.arabic },
      { name: "turkey", label: strings.nav.languages.turkey },
      { name: "germany", label: strings.nav.languages.germany },
      { name: "french", label: strings.nav.languages.french },
    ],
  };
  componentWillMount() {
    const lng = Strings.getLanguage();
    const g = this.state.localization.filter((l) => l.name === lng)[0];
    this.setState({ lng: g });
  }
  onChangeLang = (lng) => {
    localStorage.setItem("lng", lng);
    window.location.reload();
  };
  renderLan = () => {
    return (
      <li key="39289" className="nav-item dropdown">
        <a
          className="nav-link dropdown-toggle text-white"
          href="#"
          id="navbarDropdown"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <FontAwesomeIcon icon={faGlobeEurope} />{" "}
          {this.state.lng ? this.state.lng.label : null}
          <FontAwesomeIcon
            style={iconDown}
            icon={faChevronDown}
            className={`${this.state.lng.name === "arabic" ? "mr-2" : "ml-2"}`}
          />
        </a>
        <div
          className={`dropdown-menu ${
            this.state.lng.name === "arabic" ? "text-right" : " "
          } `}
          aria-labelledby="navbarDropdown"
        >
          {this.state.localization.map((lng) => {
            return (
              <a
                key={lng.name}
                className={`dropdown-item ${
                  this.state.lng.name === lng.name ? "d-none" : ""
                } `}
                onClick={() => this.onChangeLang(lng.name)}
                href="##"
                style={
                  lng.name === "arabic"
                    ? { fontFamily: "Tajawal, sans-serif !important" }
                    : {}
                }
              >
                {lng.label}
              </a>
            );
          })}
        </div>
      </li>
    );
  };

  commingSoon = () => {
    this.props.openModal(Strings.popUps.commingSoon);
  };
  isMenuOpen = (state) => {
    this.setState({ isOpen: state.isOpen });
  };
  closeMenu() {
    this.setState({ isOpen: false });
  }

  render() {
    if (!this.props.appLoading) {
      return (
        <nav
          dir={this.state.lng.name === "arabic" ? "rtl" : "ltr"}
          className="navbar navbar-expand-lg navbar-light text-white"
        >
          <div className="burger-menu">
            <button
              style={{
                border: "none",
                background: "transparent",
                color: "white",
              }}
              onClick={() => this.setState({ isOpen: false })}
            >
              <FontAwesomeIcon icon={faBars} />
            </button>
            <Menu
              onStateChange={this.isMenuOpen}
              width={"70%"}
              isOpen={this.state.isOpen}
              className={`${this.props.lng === "arabic" ? "text-right" : ""}`}
              pageWrapId={"page-wrap"}
              outerContainerId={"outer-container"}
              left
            >
              {this.props.isAuth && (
                <div className="menu-item">
                  <div className="media pt-3">
                    <img
                      src={this.props.user.picture_thumb}
                      style={{ width: "30px" }}
                      className="align-self-start mr-3 "
                    />
                    <div className="media-body">
                      <h6 className="mt-0">{this.props.user.name}</h6>
                      <p> {this.props.user.email} </p>
                    </div>
                  </div>
                </div>
              )}
              <Link
                to="/"
                className="menu-item p-3"
                 target="_blank"
                onClick={() => this.closeMenu()}
              >
                {Strings.nav.home}
              </Link>
              <Link
                to="/aboutus"
                 target="_blank"
                className="menu-item p-3"
                onClick={() => this.closeMenu()}
              >
                {Strings.nav.aboutUs}
              </Link>
              <Link
                to="/services"
                 target="_blank"
                className="menu-item p-3"
                onClick={() => this.closeMenu()}
              >
                {Strings.nav.services}
              </Link>
              <Link
                to="/news"
                 target="_blank"
                className="menu-item p-3"
                onClick={() => this.closeMenu()}
              >
                {Strings.nav.news}
              </Link>
              <div className="pt-1">{this.renderLan()}</div>
              <Link
               target="_blank"
                to="/cart"
                className="menu-item p-3"
                onClick={() => this.closeMenu()}
              >
                {strings.path.cart}
              </Link>
              <Link
                to="/wishlist"
                 target="_blank"
                className="menu-item p-3"
                onClick={() => this.closeMenu()}
              >
                {strings.path.wishlist}
              </Link>
              <Link
                to="/contact-us"
                className="nav-link text-white"
                 target="_blank"
                onClick={() => this.closeMenu()}
              >
                {Strings.nav.connectUs}
              </Link>
              <Link
                to="/subscription-plans"
                 target="_blank"
                className="nav-link text-white"
                onClick={() => this.closeMenu()}
              >
                {Strings.nav.plans}
              </Link>
              {!this.props.isAuth ? (
                <a
                  href="#"
                  className="menu-item p-3 pb-5"
                  onClick={() => {
                    this.props.openLoginModal(strings.login.loginMessage);
                    this.closeMenu();
                  }}
                >
                  {strings.login.login}
                </a>
              ) : (
                <a
                  href="#"
                  className="menu-item p-3 pb-5"
                  onClick={() => this.props.signout()}
                >
                  {strings.login.logout}
                </a>
              )}
            </Menu>
          </div>
          <div className="container">
            <Zoom>
              <ul
                style={{ fontWeight: "bold", fontSize: "14px" }}
                className={
                  this.state.lng.name === "arabic"
                    ? "navbar-nav ml-auto"
                    : "navbar-nav mr-auto"
                }
              >
                <li key="23" className="nav-item">
                  <Link
                    to="/"
                    target="_blank"
                    className="nav-link text-white"
                    href="#"
                  >
                    {Strings.nav.home}
                  </Link>
                </li>
                <li key="3648" className="nav-item">
                  <Link
                    to="/aboutus"
                    target="_blank"
                    className="nav-link text-white"
                  >
                    {Strings.nav.aboutUs}
                  </Link>
                </li>
                <li key="44" className="nav-item">
                  <Link
                    to="/services"
                    target="_blank"
                    className="nav-link text-white"
                  >
                    {Strings.nav.services}
                  </Link>
                </li>
                <li key="44e" className="nav-item">
                  <Link
                    className="nav-link text-white"
                    target="_blank"
                    to="/news"
                  >
                    {Strings.nav.news}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/contact-us"
                    target="_blank"
                    className="nav-link text-white"
                  >
                    {Strings.nav.connectUs}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/subscription-plans"
                    target="_blank"
                    className="nav-link text-white"
                  >
                    {Strings.nav.plans}
                  </Link>
                </li>
              </ul>
            </Zoom>
            <ul
              style={{ fontSize: "13px" }}
              className={
                this.state.lng.name === "arabic"
                  ? "navbar-nav mr-auto"
                  : "navbar-nav ml-auto"
              }
            >
              <CurrencySelect />
              {this.renderLan()}
              <Login urls={this.props.urls} />
            </ul>
          </div>
        </nav>
      );
    } else {
      return null;
    }
  }
}
function mapStateToProps(state) {
  return {
    appLoading: state.ui.loadingApp,
    isAuth: state.user.isAuth,
    lng: state.lng,
    user: state.user.user,
  };
}
const iconDown = {
  fontSize: "10px",
};
export default connect(mapStateToProps, {
  setLng,
  openModal,
  openLoginModal,
  signout,
})(NavbarComponent);

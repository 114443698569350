import {
  OPEN_MODAL,
  CLOSE_MODAL,
  LOADING_APP,
  APP_LOADED,
  COLSE_ABUSE_MODAL,
  OPEN_ABUSE_MODAL,
  CLOSE_LOGIN_MODAL,
  OPEN_LOGIN_MODAL,
  OPEN_MAP_MODAL,
  CLOSE_MAP_MODAL,
  USER_FILE_NOT_UPLODED,
  CLOSE_FILE_NOT_UPLODED,
  LOGOUT_IFRAME,
  CLOSE_LOGOUT_IFRAME,
  OPEN_TRADE_ASSRANCE_MODAL,
  CLOSE_TRADE_ASSRANCE_MODAL,
} from "../actions/types";
const INITIAL_STATE = {
  openModal: false,
  message: null,
  loadingApp: false,
  abuseModal: false,
  action: null,
  loginModal: false,
  mapModal: false,
  profileError: false,
  logoutIframe: false,
  tradeAssurance: false,
  loginMessage: null,
  consultationModel: false,
  location: null,
  openTutorialModal: false,
  openTutorialSlider: false,
};
const uiReducer = (state = INITIAL_STATE, action, checkout) => {
  switch (action.type) {
    case OPEN_MODAL:
      console.log(checkout);
      return {
        ...state,
        openModal: true,
        message: action.payload,
      };
    case LOADING_APP:
      return {
        ...state,
        loadingApp: true,
      };
    case APP_LOADED:
      return {
        ...state,
        loadingApp: false,
      };
    case CLOSE_MODAL:
      return {
        ...state,
        openModal: false,
        message: null,
      };
    case OPEN_ABUSE_MODAL:
      return {
        ...state,
        abuseModal: true,
      };
    case COLSE_ABUSE_MODAL:
      return {
        ...state,
        abuseModal: false,
      };
    case OPEN_LOGIN_MODAL:
      return {
        ...state,
        loginModal: true,
        loginMessage: action.payload,
      };
    case CLOSE_LOGIN_MODAL:
      return {
        ...state,
        loginModal: false,
      };
    case OPEN_MAP_MODAL:
      return {
        ...state,
        mapModal: true,
      };
    case CLOSE_MAP_MODAL:
      return {
        ...state,
        mapModal: false,
      };
    case USER_FILE_NOT_UPLODED:
      console.log({ ...state, profileError: true });
      return {
        ...state,
        profileError: true,
      };
    case CLOSE_FILE_NOT_UPLODED:
      return {
        ...state,
        profileError: false,
      };
    case LOGOUT_IFRAME:
      return {
        ...state,
        logoutIframe: true,
      };
    case CLOSE_LOGOUT_IFRAME:
      return {
        ...state,
        logoutIframe: false,
      };
    case OPEN_TRADE_ASSRANCE_MODAL:
      return {
        ...state,
        tradeAssurance: true,
      };
    case CLOSE_TRADE_ASSRANCE_MODAL:
      return {
        ...state,
        tradeAssurance: false,
      };
    case "ConsultationModel":
      return {
        ...state,
        consultationModel: true,
        location: action.payload,
      };
    case "closeConsultationModel":
      return {
        ...state,
        consultationModel: false,
        location: null,
      };
    case "OPEN_TUTORIAL_MODAL":
      return {
        ...state,
        openTutorialModal: true,
      };
    case "CLOSE_TUTORIAL":
      return {
        ...state,
        openTutorialModal: false,
      };
    case "OPEN_TUTORIAL_SLIDER":
      return {
        ...state,
        openTutorialSlider: true,
      };
    case "CLOSE_TUTORIAL_SLIDER":
      return {
        ...state,
        openTutorialSlider: false,
      };
    default:
      return state;
  }
};

export default uiReducer;

export const GET_LNG = "GET_LNG";
export const SET_LNG = "SET_LNG";

// login
export const LOGIN_USER = "LOGIN_USER";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOADING = "LOADING";
export const LOGIN_SERVER_ERROR = "LOGIN_SERVER_LOGIN";
export const LOGOUT_IFRAME = "LOGOUT_IFRAME";

// user
export const GET_USER = "GET_USER";
export const SIGNOUT = "SIGNOUT";
export const CLOSE_LOGOUT_IFRAME = "CLOSE_LOGOUT_IFRAME";

// get categories
export const GET_CATEGORIES = "GET-CATEGORIES";
export const LOADING_CATEGORY = "LOADING_CATEGORY";

export const LOADING_PRODUCT = "LOADING_PRODUCT";

// products
export const GET_PRODUCTS = "GET_PRODUCTS";
export const GET_PRODUCT = "GET_PRODUCT";
export const LOADING_PRODUCT_DETAILS = "LOADING_PRODUCT_DETAILS";
export const PRODUCT_DETAILS_ERROR = "PRODUCT_DETAILS_ERROR";

// brands
export const GET_BRANDS_AND_BANNERS = "GET_BRANDS_AND_BANNERS";

// UI
export const LOADING_UI = "LOADING_UI";
export const OPEN_MODAL = "OPEN_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";
export const LOADING_APP = "LOADING_APP";
export const APP_LOADED = "APP_LODED";
export const OPEN_ABUSE_MODAL = "OPEN_ABUSE_MODAL";
export const COLSE_ABUSE_MODAL = "COLSE_ABUSE_MODAL";
export const OPEN_LOGIN_MODAL = "OPEN_LOGIN_MODAL";
export const CLOSE_LOGIN_MODAL = "CLOSE_LOGIN_MODAL";
export const OPEN_MAP_MODAL = "OPEN_UI_MODAL";
export const CLOSE_MAP_MODAL = "CLOSE_MAP_MODAL";
export const CLEAR_SELECTED_WISHLIST = "CLEAR_SELECTED_WISHLIST";
export const USER_FILE_NOT_UPLODED = "USER_FILE_NOT_UPLODED";
export const CLOSE_FILE_NOT_UPLODED = "CLOSE_FILE_NOT_UPLODED";
export const OPEN_TRADE_ASSRANCE_MODAL = "OPEN_TRADE_ASSRANCE_MODAL";
export const CLOSE_TRADE_ASSRANCE_MODAL = "CLOSE_TRADE_ASSRANCE_MODAL";

// CART
export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const CHANGE_QUANTITY = "CHANGE_QUANTITY";
export const DECREASE_QUANTITY = "DECREASE_QUANTITY";
export const MULTI_ADD_TO_CART = "MULTI_ADD_TO_CART";
export const CLEAR_ALL = "CLEAR_ALL";

// WISH_LIST
export const ADD_TO_WISH_LIST = "ADD_TO_WISH_LIST";
export const ADD_TO_SELECTED_WISH_LIST = "ADD_TO_SELECTED_WISH_LIST";
export const SELECT_ALL_WISH_LIST = "SELECT_ALL_WISH_LIST";
export const REMOVE_SELECTED_WISHLIST = "REMOVE_SELECTED_WISH_LIST";
export const SORTING_BY_RATING = "SORTING_BY_RATING";
export const CLEAR_WISHLIST = "CLEAR_WISHLIST";
export const REMOVE_FROMSELECTED__WISH = "REMOVE_FROMSELECTED__WISH";

// COMPARE
export const ADD_TO_COMPARE = "ADD_TO_COMPARE";
export const REMOVE_FROM_COMPARE = "REMOVE_FROM_COMPARE";
export const SELECT_ALL_TO_COMPARE = "SELECT_ALL_TO_COMPARE";
export const ADD_MULTIPLE_TO_COMPARE = "ADD_MULTIPLE_TO_COMPARE";
export const HIDE_SAME_FEATURE = "HIDE_SAME_FEATURE";
export const HILIGHT_DIFFERENCE = "HILIGHT_DIFFERENCY";
export const DELETE_FROM_COMPARE = "DELETE_FROM_COMPARE";

// SEARCH
export const SEARCH = "SEARCH";
export const IMG_SEARCH = "SEARCH_BY_IMG";
export const CHANGE_IN_STOCK = "CHANGE_IN_STOCK";
export const CHANGE_OUT_STOCK = "CHANGE_OUT_STOCK";
export const CHANGE_READY_TO_SHIP = "CHANGE_READY_TO_SHIP";
export const CHANGE_NOT_TREADY_TO_SHIP = "CHANGE_NOT_READY_TO_SHIP";
export const CHANGE_UNDER_50 = "CHANGE_UNDER_500";
export const CHANGE_FROM_50_TO_100 = "CHANGE_FROM_50_TO_100";
export const CHANGE_FROM_200_TO_500 = "CHANGE_FROM_200_TO_500";
export const MORE_500 = "MORE_500";
export const CLEAR_CAT = "CLEAR_CAT";

// TRADE ASSURANCE
export const SELECT_TRADE_ASSURANCE = "SELECTED_TRADE_ASSURANCE";

// start

export const GREATING = "GREATING";

export const REMOVE_WISHLIST = "REMOVE_WISH_LIST";

// CHECKOUT
export const ON_ADD_BILLING = "ON_ADD_BILLING";
export const ON_ADD_SHIPING = "ON_ADD_SHIPING";
export const ON_ADD_ORDER = "ON_ADD_ORDER";
export const CLEAR_CHECKOUT = "CLEAR_CHECKOUT";

// CURRENCY
export const CHANGE_CURRENCY = "CHANGE_CURRENCY";

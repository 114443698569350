import React, { useState } from "react";
import strings from "../../Localization/LNG";
import { useSelector } from "react-redux";
import Slider from "react-slick";
import _ from "lodash";
import Lightbox from "react-image-lightbox";
import Badge from "../utils/Badge";
const settings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 4,
  initialSlide: 0,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 734,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1
      }
    }
  ]
};

const CompanyProfile = ({ product }) => {
  const lng = useSelector(state => state.lng);
  const [isImageOpen, setisImageOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  console.log(product.data.exporter.data.media);
  const images = _.filter(product.data.exporter.data.media, media => {
    return media.type === "image/jpeg";
  });
  console.log(product.data.exporter.data.short_description);

  const renderBadge = () => {
    console.log(exporterData.has_badge);
    console.log(typeof exporterData.has_badge);
    if (exporterData.has_badge === 1) {
      return <Badge />;
    } else return null;
  };

  const exporterData = product.data.exporter.data;
  return (
    <div className={`p-4 border ${lng === "arabic" ? "text-right" : ""} `}>
      {isImageOpen && (
        <Lightbox
          mainSrc={images[photoIndex].url}
          imageTitle={product.name}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setisImageOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % images.length)
          }
        />
      )}
      <h6 className="main-color mt-5 font-weight-bold">
        {strings.product.companyOverview} {renderBadge()}
      </h6>
      <p>
        {product.data.exporter.data.short_description
          ? product.data.exporter.data.short_description
          : strings.product.notFound}
      </p>
      <h6 className="main-color mt-5 font-weight-bold">
        {" "}
        {strings.product.companyData}{" "}
      </h6>
      <div className="row mt-3">
        <Slider className="w-100" {...settings}>
          {_.map(images, img => {
            return (
              <div className="col " style={{ outline: "none" }}>
                <img
                  style={{ height: "100px", cursor: "pointer" }}
                  className="img-fluid"
                  src={img.url}
                />
              </div>
            );
          })}
        </Slider>
      </div>
      <div className="company_data_table">
        <div className="row p-4">
          <div className="col-12 border p-1">
            <div className="row">
              <div className="col-4">
                <div className="">
                  <ul className="company_list_features mb-0">
                    <li> {strings.product.businessType} </li>
                    <li> {strings.product.totalEmployees} </li>
                    <li> {strings.product.yearEstablished} </li>
                    <li> {strings.product.productCertification} </li>
                    <li> {strings.product.trademarks}</li>
                  </ul>
                </div>
              </div>
              <div className="col p-">
                <div className="">
                  <ul className="company_list_values p-0 mb-0">
                    <li>
                      {exporterData.business_type
                        ? exporterData.business_type
                        : strings.product.notFound}
                    </li>
                    <li>
                      {" "}
                      {exporterData.total_employees
                        ? exporterData.total_employees
                        : strings.product.notFound}{" "}
                    </li>
                    <li>
                      {exporterData.year_established
                        ? exporterData.year_established
                        : strings.product.notFound}
                    </li>
                    <li>
                      {exporterData.product_certification
                        ? exporterData.product_certification
                        : strings.product.notFound}
                    </li>
                    <li>
                      {exporterData.trade_mark
                        ? exporterData.trade_mark
                        : strings.product.notFound}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {exporterData.production_capacity && (
        <>
          <h6 className="main-color mt-5 font-weight-bold">
            {strings.product.productionCapacity}
          </h6>
          <p>{exporterData.production_capacity}</p>
        </>
      )}
      {exporterData.quality_control && (
        <>
          <h6 className="main-color mt-5 font-weight-bold">
            {" "}
            {strings.product.qualityControl}
          </h6>
          <p>
            {exporterData.quality_control
              ? exporterData.quality_control
              : strings.product.notFound}
          </p>
        </>
      )}
      {exporterData.rd_capacity && (
        <>
          <h6 className="main-color mt-5 font-weight-bold">
            {" "}
            {strings.product.RDCapacity}
          </h6>
          <p>{exporterData.rd_capacity}</p>
        </>
      )}
      {exporterData.trade_capacity && (
        <>
          <h6 className="main-color mt-5 font-weight-bold">
            {" "}
            {strings.product.tradeCapacity}
          </h6>

          <p>{exporterData.trade_capacity}</p>
        </>
      )}
    </div>
  );
};
export default CompanyProfile;

// {/* <h6 className="main-color mt-5 font-weight-bold">
//   Factory inspection reports
// </h6>
// <p>
//   {exporterData.factory_reports
//     ? exporterData.factory_reports
//     : strings.product.notFound}
// </p> */}
// {/* <p>
//   {exporterData.trade_capacity
//     ? exporterData.trade_capacity
//     : strings.product.notFound}
// </p>
// <h6 className="main-color mt-5 font-weight-bold">
//   {strings.product.companyLocation}
// </h6> */}
// {/* <h6 className="main-color mt-5 font-weight-bold">
//   {strings.product.companyMail}
// </h6>
// <p>
//   {product.data.company_mail
//     ? product.data.company_mail
//     : strings.product.notFound}
// </p> */}
// {/*
// <h6 className="main-color mt-5 font-weight-bold">
//   {strings.product.companyPhone}
// </h6>
// <p>
//   {product.data.company_phone_number
//     ? product.data.company_phone_number
//     : strings.product.notFound}{" "}
// </p> */}

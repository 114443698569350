import axios from "../../services/axios";
import {
  GET_CATEGORIES,
  LOADING_UI,
  PRODUCT_DETAILS_ERROR,
  LOADING_CATEGORY
} from "./types";

export const getCategories = () => async dispatch => {
  dispatch({ type: LOADING_CATEGORY });
  try {
    dispatch({ type: LOADING_UI });
    const response = await axios.get(`/api/home/categories`, {
      headers: {
        "X-Platform": "web"
      }
    });
    dispatch({ type: GET_CATEGORIES, payload: response.data.data });
  } catch (error) {}
};

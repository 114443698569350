import {
  SEARCH,
  IMG_SEARCH,
  CHANGE_IN_STOCK,
  CHANGE_OUT_STOCK,
  CHANGE_READY_TO_SHIP,
  CHANGE_NOT_TREADY_TO_SHIP,
  CHANGE_UNDER_50,
  CHANGE_FROM_50_TO_100,
  CHANGE_FROM_200_TO_500,
  MORE_500,
  CLEAR_CAT
} from "./types";
import queryString from "query-string";
import _ from "lodash";

export const onFilterByPrice = value => {
  return { type: "ON_CHANGE_PRICE", payload: value };
};

export const onRemovePriceFilter = value => {
  return { type: "ON_REMOVE_PRICE", payload: value };
};

export const onSearchTrigger = (term, categorie) => {
  console.log(term);
  return {
    type: SEARCH,
    payload: { term: term, categorie: categorie }
  };
};

export const searchByImg = (img, imgUrl) => dispatch => {
  dispatch({ type: IMG_SEARCH, payload: img, imgUrl });
};

export const onChangeInStock = () => {
  return { type: CHANGE_IN_STOCK };
};

export const clearCategory = () => {
  return { type: CLEAR_CAT };
};
export const onCategotySeelcted = () => {
  return { type: "ON_SELECT_CATEGORY" };
};
export const onLeaveSearch = () => {
  return { type: "ON_LEAVE_SEARCH_CATEGORY_CLEAR" };
};
export const onChangeOutStock = () => {
  return { type: CHANGE_OUT_STOCK };
};

export const onChanegReadyToShip = () => {
  return { type: CHANGE_READY_TO_SHIP };
};

export const onChaneNotReady = () => {
  return { type: CHANGE_NOT_TREADY_TO_SHIP };
};

export const onCategorySelect = slug => {
  return { type: "ON_SELECT_CATEGORY", payload: slug };
};

export const onSeletQuentity = values => {
  return { type: "ON_CHANGE_QUANTITY", payload: values };
};

export const onRemoveQuentity = values => {
  return { type: "ON_REMOVE_QUANTITY", payload: values };
};

export const onAddCategoy = value => {
  return { type: "ON_SELECT_CATEGORY", payload: value };
};

export const onDeselectCategori = id => {
  return { type: "ON_DESLETC_CATEGORI", payload: id };
};

export const clearCategorys = () => {
  return { type: "_clear_category" };
};

export const onClear = () => {
  return { type: "ON_CLEAR" };
};

export const onDisClear = () => {
  return { type: "disclear" };
};

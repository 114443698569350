import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";
import { closeMapModal } from "../../store/actions/UiAction";

function MapModal(props) {
  const lng = useSelector(state => state.lng);
  const isOpen = useSelector(state => state.ui.mapModal);
  const dispatch = useDispatch();
  return (
    <Modal
      show={isOpen}
      dir={` ${lng === "arabic" ? "rtl" : "ltr"} `}
      onHide={() => closeMapModal()}
      keyboard={true}
      onEscapeKeyDown={() => closeMapModal()}
    >
      <button
        onClick={() => {
          dispatch(closeMapModal());
        }}
        style={{ fontSize: "2rem" }}
        type="button"
        className="close"
        aria-label="Close"
      >
        <span aria-hidden="true" className="float-right mr-3">
          &times;
        </span>
        <h2>Opertiunitys</h2>
      </button>
    </Modal>
  );
}

export default MapModal;

import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import renderHtml from "react-render-html";
import moment from "moment";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { Formik, ErrorMessage } from "formik";
import axios from "../../services/axios";
import LoginForm from "../auth/Login/LoginForm";
import { openModal } from "../../store/actions/UiAction";
import * as Yup from "yup";
import _ from "lodash";
import strings from "../../Localization/LNG";

export default function FARQModal({ isOpen, product, onClose, type }) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [countrys, setCountrys] = useState([]);
  const [isShiping, setIsShiping] = useState(false);
  const isAuth = useSelector((state) => state.user.isAuth);
  const [successMessage, setSuccessMessage] = useState(null);
  const lng = useSelector((state) => state.lng);
  const faqVa = {
    amount: Yup.number()
      .positive(strings.rfqModal.validation.amountMustMoreOne)
      .required(strings.rfqModal.validation.amount),
    payment_type: Yup.string().required(strings.rfqModal.validation.payment),
    address: Yup.string().required(strings.rfqModal.validation.address),
    city: Yup.string().required(strings.rfqModal.validation.city),
    country: Yup.string().required(strings.rfqModal.validation.country),
    phone: Yup.string().required(strings.rfqModal.validation.phone),
    shipping_from: Yup.string().required(
      strings.rfqModal.validation.shippingFromRequired
    ),
    shipping_to: Yup.string().required(
      strings.rfqModal.validation.shippingToRequired
    ),
    type_of_transportation: Yup.string().required(
      strings.rfqModal.validation.typeOfTransportationRequired
    ),
    payment_type: Yup.string().required(
      strings.rfqModal.validation.paymentType
    ),
  };
  if (isShiping === false) {
    delete faqVa.shipping_to;
    delete faqVa.shipping_from;
    delete faqVa.type_of_transportation;
  }
  const validation = Yup.object().shape(faqVa);
  const renderTitle = () => {
    if (type === "exporter") {
      return strings.product.rfqExporter;
    } else if (type === "git-zone") {
      return "RFQ FROM GIT-ZONE";
    }
  };
  useEffect(() => {
    axios.get("/api/countries").then((res) => setCountrys(res.data.data));
  }, [""]);
  const token = localStorage.getItem("token");
  return (
    <Modal
      size="lg"
      show={isOpen}
      dir={`${lng === "arabic" ? "rtl" : "ltr"}`}
      className="farq-modal"
      keyboard={true}
      onHide={onClose}
      onEscapeKeyDown={onClose}
      aria-labelledby="example-custom-modal-styling-title"
    >
      <button
        style={{ fontSize: "2rem" }}
        type="button"
        className="close"
        onClick={onClose}
        aria-label="Close"
      >
        <span aria-hidden="true" className="float-right mr-3">
          &times;
        </span>
      </button>
      <div className="mt-3">
        <h3 className="rfq-title text-center">
          {successMessage ? "" : renderTitle()}{" "}
        </h3>
      </div>

      {isAuth ? (
        <Formik
          enableReinitialize
          initialValues={{
            amount: "",
            payment_type: "",
            product_name: "",
            country: "",
            city: "",
            address: "",
            postal_code: "",
            phone: "",
            type_of_transportation: "",
            notes: "",
            shipping_from: "",
            shipping_to: "",
            special_specifications: "",
          }}
          onSubmit={async (values, { setSubmitting }) => {
            values.product_id = product.id;
            if (type === "exporter") {
              setLoading(true);
              return axios
                .post("/api/RfqExporter", values, {
                  headers: {
                    Authorization: localStorage.getItem("token"),
                  },
                })
                .then((res) => {
                  setLoading(false);
                  onClose();
                  dispatch(
                    openModal("Your request has been sent Wait for Response")
                  );
                })
                .catch((e) => {
                  setLoading(false);
                  setSuccessMessage("An Error Ocuured ");
                  onClose();
                  dispatch(openModal("An error occured"));
                });
            }
            if (type === "git-zone") {
              setLoading(true);
              axios
                .post("/api/RfqAdmin", values, {
                  headers: {
                    Authorization: token,
                  },
                })
                .then((res) => {
                  setLoading(false);
                  onClose();
                  dispatch(
                    openModal("Your request has been sent Wait for Response")
                  );
                })
                .catch((e) => {
                  console.log(e);
                  setLoading(false);
                  onClose();
                  dispatch(openModal("An Error occured"));
                });
            }
          }}
          validationSchema={validation}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            touched,
            setFieldValue,
            errors,
          }) => (
            <>
              <form
                className="faq_form mt-3"
                onSubmit={handleSubmit}
                autoComplete="off"
              >
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <div className="row">
                      <div className="col-4">
                        <img
                          style={{ width: "5rem" }}
                          src={
                            product && product.images && product.images[0]
                              ? product.images[0].url
                              : null
                          }
                          alt=""
                        />
                      </div>
                      <div className="col pl-0">
                        <p
                          className={`main-color ${
                            lng === "arabic" ? "text-right" : "text-left"
                          } d-inline-block font-weight-bold`}
                        >
                          {product.name}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <div className="form-group">
                      <input
                        type="number"
                        value={values.amount}
                        onChange={handleChange}
                        placeholder={strings.rfqModal.amount}
                        className="form_input"
                        onBlur={handleBlur}
                        name="amount"
                      />
                      <div
                        className={`error ${
                          lng === "arabic" ? "float-left" : "float-right"
                        } `}
                      >
                        {errors.amount && touched.amount && errors.amount}
                      </div>
                    </div>
                  </div>
                </div>
                {product.description && (
                  <>
                    <div className="row mt-2">
                      <div className="col">
                        <h5
                          className={` rfq-title ${
                            lng === "arabic" ? "float-right" : "float-left"
                          }  `}
                        >
                          {strings.rfqModal.description}
                        </h5>
                      </div>
                    </div>
                    {renderHtml(product.description)}
                  </>
                )}
                <div className="payment_type mt-3">
                  <h5
                    className={`rfq-title ${
                      lng === "arabic" ? "text-right" : "text-left"
                    }`}
                  >
                    {strings.rfqModal.paymentType}
                  </h5>
                  <div
                    className={`form-group p-3 pl-5 ${
                      lng === "arabic" ? "text-right" : ""
                    } `}
                  >
                    <div className="custom-control custom-radio custom-control-inline">
                      <input
                        type="radio"
                        id="customRadioInline1"
                        name="payment_type"
                        onChange={handleChange}
                        value="Open Delivery"
                        className="custom-control-input"
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="customRadioInline1"
                      >
                        {strings.bestQuotationForm.cachOnDelivery}
                      </label>
                    </div>
                    <div className="custom-control custom-radio custom-control-inline">
                      <input
                        type="radio"
                        id="customRadioInline2"
                        onChange={handleChange}
                        name="payment_type"
                        value="In Advance"
                        className="custom-control-input"
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="customRadioInline2"
                      >
                        {strings.bestQuotationForm.inAdvance}
                      </label>
                    </div>
                    <div className="custom-control custom-radio custom-control-inline">
                      <input
                        type="radio"
                        id="customRadioInline3"
                        onChange={handleChange}
                        value="By Instaliment"
                        name="payment_type"
                        className="custom-control-input"
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="customRadioInline3"
                      >
                        {strings.bestQuotationForm.byInstallment}
                      </label>
                    </div>
                    <div
                      className={`error ${
                        lng === "arabic" ? "float-left" : "float-right"
                      } `}
                    >
                      {errors.payment_type &&
                        touched.payment_type &&
                        errors.payment_type}
                    </div>
                  </div>
                </div>
                <h5
                  className={`rfq-title ${
                    lng === "arabic" ? "text-right" : "text-left"
                  } mt-3`}
                >
                  {strings.rfqModal.address}
                </h5>
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <div className="form-group">
                      <select
                        value={values.country}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="country"
                      >
                        <option value="" disabled>
                          {strings.rfqModal.country}
                        </option>
                        {_.map(countrys, (country) => (
                          <option value={country.name}> {country.name} </option>
                        ))}
                      </select>
                      <div
                        className={`error ${
                          lng === "arabic" ? "float-left" : "float-right"
                        } `}
                      >
                        {errors.country && touched.country && errors.country}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <div className="form-group">
                      <input
                        type="text"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name="city"
                        value={values.city}
                        placeholder={strings.rfqModal.city}
                        className="form_input"
                      />
                      <div className="error float-right">
                        {errors.city && touched.city && errors.city}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form_input"
                        name="address"
                        onChange={handleChange}
                        placeholder={strings.rfqModal.address}
                        value={values.address.address}
                        onBlur={handleBlur}
                      />
                      <div
                        className={`error ${
                          lng === "arabic" ? "float-left" : "float-right"
                        } `}
                      >
                        {errors.address && touched.address && errors.address}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <div className="form-group">
                      <input
                        className="input form_input"
                        name="postal_code"
                        value={values.postal_code}
                        placeholder={strings.rfqModal.zib}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      <div className="error float-right">
                        {errors.postal_code &&
                          touched.postal_code &&
                          errors.postal_code}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <div className="form-group">
                      <input
                        name="phone"
                        value={values.address.phone}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder={strings.rfqModal.phone}
                        className="form_input"
                        type="number"
                      />
                      <div
                        className={`error ${
                          lng === "arabic" ? "float-left" : "float-right"
                        } `}
                      >
                        {errors.phone && touched.phone && errors.phone}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <h5
                    className={`rfq-title ${
                      lng === "arabic" ? "text-right" : "text-left"
                    }`}
                  >
                    {strings.rfqModal.specialSpecifications}{" "}
                  </h5>
                  <textarea
                    cols="4"
                    rows="4"
                    value={values.special_specifications}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="special_specifications"
                    placeholder={strings.rfqModal.specialSpecifications}
                    type="text"
                    className="form_input"
                  />
                </div>
                <h5
                  className={`rfq-title ${
                    lng === "arabic" ? "text-right" : "text-left"
                  } mt-3 `}
                >
                  {strings.rfqModal.shippingInfo}
                  <span className="float-right">
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        checked={isShiping}
                        onChange={() => setIsShiping(!isShiping)}
                        id="isShiming"
                      />
                      <label
                        className="custom-control-label isShiping_op"
                        htmlFor="isShiming"
                      ></label>
                    </div>
                  </span>
                </h5>
                {isShiping && (
                  <>
                    <div className="row">
                      <div className="col-md-6 col-sm-12">
                        <div className="row">
                          <div className="col-md-6 col-sm-12">
                            <div className="form-group position-relative">
                              <DatePicker
                                onChange={(date) =>
                                  setFieldValue("shipping_from", date)
                                }
                                selected={values.shipping_from}
                                placeholderText={strings.rfqModal.from}
                                minDate={moment().toDate() - 1}
                                wrapperClassName="w-100"
                                className="form_input"
                                name="shipping_from"
                                onBlur={handleBlur}
                              />
                              <div
                                className={`faq-date-icon-from ${
                                  lng === "arabic" ? "ar" : ""
                                }  position-absolute`}
                              >
                                <FontAwesomeIcon icon={faCalendarAlt} />
                              </div>
                              <ErrorMessage
                                name="shipping_from"
                                className={`error ${
                                  lng === "arabic" ? "float-left" : "float-left"
                                } `}
                                component="span"
                              />
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-group">
                              <DatePicker
                                onChange={(date) =>
                                  setFieldValue("shipping_to", date)
                                }
                                selected={values.shipping_to}
                                disabled={!values.shipping_from ? true : false}
                                minDate={moment(values.shipping_from).toDate()}
                                placeholderText={strings.rfqModal.to}
                                className="form_input"
                                onBlur={handleBlur}
                                name="shipping_to"
                                wrapperClassName="w-100"
                              />
                              <div
                                className={`faq-date-icon-to ${
                                  lng === "arabic" ? "ar" : ""
                                } position-absolute`}
                              >
                                <FontAwesomeIcon icon={faCalendarAlt} />
                              </div>
                              <ErrorMessage
                                name="shipping_to"
                                className={`error ${
                                  lng === "arabic" ? "float-left" : "float-left"
                                } `}
                                component="span"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        <div className="form-group">
                          <select
                            value={values.type_of_transportation}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="type_of_transportation"
                            placeholder="Type of transportation"
                          >
                            <option value="" disabled>
                              {strings.rfqModal.typeOfTransportation}
                            </option>
                            <option value="Ocean transportation">
                              {
                                strings.shipmentQuotation.shippingMethods
                                  .oceanTransportation
                              }
                            </option>
                            <option value="Land transportation" defaultChecked>
                              {
                                strings.shipmentQuotation.shippingMethods
                                  .landTransportation
                              }
                            </option>
                            <option value="Air transportation">
                              {
                                strings.shipmentQuotation.shippingMethods
                                  .airTransportation
                              }
                            </option>
                          </select>
                          <ErrorMessage
                            name="type_of_transportation"
                            className={`error ${
                              lng === "arabic" ? "float-left" : "float-right"
                            } `}
                            component="span"
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}
                <div className={`row ${isShiping ? "" : "mt-3"} `}>
                  <div className="col">
                    <div className="form-group">
                      <textarea
                        name="notes"
                        id=""
                        cols="4"
                        rows="4"
                        value={values.notes}
                        className="form_input"
                        style={{ height: "auto" }}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder={strings.rfqModal.note}
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div className="text-center">
                  <button type="submit" className="submit_button">
                    {loading ? (
                      <>
                        <div
                          className="spinner-border spinner-border-sm"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      </>
                    ) : (
                      strings.rfqModal.submitRfq
                    )}
                  </button>
                </div>
              </form>
            </>
          )}
        </Formik>
      ) : (
        <LoginForm />
      )}
    </Modal>
  );
}
const backButton = {
  cursor: "pointer",
  border: "none",
  padding: "8px",
  borderRadius: "6px",
  width: "11rem",
  color: "white",
  background: "#5B5756",
};

{
  /* {values.payment_type === "Per milestone" && (
                    <div className="milestone">
                      <h5 className="rfq-title text-left"> Milestone </h5>
                      <div className="row">
                        <div className="col-md-4 col-sm-12">
                          <DatePicker
                            onChange={date =>
                              setFieldValue("milestone_1", date)
                            }
                            name="milestone_1"
                            selected={values.milestone_1}
                            minDate={moment(new Date()).toDate()}
                            placeholderText="Milestone 1"
                            className="form_input"
                            wrapperClassName="w-100"
                          />
                        </div>
                        <div className="col-md-4 col-sm-12">
                          <DatePicker
                            onChange={date =>
                              setFieldValue("milestone_2", date)
                            }
                            name="milestone_2"
                            selected={values.milestone_2}
                            minDate={moment(values.milestone_1).toDate()}
                            placeholderText="Milestone 2"
                            className="form_input"
                            wrapperClassName="w-100"
                          />
                        </div>
                        <div className="col-md-4 col-sm-12">
                          <DatePicker
                            onChange={date =>
                              setFieldValue("milestone_3", date)
                            }
                            name="milestone_3"
                            selected={values.milestone_3}
                            minDate={moment(values.milestone_2).toDate()}
                            placeholderText="Milestone 3"
                            className="form_input"
                            wrapperClassName="w-100"
                          />
                        </div>
                      </div>
                    </div>
                  )} */
}

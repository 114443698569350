import React, { useState, useEffect, useRef } from "react";
import "./search_bar.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import {
  searchByImg,
  onLeaveSearch,
  onClear
} from "../../store/actions/searchActions";
import Strings from "../../Localization/LNG";
import { faChevronDown, faBell } from "@fortawesome/free-solid-svg-icons";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { onSearchTrigger } from "../../store/actions/searchActions";
import { useHistory } from "react-router-dom";
import strings from "../../Localization/LNG";
import Autosuggest from "react-autosuggest";

function SearchBar(props) {
  const [categorie, setCategories] = useState(Strings.allCategories);
  const selectedLng = useSelector(state => state.lng);
  const [selectedImage, setSelectedImage] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const categorys = useSelector(state => state.categories.categories);
  const wishlist = useSelector(state => Object.values(state.wishlist.items));
  const carts = useSelector(state => Object.values(state.cart.items));
  const isAppLoading = useSelector(state => state.ui.loadingApp);
  const searchStore = useSelector(state => state.search);
  const isAuth = useSelector(state => state.user.isAuth);
  const dispatch = useDispatch();
  const [searchError, setSearchError] = useState(null);
  const history = useHistory();
  const uploadEl = useRef(null);
  const [img, setImg] = useState(null);
  const { location } = props;
  const oldURL = useRef(location.key);
  const searchRef = useRef();
  const renderCart = length => {
    return length;
  };

  useEffect(() => {
    if (location.pathname !== "/search") {
      setSearchTerm("");
      setCategories(Strings.allCategories);
      setSearchError(null);
      dispatch(searchByImg(null, null));
      dispatch(onLeaveSearch());
    }
    if (searchStore.isCategorySelected) {
      setCategories(Strings.allCategories);
    }
    oldURL.current = location.key;
  }, [location.key, searchStore.isCategorySelected]);
  const fileSelectHandler = e => {
    const selectedImg = e.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = function(e) {
      setImg(e.target.result);
      setSearchError(null);
      dispatch(searchByImg(selectedImg, e.target.result));
      history.push(`/search?s=${searchTerm}&category=${categorie}`);
    };
  };

  const onSelecteCategory = cat => {
    setSearchError(null);
    setCategories(cat);
    doSearch(cat);
  };
  
  const getSuggestionValue = suggestion => suggestion.name;

  const onClickSearch = e => {
    searchRef.current.select();
  };

  const doSearch = (category) =>{
    if (category === "All Categories" && !searchTerm) {
      setSearchError(strings.searchErrorMessage);
      return;
    }
    setSearchError(null);
    dispatch(onClear());
    dispatch(searchByImg(null, null));
    history.push(`/search?s=${searchTerm}&category=${category}`);
    dispatch(onSearchTrigger(searchTerm, category));
  }

  const onSearch = e => {
    e.preventDefault();
    console.log(categorie);
    if (categorie === "All Categories" && !searchTerm) {
      setSearchError(strings.searchErrorMessage);
      return;
    }
    setSearchError(null);
    dispatch(onClear());
    dispatch(searchByImg(null, null));
    history.push(`/search?s=${searchTerm}&category=${categorie}`);
    dispatch(onSearchTrigger(searchTerm, categorie));
  };
  const onUpload = () => {
    uploadEl.current.click();
  };
  const renderSuggestion = suggestion => <div>{suggestion.name}</div>;
  if (!isAppLoading) {
    return (
      <form onSubmit={onSearch} style={{ marginTop: "77px" }}>
        <div
          dir={selectedLng === "arabic" ? "rtl" : "ltr"}
          className="row mt-4"
        >
          <div className={`col-md-1 git-zone-logo`}>
            <Link to="/">
              <img
                className="logo"
                src={require("../../assets/Git-zone-logo.png")}
                alt=""
              />
            </Link>
          </div>
          <div
            className={`col-lg-9 col-md-10 ${
              selectedLng === "arabic" ? "searc_b_en" : "searc_b_er"
            } bar `}
          >
            <div
              className={`input-group search_section h-75  ${
                selectedLng === "arabic" ? "ar" : "en"
              } `}
            >
              <div className="input-group-prepend">
                <button
                  className="btn categories_btn dropdown-toggle"
                  type="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  style={{ fontFamily: "gunar-medium" }}
                  aria-expanded="false"
                >
                  {categorie.name ? categorie.name : categorie}
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    className={selectedLng === "arabic" ? "mr-1" : "ml-1"}
                  />
                </button>
                <div
                  className={`dropdown-menu ${
                    selectedLng === "arabic" ? "w-100 cat-drob-ara" : "w-100"
                  }`}
                >
                  <a
                    onClick={() => setCategories(Strings.allCategories)}
                    className="dropdown-item font-weight-bold search_cat_item border-bottom"
                    href="#"
                  >
                    {Strings.allCategories}
                  </a>
                  {categorys ? (
                    <>
                      {categorys.map(category => {
                        return (
                          <>
                            <div key={category.id}>
                              <a
                                onClick={e => {
                                  e.preventDefault();
                                  onSelecteCategory(category);
                                }}
                                className="dropdown-item font-weight-bold search_cat_item border-bottom"
                                href="#"
                              >
                                {category.name}
                              </a>
                            </div>
                          </>
                        );
                      })}
                    </>
                  ) : (
                    <div className="dropdown-item">Loading...</div>
                  )}
                </div>
              </div>
              <input
                key="searchBarText"
                type="text"
                placeholder={searchError ? searchError : Strings.search}
                value={searchTerm}
                ref={searchRef}
                onClick={onClickSearch}
                onChange={e => setSearchTerm(e.target.value)}
                className={`form-control  search_bar ${
                  searchError ? "search-error" : ""
                }`}
              />
              {/* <Autosuggest
                suggestions={[]}
                renderSuggestion={renderSuggestion}
                getSuggestionValue={getSuggestionValue}
                onSuggestionsClearRequested={e => console.log(e)}
                onSuggestionsFetchRequested={e => console.log(e)}
                inputProps={{
                  onChange: e => setSearchTerm(e.target.value),
                  className: "form-control  search_bar",
                  value: { searchTerm }
                }}
              /> */}
              <input
                type="file"
                accept="image/x-png,image/gif,image/jpeg"
                onChange={fileSelectHandler}
                ref={uploadEl}
                hidden
              />
              <div
                onClick={onUpload}
                className={`image_upload ${
                  selectedLng === "arabic" ? "rtl" : "ltr"
                }  `}
              >
                <img src={require("../../assets/icons/Shape 833.png")} alt="" />
              </div>
              <div className="input-group-prepend">
                <button
                  type="submit"
                  className={`search_btn ${
                    selectedLng === "arabic" ? "rtl" : "ltr"
                  } `}
                >
                  <FontAwesomeIcon icon={faSearch} /> {Strings.search}
                </button>
              </div>
            </div>
          </div>
          <div
            className={`col-md-2 icons p-2 ${
              selectedLng === "arabic" ? "text-right" : ""
            } `}
          >
            <div className={`top_shop_icons`}>
              <Link to="/wishlist" style={{ position: "relative" }}>
                <img
                  className={`mr-2`}
                  src={require("../../assets/icons/wishlist.png")}
                  alt=""
                />
                <span className="badge whishList-badge ">
                  {renderCart(wishlist.length)}
                </span>
              </Link>
              <Link to="/cart" style={{ position: "relative" }}>
                <img
                  src={require("../../assets/icons/cart.png")}
                  className={selectedLng == "arabic" ? "mr-2" : "ml-2"}
                  alt=""
                />
                <span
                  className={`badge ${
                    selectedLng === "arabic" ? "cart-badge-ar" : "cart-badge-en"
                  } `}
                >
                  {renderCart(carts.length)}
                </span>
              </Link>
              {/* {isAuth && (
                <div className={`notification ${selectedLng === 'arabic' ? 'mr-1': ''} `}>
                  <div className="bell">
                    <FontAwesomeIcon icon={faBell} />
                  </div>
                </div>
              )} */}
            </div>
          </div>
        </div>
      </form>
    );
  } else {
    return null;
  }
}

const rtlStyle = {
  zIndex: 3,
  position: "absolute",
  right: "8rem",
  top: "33%",
  cursor: "pointer"
};

const ltrStyle = {
  zIndex: 3,
  position: "absolute",
  left: "8rem",
  top: "33%",
  cursor: "pointer"
};

export default withRouter(SearchBar);

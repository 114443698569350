import LocalizedStrings from "react-localization";
import ARABIC_TERMIS from "./terms/ar";
import ENGLISH_TERMIS from "./terms/en";
import TURKY_TERMIS from "./terms/tr";
import GERMAN_TERMIS from "./terms/de";
import FRENSH_TERMIS from "./terms/fr";
let strings = new LocalizedStrings({
  english: ENGLISH_TERMIS,
  arabic: ARABIC_TERMIS,
  turkey: TURKY_TERMIS,
  germany: GERMAN_TERMIS,
  french: FRENSH_TERMIS
});

export default strings;

// en: ENGLISH_TERMIS,
//   ar: ARABIC_TERMIS,
//   tr: TURKY_TERMIS,
//   de: GERMAN_TERMIS,
//   fr: FRENSH_TERMIS

import {
  GET_PRODUCTS,
  LOADING_PRODUCT,
  GET_PRODUCT,
  PRODUCT_DETAILS_ERROR
} from "../actions/types";

const products = (state = { loading: false, error: false }, action) => {
  switch (action.type) {
    case GET_PRODUCTS:
      return {
        ...action.payload,
        loading: false,
        error: false
      };
    case LOADING_PRODUCT:
      return {
        ...state,
        loading: true,
        error: false
      };
    case LOADING_PRODUCT: {
      return {
        ...state,
        loading: true,
        error: false
      };
    }
    case GET_PRODUCT:
      return {
        ...state,
        loading: false,
        product: action.payload,
        error: false
      };
    case PRODUCT_DETAILS_ERROR:
      return {
        ...state,
        loading: false,
        error: true
      };
    default:
      return state;
  }
};

export default products;

import React from "react";
import { useSelector } from "react-redux";
import LogoSpinner from "../utils/LogoSpinner";
function PageNotFound() {
  const isAppLoading = useSelector(state => state.ui.loadingApp);

  if (!isAppLoading) {
    return (
      <div className="container">
        <div className="mt-5 text-center">
          <img
            style={{ height: "70vh" }}
            src={require("../../assets/svg/page_not_found_su7k.svg")}
            alt=""
          />
          <h2> 404 Page Not Found </h2>
        </div>
      </div>
    );
  } else return <LogoSpinner />;
}

export default PageNotFound;

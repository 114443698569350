import React from "react";
import PathComponent from "../utils/Path";
import { Link } from "react-router-dom";
import strings from "../../Localization/LNG";
import { useSelector } from "react-redux";

function DetailsPath({ productName }) {
  const lng = useSelector(state => state.lng);
  return (
    <PathComponent>
      <li className="breadcrumb-item">
        <Link to="/">{strings.path.home}</Link>
      </li>
      <li className="breadcrumb-item active" aria-current="page">
        <span className={`${lng === "arabic" ? "mr-2" : ""}`}>
          {productName}
        </span>
      </li>
    </PathComponent>
  );
}

export default DetailsPath;

import React from "react";
import "./style.scss";
import Rater from "react-rater";
import _ from "lodash";
import {
  faPlus,
  faMinus,
  faImage,
  faPlayCircle,
  faVrCardboard,
} from "@fortawesome/free-solid-svg-icons";
import Price from "../../utils/Price";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import Slider from "react-slick";
import { withRouter } from "react-router-dom";
import "react-rater/lib/react-rater.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import NumberFormat from "react-number-format";
import {
  openLoginModal,
  openModal,
  CheckNotIpload,
} from "../../../store/actions/UiAction";
import strings from "../../../Localization/LNG";
import Video360 from "./Video360";
import { addToCart } from "../../../store/actions/cartActions";
import axios from "axios";
import PicesText from "../../utils/PicesText";
class ShowCase extends React.Component {
  state = {
    mainImg: _.head(this.props.product.images),
    isImageOpen: false,
    photoIndex: 0,
    selectedVideo: null,
    selected360: null,
    showModal: false,
  };
  videoRef = React.createRef();
  componentWillReceiveProps(next) {
    this.setState({ mainImg: _.head(next.product.images) });
  }
  onCloseModal = () => {
    this.setState({ showModal: false });
  };

  renderCutomizationOption = () => {
    const { product } = this.props;
    if (
      product.customize_option_logo === 1 ||
      product.customize_option_packaging === 1
    ) {
      return (
        <p className="mt-3">
          <span style={{ fontWeight: "bold" }}>
            {strings.product.customization}
          </span>
          <span>
            {product.customize_option_logo === 1
              ? strings.product.customizationLogo
              : ""}
            {product.customize_option_packaging === 1
              ? ` / ${strings.product.customizationPackaging}`
              : ""}
          </span>
        </p>
      );
    } else return null;
  };

  onHoverVideo = () => {
    this.videoRef.current.setAttribute("controls", "controls");
  };
  onLeaveVideo = () => {
    this.videoRef.current.removeAttribute("controls");
  };
  renderViewer = () => {
    const { selectedVideo, mainImg, selected360 } = this.state;
    if (selectedVideo) {
      return (
        <div className="p-3">
          <video
            ref={this.videoRef}
            className="product_video img-fluid"
            onMouseEnter={this.onHoverVideo}
            onMouseLeave={this.onLeaveVideo}
          >
            <source src={selectedVideo.url} />
          </video>
        </div>
      );
    } else if (selected360) {
      return <Video360 url={selected360.url} />;
    } else {
      return (
        <div className="p-3">
          <img
            src={
              mainImg
                ? mainImg.url
                : require("../../../assets/placeholder/200X200.jpg")
            }
            className="img-fluid "
          />
        </div>
      );
    }
  };

  openViewerModal = () => {
    const { selected360, selectedVideo } = this.state;
    if (selectedVideo) {
      this.videoRef.current.requestFullscreen();
      return;
    } else if (selected360) {
      this.setState({ showModal: true });
      return;
    }
    this.setState({ isImageOpen: true });
  };
  renderRangePrice = () => {
    const { product } = this.props;
    if (+product.range_price_from > 0 || +product.range_price_to > 0) {
      return (
        <p>
          <Price price={product.range_price_from} /> -
          <Price price={product.range_price_to} />
          <PicesText product_unite={product.product_unite} />
        </p>
      );
    } else return null;
  };
  render() {
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 5,
      slidesToScroll: 4,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 734,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
      ],
    };
    const { product } = this.props;
    const { mainImg, quantity, photoIndex } = this.state;
    let images = [];
    product.images.forEach((img) => {
      const url = img.url;
      images.push(url);
    });
    const { showModal, selectedVideo, selected360 } = this.state;
    return (
      <div className="row">
        <div className="col-md-6 col-sm-12">
          {this.state.isImageOpen && (
            <>
              <Lightbox
                mainSrc={images[photoIndex]}
                imageTitle={product.name}
                nextSrc={images[(photoIndex + 1) % images.length]}
                prevSrc={
                  images[(photoIndex + images.length - 1) % images.length]
                }
                onCloseRequest={() => this.setState({ isImageOpen: false })}
                onMovePrevRequest={() =>
                  this.setState({
                    photoIndex:
                      (photoIndex + images.length - 1) % images.length,
                  })
                }
                onMoveNextRequest={() =>
                  this.setState({
                    photoIndex: (photoIndex + 1) % images.length,
                  })
                }
              />
            </>
          )}
          <div className="">
            <div
              className={`div border ${
                selected360 ? "" : "p-3"
              } product_showcase_media  overflow-hidden`}
              style={{ width: "100%", textAlign: "center" }}
            >
              {this.renderViewer()}
              <span
                onClick={this.openViewerModal}
                className={`large_img text-right ${
                  selected360 ? "d-none" : ""
                }`}
              >
                <div className="large_img_icon">
                  <img
                    src={require("../../../assets/svg/expand-alt-solid.svg")}
                    alt=""
                  />
                </div>
              </span>
            </div>
            <div>
              <div
                className={`mt-2 ${
                  this.props.lng === "arabic" ? "text-right" : ""
                } `}
              >
                <Slider className="product-slider-showcase" {...settings}>
                  {_.map(product.images, (img, index) => {
                    return img.url ? (
                      <div
                        className="imge_min position-relative"
                        onClick={() => {
                          this.setState({ mainImg: img });
                          this.setState({ selectedVideo: null });
                          this.setState({ selected360: null });
                          this.setState({ photoIndex: index });
                        }}
                      >
                        <img
                          className="img-thumbnail product_under_videer showcaseImg"
                          src={img.url}
                        />
                        <div className="overlay_img position-absolute">
                          <div className="video-icons">
                            <FontAwesomeIcon icon={faImage} />
                          </div>
                        </div>
                      </div>
                    ) : null;
                  })}
                  {_.map(product.video, (video) => {
                    return video.url ? (
                      <div
                        className="imge_min position-relative"
                        onClick={() => this.setState({ selectedVideo: video })}
                      >
                        <video
                          className="img-thumbnail product_under_videer showcaseImg"
                          src={video.url}
                          controls={false}
                        />
                        <div className="overlay_img position-absolute">
                          <div className="video-icons">
                            <FontAwesomeIcon icon={faPlayCircle} />
                          </div>
                        </div>
                      </div>
                    ) : null;
                  })}
                  {_.map(product.images360, (img) => {
                    return img.url ? (
                      <div
                        className="imge_min position-relative"
                        onClick={() => {
                          this.setState({ selected360: img });
                          this.setState({ selectedVideo: null });
                        }}
                      >
                        <img
                          style={{ cursor: "pointer" }}
                          className="img-thumbnail product_under_videer showcaseImg"
                          src={img.url}
                        />
                        <div className="overlay_img position-absolute">
                          <div className="video-icons">
                            <FontAwesomeIcon icon={faVrCardboard} />
                          </div>
                        </div>
                      </div>
                    ) : null;
                  })}
                </Slider>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`col-md-6 col-sm-12 product_details_showCase ${
            this.props.lng === "arabic" ? "text-right" : ""
          }`}
        >
          <h6 style={{ color: "#bd1723", fontFamily: "gunar-medium" }}>
            {product.name}
          </h6>
          <div className="ml-4">
            <div className="rating" style={{ fontSize: "2rem" }}>
              <Rater interactive={false} total={5} rating={product.rating} />
            </div>
            <p>
              <Price price={product.regular_price} />
              <span>
                {" "}
                {strings.product.forOnePrice} {"  "}
                <PicesText product_unite={product.product_unite} />
              </span>
            </p>
            {this.renderRangePrice()}
            <p>
              {product.minimum_quantity}{" "}
              <span>
                {+product.minimum_quantity > 1
                  ? `${product.product_unite} ${strings.product.piecesMinOrder}`
                  : `${product.product_unite} ${strings.product.pieceMinOrder}`}
              </span>
            </p>
            <p>
              <span> {strings.product.stockInAndOut} </span>
              <span
                className={`${
                  product.stock === 0 ? "text-danger" : "text-success"
                } ${this.props.lng === "arabic" ? "mr-3" : "ml-3"}`}
              >
                {product.stock === 0
                  ? strings.product.notAvilable
                  : strings.product.avilable}
              </span>
            </p>
            <hr />
            {+product.fob_price > 0 ? (
              <div className="custom-control custom-checkbox">
                <input
                  type="checkbox"
                  class="custom-control-input"
                  id="fob"
                  checked={this.props.isFob}
                  onChange={this.props.onChangeFob}
                />
                <label
                  style={{ lineHeight: "24px" }}
                  class="custom-control-label"
                  for="fob"
                >
                  FOB
                </label>
                <span
                  className={` ${
                    this.props.lng === "arabic" ? "float-left" : "float-right"
                  }`}
                >
                  <Price price={product.fob_price} />
                </span>
              </div>
            ) : null}
            <div class="btn-group mt-3" role="group" aria-label="Basic example">
              <span
                className={` ${this.props.lng === "arabic" ? "ml-2" : "mr-2"}`}
                style={{ color: "#bd1723", fontWeight: 600, fontSize: "14px" }}
              >
                {strings.product.quantity}
              </span>
              <button
                onClick={this.props.onDecreesQuantity}
                style={quantityBtn}
                type="button"
                disabled={+product.stock > 0 ? false : true}
              >
                <FontAwesomeIcon icon={faMinus} />
              </button>
              <input
                type="number"
                name="quantity"
                disabled={+product.stock > 0 ? false : true}
                required
                className="text-center quantity--input"
                onChange={(e) => this.props.onChangeQuantity(e.target.value)}
                value={this.props.quentity}
                style={{ width: "4rem", border: "1px solid #E5E5E5" }}
              />
              <button
                onClick={this.props.onIncressQuantity}
                style={quantityBtn}
                type="button"
                disabled={+product.stock > 0 ? false : true}
              >
                <FontAwesomeIcon icon={faPlus} size="1x" />
              </button>
              <span
                className={` ${this.props.lng === "arabic" ? "mr-2" : "ml-2"}`}
              >
                <PicesText product_unite={product.product_unite} />
              </span>
            </div>
            {+product.sample_price > 0 && (
              <>
                {+product.stock > 0 && (
                  <p className="mt-3">
                    <span style={{ fontWeight: "bold" }}>
                      {strings.product.sample}
                    </span>
                    <small className="m-1">
                      {+product.sample_price > 0
                        ? ` ${product.sample_price} $ ${strings.product.forOnePrice}`
                        : strings.product.free}
                    </small>
                    <button
                      onClick={() => {
                        if (!this.props.isAuth) {
                          this.props.openLoginModal(strings.loginFirst);
                          return;
                        }
                        const isImporter = _.some(
                          this.props.userRoules.roles,
                          (r) => r.name === "importer"
                        );
                        if (!isImporter) {
                          this.props.openModal(
                            strings.permistionMessages.mustImporter
                          );
                          return;
                        }
                        const {
                          docs_uploaded,
                          approved,
                        } = this.props.userRoules;
                        if (!docs_uploaded) {
                          this.props.CheckNotIpload();
                          return;
                        }
                        if (approved === "waiting" || approved === "rejected") {
                          this.props.openModal(
                            approved === "waiting"
                              ? "Your account must be approved first"
                              : "your registration has been rejected ,Please check your email "
                          );
                          return;
                        }
                        this.props.history.push("/cart");
                        this.props.addToCart(
                          product,
                          1,
                          false,
                          product.fob_pice,
                          true
                        );
                      }}
                      style={buySampel}
                      className={`${this.props.lng === "arabic" ? "mr-1" : ""}`}
                    >
                      {strings.product.buySample}
                    </button>
                  </p>
                )}
              </>
            )}
            {/* <p> {product.unite} </p> */}
            {this.renderCutomizationOption()}
          </div>
        </div>
      </div>
    );
  }
}

const quantityBtn = {
  background: "transparent",
  border: "1px solid #E5E5E5",
  width: "2rem",
  color: "#bd1723",
  fontSize: "12px",
};

const buySampel = {
  border: "none",
  background: "#bd1723",
  color: "white",
  fontSize: "13px",
  marginLeft: "15px",
};

// SAMPLE CODE
// {
//   if (this.props.isAuth) {
//     const isImporter = _.some(
//       this.props.userRoules.roles,
//       r => r.name === "importer"
//     );
//     if (!isImporter) {
//       this.props.openModal(
//         strings.permistionMessages.mustImporter
//       );
//       return;
//     }
//     const { docs_uploaded, approved } = this.props.userRoules;
//     if (!docs_uploaded) {
//       this.props.CheckNotIpload();
//       return;
//     }
//     if (approved === "waiting" || approved === "rejected") {
//       this.props.openModal(
//         approved === "waiting"
//           ? "your account must approved"
//           : "your registration has been rejected ,Please check your email "
//       );
//       return;
//     }
//     this.props.history.push(
//       `/checkout/${product.id}?sample=true&quentity=${
//         this.props.quentity
//       }&slug=${product.slug}${
//         this.props.tradeAssurance
//           ? "&trade_accurance=true"
//           : ""
//       }`
//     );
//   } else {
//     this.props.openLoginModal(strings.loginFirst);
//   }
// }

function mapStateToProps(state) {
  return {
    lng: state.lng,
    isAuth: state.user.isAuth,
    userRoules: state.user.user,
    currency: state.currency.currency,
  };
}

export default connect(mapStateToProps, {
  openLoginModal,
  openModal: openModal,
  CheckNotIpload,
  addToCart,
})(withRouter(ShowCase));

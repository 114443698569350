const fr = {
  nav: {
    home: "Accueil",
    services: "Services",
    aboutUs: "À Propos",
    connectUs: "Contactez-nous",
    plans: "Formules d'Abonnement ",
    abuse: "Signaler un Abus",
    news: "Actualités ",
    languages: {
      arabic: "العربية",
      english: "English",
      turkey: "Türkçe",
      germany: "Deutsch ",
      french: "Français",
    },
    currancy: { dollar: "Dollar", lira: "Lira" },
    auth: "S'identifier/S'inscrire",
    login: "S'identifier",
    reqister: "S'inscrire",
  },
  searchErrorMessage: "Saisissez un texte ou téléchargez une image ",
  categorie: "catégories",
  search: "chercher",
  otherCategories: "Autres catégories",
  allCategories: "Toutes catégories",
  share: "Partagez-le",
  login: {
    login: "S'identifier",
    signup: "Créer un Nouveau Compte",
    email: "Email",
    password: "Password",
    forgetPassword: "Mot de passe oublié",
    required: "Required",
    emailNotValid: "Invalid Email Address",
    google: "Google",
    profile: "Profile",
    logout: "Logout",
    loginMessage: "Connectez-vous à votre compte",
  },
  sectionsTitle: {
    hotDeals: "Bons plans",
    forLimitTime: "Offers For Limited Time Only !",
    bestSeller: "Meilleures ventes",
    dealsOf: "Offres de",
    theMonth: "le mois",
    productsCategories: "Catégories de produits",
    readyToShip: "Prêt pour expédition",
    specialItems: "Articles spéciaux",
    currancyConventer: "Convertisseur de devises",
  },
  bestQuotationForm: {
    bestQuotationTitle: "Prochaine étape ? Demander un devis",
    name: "Nom",
    phone: "Numéro de téléphone",
    country: "pays",
    email: "Email",
    productName: "Nom du produit",
    unitAmount: "Montant unitaire",
    description: "La description",
    addressTo: "Adressé à",
    paymentTerms: "Modalités de paiement",
    date: "Date",
    requestPricing: "DEMANDER UN PRIX",
    hint:
      "Un produit vous intéresse ? Veuillez remplir le formulaire et nous vous enverrons un devis estimatif pour le produit concerné.",
    submitButtonText: "Demande de devis",
    Date_per_each_mile_stone: "Date par mille de pierre",
    cachOnDelivery: "Paiement à la livraison",
    inAdvance: "En avance",
    byInstallment: "By Installment",
  },
  brands: { title: "Partenaires pour la réussite" },
  featurs: {
    importing: {
      title: "Importation",
      text:
        "Gagnez du temps, évitez de voyager dans plusieurs pays pour votre approvisionnement et commencez à vous procurer des produits sur le marché de gros en ligne. GIT-ZONE.com vous donne accès à des milliers de fournisseurs, de différents endroits et à un large éventail de catégories pour des importations. ",
    },
    exporting: {
      title: "Exportation",
      text:
        "Donnez à votre produit la visibilité qu'il mérite et présentez-le en ligne. Les marchés de gros en ligne ont prouvé leur capacité à accroître la rentabilité et la visibilité des produits.  Sur GIT-ZONE.com, nous vous offrons une plateforme pour présenter votre/vos produit(s) et communiquer facilement et en toute sécurité avec les acheteurs. Consultez nos",
    },
    investment: {
      title: "Investissement",
      text:
        "GIT-ZONE.com est la première plateforme e-commerce au Moyen-Orient ; nous couvrons l'Afrique, l'Europe et pratiquement le monde entier. Si vous souhaitez donc investir dans une entreprise, quel que soit le pays, nous vous aiderons à faire correspondre votre idée avec le projet d'entreprise adéquat.",
    },
    tradeAssurance: {
      title: "Assurance commerciale",
      text:
        "Nous redonnons confiance aux milieux d'affaires. Que vous soyez acheteur ou vendeur, nous effectuerons le contrôle pour vous. Notre service Trade Assurance réduit la fraude dans les transactions commerciales pour toutes les parties concernées.",
    },
  },
  shipmentQuotation: {
    title: "Économisez et transportez avec GIT-ZONE.com",
    productName: "Nom du produit",
    categories: "Categories",
    quantity: "Quantité",
    toLocation: "Vers l'emplacement",
    fromLocation: "Depuis le lieu",
    productLink: "Product Url",
    requestShipment: "DEMANDE D'EXPÉDITION",
    hint:
      "Nous vous aiderons à transporter votre produit. Veuillez remplir le formulaire et nous vous enverrons le devis pour le transport. ",
    shippingMethods: {
      shippingMethods: "méthodes de livraison",
      landTransportation: "Transport terrestre",
      airTransportation: "Transport aérien",
      oceanTransportation: "Transport maritime",
    },
    submitButtonText: "Demande de devis",
  },
  newsLetter: {
    title: "Intéressé par des Offres Spéciales ? Inscrivez-vous !",
    form: { email: "Enter Your Email Address", submit: "Submit" },
    keepInTouch: "Restez informés !  ",
  },
  footer: {
    info:
      "est la première plateforme e-commerce au Moyen-Orient à combler le fossé entre acheteurs et fournisseurs sur les marchés locaux et internationaux.",
    company: {
      title: "About GIT-ZONE.com",
      siteMap: "Plan du Site",
      manual: "Manuel",
      tutorial: "Tutoriel",
      aboutUs: "À Propos ",
    },
    policyInfo: {
      title: "GIT-ZONE.com Policy",
      faq: "FAQ",
      privacy: "Politique GIT-ZONE.com",
      terms: "Terms & Conditions",
      plans: "Formules d'abonnement",
    },
  },
  formValidation: {
    productRequried: "Le nom du produit est obligatoire",
    categoryRequired: "Catégorie requise",
    quantityRequired: "Quantité Nécessaire",
    fromLocationRequired: "De l'emplacement requis",
    toLocationRequired: "Vers l'emplacement requis",
    shippingMothodRequired: "Méthode d'expédition requise",
    unitAmountRequired: "Montant d'unité requis",
    descriptionRequired: "Description requise",
    addressRequired: "Adresse requise",
    emailRequired: "Adresse mail requis",
    emailInvalid: "Courriel non valide",
    passwordRequired: "Mot de passe requis",
    paymentTermsRequired: "Paiement Requis",
    dateRequired: "Date requise",
    productUrlRequired: "URL du produit requise",
    urlNotValid: "URL non valide",
  },
  conntactus: {
    leaveMessage: "Avoir une question? Nous allons vous aider!",
    title: `Que vous ayez besoin de l'équipe d'assistance ou de notre équipe commerciale, nous nous occuperons de votre demande!`,
    fristName: " Nom complet",
    email: "Email",
    phoneNumber: "Numéro de téléphone",
    messageType: "Type de message",
    subject: "Matière",
    yourMessage: "Votre message",
    branch: "Branche",
    address: "Adresse",
    phone: "Téléphone",
    messageTypeOp: {
      sales: "Ventes",
      markting: "Commercialisation",
      customerService: "Service client",
      operations: "Operations",
    },
    validation: {
      emailRequired: "Email requis",
      fullNameRequire: "Nom complet requis",
      emailInvalid: "Courriel non valide",
      phoneNumber: "Téléphone requis",
      messageType: "Message Type Requried",
      subjectRequired: "Sujet obligatoire",
      messageRequired: "Message requis",
    },
    submitButton: "Envoyer le message",
    successMessage:
      "Votre message a été envoyé Nous vous contacterons sous peu",
  },
  path: {
    home: "Home",
    news: "Actualités",
    contactUs: "Contactez-nous",
    aboutUs: "À Propos ",
    privacyPolicy: "Politique GIT-ZONE.com",
    terms: "Termes et conditions	",
    services: "Prestations de service",
    checkout: "Checkout",
    search: "chercher",
    products: "Des produits",
    wishlist: "Liste de souhaits",
    cart: "Cart",
    faq: "FAQ'S",
    compare: "Comparer",
  },
  product: {
    addToCompare: "ajouter pour comparer",
    protectOrder: "Protect your orders from payment to delivery",
    tradeAssurance: "Assurance commerciale",
    startOrder: "COMMENCER LA COMMANDE",
    shipping: "livraison",
    showCase:
      "Pour les prix des produits, la personnalisation ou d'autres demandes",
    share: "Partager",
    shortDescription: "brève description",
    piecesMinOrder: "Pièces",
    pieceMinOrder: "Piece (Min. Order)",
    rfqExporter: "RFQ DE L'EXPORTATEUR",
    productDetails: "détails du produit",
    companyData: "Les données de la compagnie",
    companyProfile: "Company Profile",
    verifiedCompany: "Entreprise vérifiée",
    businessType: "Type d'entreprise",
    totalEmployees: "Total des employés",
    yearEstablished: "Année de création",
    productCertification: "Certification du produit",
    trademarks: "Marques",
    productDescription: "Description du produit",
    productionCapacity: "Capacité de production",
    qualityControl: "Contrôle de qualité",
    RDCapacity: "Capacité R&D",
    tradeCapacity: "Capacité commerciale",
    reviews: "avis",
    addReview: "ajouter un avis",
    reviewShowCase:
      "Votre avis sera publié publiquement sur le Web. En savoir plus",
    rating: "Rating",
    yourReview: "Votre avis",
    relatedProduct: "Produits associés",
    forOnePrice: "Par pièce",
    minPice: " Per Piece",
    stockInAndOut: "Stock In/Out",
    avilable: "Disponible",
    notAvilable: "non Disponible",
    quantity: "Quantité",
    piece: "Pièce",
    sample: "Sampels :",
    sampleShowCase: " Pièces",
    buySample: "Acheter un échantillon",
    companyOverview: "Présentation de l'entreprise",
    companyName: "Nom de la compagnie ",
    companyMail: "Courriel de l'entreprise",
    companyPhone: "Téléphone de l'entreprise",
    companyLocation: "Emplacement de la société",
    title: "Title",
    submitReview: "SOUMETTRE",
    notFound: "Introuvable",
    exporterName: "Nom de l'exportateur",
    customization: "Personnalisation: ",
    customizationLogo: "Logo ",
    free: "gratuit",
    customizationPackaging: "Emballage",
  },
  abuseReport: {
    title: "RAPPORT D'ABUS",
    fullname: "Nom complet",
    email: "Email",
    phone: "Numéro de téléphone",
    order: "N ° de commande	",
    abuseType: "Type d'abus",
    types: {
      importer: "importateur",
      exporter: "Exportateur",
      tradeAccurance: "Assurance commerciale",
      investment: "Investissement",
      shipment: "Expédition",
      others: "Autre",
    },
    evidence: "Evidence Url's",
    abuseDetails: "Détails de l'abus	",
    validation: {
      fullName: "Nom complet requis",
      orderRquired: "Commande requise",
      emailRequired: "E-mail requis	",
      emailNotValid: "Email non valide",
      evidenceRequired: "Preuve requise",
      abuseDetailsRequired: "Détails d'abus requis",
      phone: "Téléphone requis",
    },
    submitButton: "SUBMIT",
    successMessage:
      "Votre rapport a été soumis Nous examinerons le cas et vous répondrons sous peu",
  },
  popUps: {
    loginSuccess:
      "Vous vous êtes connecté avec succès Retour à l'accueil et soumettre",
    submitSuccess:
      "Votre demande à été envoyé Le devis vous sera envoyé sous peu",
    commingSoon: "BIENTÔT DISPONIBLE........",
  },
  checkout: {
    billing: "Facturation",
    shiping: "Expédition",
    order: "Commande",
    payment: "Paiement",
    billingDetails: "DÉTAILS DE FACTURATION",
    diffrentAddress: "EXPÉDIER À UNE ADRESSE DIFFRENT",
    next: "Suivant",
    fristName: "Prénom",
    lastName: "Nom de famille",
    companyName: "Nom de la compagnie",
    country: "Pays",
    streetAddress: "Adresse civique",
    apartment: "Appartement, Suite, Unité etc. (facultatif)	",
    city: "Ville",
    state: "Etat / Pays",
    zib: "ode postal / Zip",
    orderNote: "Order Note",
    validation: {
      fristName: "Premier nom requis",
      lastName: "Nom de famille requis",
      country: "Pays requis",
      street: "Adresse requis",
      city: "Ville requise",
    },
  },
  compare: {
    noProductAdded: "Comparer est vide",
    hideSameFeatures: "Masquer les mêmes fonctionnalités",
    fillterBy: "Filtrer par",
    hilightDeifference: "Mettre en évidence la différence",
    compareError:
      "vous ne pouvez comparer que des produits de la même catégorie",
    allFeatures: "TOUTES LES CARACTÉRISTIQUES",
    productFeature: "Caractéristiques du produit",
    exporterFeature: "Caractéristiques de l'exportateur",
    price: "Prix",
    rangePriceFrom: "Prix de gamme à partir de",
    rangePriceTo: "Prix de gamme à",
    samplePrice: "Exemple de prix",
    minOrderQuentity: "Quantité minimum de commande",
    fobPrice: "prix FOB",
    customeOption: "Option personnalisée",
    rate: "Taux",
    companyName: "Nom de l'entreprise",
    exporterTitle: "Titre d'exportateur",
    exporterName: "Nom de l'exportateur",
    companyPhone: "Téléphone de l'entreprise",
    companyLocation: "Emplacement de la société",
    companyMail: "Email d'entreprise		",
  },
  cart: {
    productDetails: "Détails du produit	",
    quantity: "Quantité",
    price: "prix",
    samplePrice: "prix de Exemple",
    total: "Total",
    romove: "SUPPRIMER	",
    checkout: "caisse",
    proceedWithExporter: "PROCEDER AVEC L'EXPORTATEUR",
    useCoupon: "Utiliser le coupon",
    tradeAccuranceOrder: "Ordonnance d'assurance commerciale",
    peice: "Pièce",
    rfqRequest: "RFQ Request",
    withoutShipment: "sans expédition",
  },
  wishlistP: {
    addToCompare: "Ajouter pour comparer",
    selectAll: "Sélectionner tout",
    contactExporter: "CONTACT EXPORTATEUR",
    defaultSorting: "TRI PAR DÉFAUT",
    sortByRate: "Par note",
  },
  faq: {
    frequentlyAskedQuestions: "Frequently Asked Questions",
    text:
      "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Odio\n    consequatur aliquam, amet natus dolores ad vel quis, dolorem eius\n    autem voluptatibus necessitatibus ipsa modi consectetur molestiae!\n    Sed nesciunt repellendus unde.",
    qaExporter: "FAQ-Exportateurs ",
    qaImporter: "FAQ-Importateurs",
    qaInvestor: "FAQ-Investissement",
  },
  countinuReading: "Continuer la lecture",
  or: "or",
  wishlistEmpty: "La liste de souhaits est vide",
  cartEmpty: "Le panier est vide",
  ok: "Ok",
  seeMore: "Voir plus ...",
  seeLess: "Voir moins",
  loginFirst: "Vous devez d'abord vous connecter",
  addToCart: "AJOUTER AU PANIER",
  learnmore: "En savoir plus ",
  signupNow: "Inscrivez-vous Maintenant !",
  addedToCart: "AJOUT AU PANIER",
  searchP: {
    filterBy: "Filtrer par",
    stockStatus: "ÉTAT DES STOCKS",
    searchShowCase: "Résultat de la recherche",
    for: "for",
    companys: "Entreprises",
    in: "dans",
    inStock: "En Stock",
    outStock: "Rupture de stock",
    rangePrice: "GAMME DE PRIX",
    quantity: "QUANTITE MINIMUM",
    location: "LIEU EXPORTATEUR",
    readyToShip: "PRÊT À EXPÉDIER",
    ready: "Prêt",
    notReady: "Pas prêt",
    less: "moins",
    rate: "Taux",
    above: "ci-dessus",
    showing: "AFFICHER",
    priceMinOrder: "Pièce (Commande Min.)	",
    of: "Ode",
    sorting: {
      default: "TRI PAR DEFAUT",
      byName: "DE NOM",
      byPrice: "PAR PRIX",
      bypopularity: "PAR POPULARITÉ",
      newest: "le plus récent",
      bestReview: "MEILLEUR AVIS",
      priceHighToLow: "PRIX HAUT À BAS",
      priceLowToHigh: "PRIX BAS À ÉLEVÉ",
      byRate: "PAR TAUX",
    },
    products: "PRODUITS",
    productEmpaty: "Aucun produit trouvé",
    countrys: {
      egypt: "Egypt",
      china: "China",
      russia: "Russia",
      germany: "Germany",
      other: "autre",
    },
  },
  rfqModal: {
    amount: "Montant",
    productDescription: "Détails du produit",
    payment: "Nombre de paiements",
    productPercent: "Pourcentage de produit",
    mony: "Argent",
    country: "pays",
    city: "ville",
    address: "adresse",
    zib: "code postal",
    phone: "Téléphone",
    shippingInfo: "Infos de livraison",
    from: "de",
    submitRfq: "SOUMETTRE RFQ",
    to: "à",
    note: "Notes sur l'expédition",
    description: "La description",
    typeOfTransportation: "Type de transport",
    paymentType: "Type de paiement",
    specialSpecifications: "pécifications spéciales",
    validation: {
      amountMustMoreOne: "Le montant doit être supérieur à 1",
      amount: "Montant requis",
      productDetails: "Détails du produit requis",
      payment: "Paiement requis",
      productPercent: "Pourcentage de produit requis",
      mony: "Argent requis",
      address: "Adresse requise",
      city: "Ville requise",
      country: "Pays requis",
      phone: "Téléphone requis",
      shippingFromRequired: "Envoi depuis Obligatoire",
      shippingToRequired: "Envoi requis",
      typeOfTransportationRequired: "Type de transport requis",
      paymentType: "Type de paiement requis",
    },
  },
  permistionMessages: {
    mustImporter: "Vous devez d'abord vous connecter en tant qu'importateur",
  },
  plans: {
    plans: "Formules",
    subscription: "Formules d'Abonnement",
    choosePlan: "S'abonner",
  },
  tradeAssuranceReq: {
    name: "Name",
    email: "Email",
    request: "Request",
    url: "Url",
    phone: "Phone Number",
    joinUs: "Join Us",
    requestQuotation: "Demande de cotation",
    reqTradeAssurance: "Demander une assurance commerciale",
    validation: {
      phoneRequired: "Phone Required",
      nameRequired: "Name Required",
      emailRequired: "Email Required ",
      emailNotValid: "Email Not Valid",
      request: "Request Required",
      urlNotValid: "Url Not Valid",
    },
  },
  //utils: { thanks: "Merci" },
  utils: {
    thanks:
      "Mesajınız gönderilmiştir. Size en kısa süre içerisinde geri dönüş yapacağız.",
  },
  services: {
    importing: "Importation ",
    exporting: "Exportation",
    investment: "Investissement",
    tradeAssurance: "Trade Assurance",
    showCase: `est un site qui vous aidera à faire franchir un palier à votre entreprise. Nous vous proposons, dans cette section, de découvrir les services proposés par notre plateforme pour promouvoir un commerce équitable.																									
    `,
  },
  aboutUs: {
    welcome: "Bienvenue sur GIT-ZONE.com,",
    showCase: `La première plateforme e-commerce au Moyen-Orient à combler le fossé entre acheteurs et fournisseurs, sur les marchés locaux, mais à l'internationale. La plateforme GIT-ZONE.com est conçue pour promouvoir un commerce équitable pour toutes les parties concernées, en créant un environnement commercial sûr, axé sur la technologie et durable pour les acheteurs, les fournisseurs et les investisseurs. `,
    letter: ` La proposition de valeur de notre plateforme réside dans le choix de catégories offertes à des tarifs compétitifs, la communication facilitée entre les parties, une politique de commande indépendante de la taille pour les acheteurs et une garantie de qualité du produit et des services, même après la vente. `,
    showCae2: ` Notre plateforme n'est pas limitée à certaines catégories ou certains pays, pour que nos acheteurs et fournisseurs puissent bénéficier d'une formation internationale. Nos catégories vont de l'électronique à l'habillement, en passant par les accessoires, l'agriculture et bien d'autres encore, visibles `,
    here: "ICI",
    showcae3:
      "Si vous êtes un acheteur, un fournisseur ou un investisseur, nous pouvons très certainement faire passer un cap à votre entreprise.",
    contactUsNow: "CONTACTEZ-NOUS MAINTENANT ! ",
    vision: "VISION",
    visionDetails: `En comblant le gouffre entre acheteurs et vendeurs, nous proposons un Commerce International au Moyen-Orient, mais également dans le reste de l'Afrique et ailleurs. Tout cela peut être accompli en facilitant des échanges commerciaux sains grâce à notre plateforme conviviale et technologique. `,
    mission: "MISSION",
    missionDetails: `Sur GIT-ZONE.com, nous prenons soin de vos transactions dès votre inscription sur notre plateforme. Que vous soyez fournisseur, acheteur ou investisseur, notre mission est de vous fournir les outils qui faciliteront et rendront plus efficace et plus rapide les échanges commerciaux. `,
    values: "VALEURS",
    valuesDetails: `Nous voulons partager officiellement l'ensemble de nos valeurs, afin de mieux nous faire connaître et de donner un aperçu de notre mode de fonctionnement. `,
    quality: "Qualité ",
    qualityD: `Avec notre service Trade Assurance, nous vous garantissons une transaction commerciale avec prévention de fraudes et réduction des risques. `,
    diversity: "Diversité",
    diversityD: `Nous proposons un large choix de catégories pour commercer. Toutes les catégories sont proposées dans le cadre d'offres et de tarifs compétitifs au profit de nos utilisateurs. `,
    passion: "Passion",
    passionD: `Nous rêvons grand et mettons votre affaire en valeur !`,
    importing: "Importation",
    importingD: `Gagnez du temps, évitez de voyager dans plusieurs pays pour votre approvisionnement et commencez à vous procurer des produits sur le marché de gros en ligne. GIT-ZONE.com vous donne accès à des milliers de fournisseurs, de différents endroits et à un large éventail de catégories pour des importations. `,
    learnMore: " En savoir plus ",
    tradeAssurance: "Trade Assurance ",
    tradeAssuranceD: ` Nous redonnons confiance aux milieux d'affaires. Que vous soyez acheteur ou vendeur, nous effectuerons le contrôle pour vous. Notre service Trade Assurance réduit la fraude dans les transactions commerciales pour toutes les parties concernées.`,
    signUpNow: ` Inscrivez-vous Maintenant !`,
    exporter: "Exportation ",
    exporterD: `Donnez à votre produit la visibilité qu'il mérite et présentez-le en ligne. Les marchés de gros en ligne ont prouvé leur capacité à accroître la rentabilité et la visibilité des produits.  Sur GIT-ZONE.com, nous vous offrons une plateforme pour présenter votre/vos produit(s) et communiquer facilement et en toute sécurité avec les acheteurs. Consultez nos`,
    investment: "Investissement ",
    investmentD: `GIT-ZONE.com est la première plateforme e-commerce au Moyen-Orient ; nous couvrons l'Afrique, l'Europe et pratiquement le monde entier. Si vous souhaitez donc investir dans une entreprise, quel que soit le pays, nous vous aiderons à faire correspondre votre idée avec le projet d'entreprise adéquat.`,
  },
  map: {
    capital: "Capital",
    language: "Langue",
    area: "Zone",
    growthRate: "Taux de croissance",
    moreInfo: "Plus d'infos",
    askforConsultation: "Demandez une consultation",
    downloadAsPDF: "Télécharger en PDF",
    submit: "Soumettre",
    phone: "Téléphone",
    note: "Remarque",
    population: "Population",
    currency: "Devise ",
  },
  privacyPolicy: {
    _1: {
      title: "GIT-ZONE.com s'en soucie !",
      body: `L'une des principales missions de GIT-ZONE.com étant de créer une plateforme e-commerce sûre et technologique pour tous les commerçants, nous tenons à protéger vos informations personnelles et à sécuriser les étapes de chaque transaction commerciale effectuée sur notre plateforme. Cette section de la plateforme présente notre politique de confidentialité et explique en détail notre collecte de vos informations personnelles, ainsi que la manière et les endroits où elles peuvent être utilisées.  `,
    },
    _2: {
      title:
        "Quelles sont les informations que nous recueillons et comment les protégeons-nous ?",
      body_1: `Durant votre inscription sur GIT-ZONE.com, il vous sera demandé de fournir certaines informations personnelles, telles que votre nom, votre adresse de livraison, votre adresse électronique et/ou votre numéro de téléphone, ainsi que d'autres informations similaires. De plus, afin de vérifier votre identité, nous pourrions avoir besoin de preuve d'identité valide, ainsi que de certaines informations financières comme votre carte de crédit et/ou vos coordonnées bancaires. Ces informations servent à la facturation. Une fois inscrit sur le site, vous ne devez publier aucune information personnelle (y compris des informations financières) sur le site.`,
      body_2: `Vous êtes responsable de la sécurité de vos nom d'utilisateur et mot de passe sur GIT-ZONE.com. Nous vous recommandons de choisir un mot de passe fort et le changer fréquemment. Veuillez ne pas utiliser les mêmes informations de connexion (e-mail et mot de passe) sur plusieurs sites Web.`,
      body_3: `Nous mettons toutefois en œuvre diverses mesures de sécurité, dont l'utilisation d'un serveur sécurisé. Après toute transaction, vos informations privées (cartes de crédit, données financières, etc.) ne seront pas stockées sur nos serveurs. Nos serveurs et notre site Web font l'objet de contrôles de sécurité réguliers afin de vous protéger en ligne.`,
    },
    _3: {
      title: "Droit d'auteur",
      body:
        "Tout le contenu inclus sur ce site ou rendu disponible par le portail GIT-ZONE.com, comme les textes, graphiques, logos, icônes de boutons, images, clips audio, clips vidéo, téléchargements numériques, compilations de données et logiciels, est la propriété de GIT-ZONE.com ou de ses fournisseurs de contenu et est protégé par les lois internationales sur le droit d'auteur. La compilation de tout le contenu incluse dans ou rendue disponible par le site GIT-ZONE.com est la propriété exclusive de GIT-ZONE.com et est protégée par les lois internationales sur le droit d'auteur. Tous les logiciels utilisés dans les services de GIT-ZONE.com sont la propriété de GIT-ZONE.com ou de ses fournisseurs de logiciels et sont protégés par les lois internationales sur le droit d'auteur",
    },
    _4: {
      title: "Liens vers des tiers",
      body: `Nous pouvons inclure ou proposer des produits ou services de tiers sur notre site. Ces produits/services tiers peuvent être utilisés pour le transport du/des produit(s), le prélèvement de paiements, la protection contre la fraude et/ou la réduction de risques de crédit. Ces sites tiers ont des politiques de confidentialité distinctes et indépendantes. Nous n'avons donc aucune responsabilité quant au contenu et aux activités desdits sites. Nous nous efforçons néanmoins de protéger l'intégrité de notre site et accueillons volontiers tout commentaire sur ces sites.`,
    },
    _5: {
      title: "Utilisons-nous des cookies ?",
      body_1: `Oui. Les cookies sont de petits fichiers qu'un site ou son fournisseur de services installe sur le disque dur de votre ordinateur via votre navigateur Web (si vous l'avez autorisé via vos paramètres). Cela permet aux sites ou aux fournisseurs de services de reconnaître votre navigateur, de recueillir et enregistrer certaines informations.`,
      body_2: `Nous utilisons des cookies pour nous aider à enregistrer et traiter les articles de votre panier, à identifier et enregistrer vos préférences pour vos futures visites et à compiler des données globales sur le trafic et l'interaction sur le site afin de vous offrir une meilleure expérience et de meilleurs outils à l'avenir. Nous vous assurons que notre utilisation des cookies ne contient aucune information personnelle ou privée et est exempte de virus. Si vous voulez en savoir plus sur les cookies, allez sur `,
      body_3: ` ou pour savoir comment les supprimer de votre navigateur, allez sur`,
      body_4: `Nous utilisons Google Analytics, un service de statistiques Web fourni par Google, Inc. ("Google") pour nous aider à mieux comprendre comment les utilisateurs interagissent avec le portail GIT-ZONE.com. Google Analytics utilise des cookies pour collecter des informations sur l'utilisation de notre site Web. Ces informations sont utilisées pour produire des rapports et créer des services qui nous aident à améliorer notre site Web et les services associés. Les rapports révèlent les tendances du site Web, sans identifier individuellement les visiteurs. Les informations générées par le cookie de Google, sur votre utilisation de notre site Web (dont votre adresse IP), peuvent être transmises et stockées par Google sur des serveurs aux États-Unis. Google peut également transmettre ces informations à de tierces parties si la loi l'exige ou lorsqu'elles traitent ces informations pour le compte de Google. Google ne liera pas votre adresse IP à toute autre donnée détenue par Google.`,
    },
    _6: {
      title: "Signalements d'abus sur GIT-ZONE.com",
      body: `Veuillez signaler tout problème, de quelque nature que ce soit, ou toute violation de ces Conditions Générales à GIT-ZONE.com. Si vous pensez que vos droits de propriété intellectuelle ont été violés, veuillez en informer GIT-ZONE.com. Sans préjudice de tout autre droit ou recours par GIT-ZONE.com en vertu de ces Conditions Générales ou de la loi, GIT-ZONE.com peut limiter, suspendre ou retirer l'accès d'un utilisateur au site et/ou son abonnement au site ou retirer le contenu hébergé. GIT-ZONE.com peut aussi choisir de prendre d'autres mesures techniques et/ou légales contre les utilisateurs responsables de problèmes ou d'éventuelles responsabilités légales de toute sorte, qui violent les droits de propriété intellectuelle de tiers ou qui enfreignent ces Conditions Générales ou nos politiques.`,
    },
  },
};

export default fr;

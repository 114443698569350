import React, { useState } from "react";
import Rater from "react-rater";
import Styled from "styled-components";
import { Formik } from "formik";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { openModal } from "../../store/actions/UiAction";
import axios from "../../services/axios";
import strings from "../../Localization/LNG";

function AddReview({ slug }) {
  const user = useSelector(state => state.user);
  const [ratingValue, setRatingValue] = useState(null);
  const [isRatingError, setIsRatingError] = useState(false);
  const lng = useSelector(state => state.lng);
  const dispatch = useDispatch();
  const userRoules = useSelector(state => state.user.user);
  const isImporter = _.some(userRoules.roles, r => r.name === "importer");
  if (userRoules && isImporter) {
    return (
      <div className="add_review ">
        <h5
          className={`${lng === "arabic" ? "text-right" : ""}`}
          style={{ color: "#bd1723" }}
        >
          {strings.product.addReview}
        </h5>
        <div className={`row mt-4 ${lng === "arabic" ? "text-right" : ""}`}>
          <div className="col-md-2">
            <img
              src={user.user && user.user.picture_thumb}
              width="60"
              height="60"
              alt="avatar"
            />
          </div>
          <div className="col-md-9">
            <h6 style={{ color: "#bd1723" }}>{user.user.name} </h6>
            <p className="text-muted mr-2">{strings.product.reviewShowCase}</p>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-3"> {strings.product.rating} </div>
          <div className="col-md-9">
            <Rater
              onRate={e => setRatingValue(e.rating)}
              interactive={true}
              total={5}
            />
            {isRatingError && (
              <span className="text-danger"> * Enter Rating </span>
            )}
          </div>
        </div>
        <Formik
          initialValues={{ review: "" }}
          onSubmit={async (values, { setSubmitting, resetForm, setErrors }) => {
            if (!ratingValue) {
              setIsRatingError(true);
              setSubmitting(false);
              setErrors("rating");
              return;
            }
            setIsRatingError(false);
            try {
              const res = await axios.post(`/api/product/${slug}/review`, {
                title: "Hello World",
                body: values.review,
                rating: ratingValue
              });
              setSubmitting(false);
              resetForm();
              dispatch(openModal("your Review has been submited"));
            } catch (error) {
              dispatch(openModal("Error ocuured"));
              setSubmitting(false);
            }
          }}
          validate={values => {
            const errors = {};
            if (!values.review) {
              errors.review = "review required";
            }
            return errors;
          }}
        >
          {({
            values,
            handleChange,
            handleBlur,
            handleSubmit,
            touched,
            isSubmitting,
            errors
          }) => (
            <form autoComplete="off" onSubmit={handleSubmit}>
              <div className="row mt-3">
                <div className="col-md-3">
                  {strings.product.yourReview}{" "}
                  <span style={{ color: "red" }}>*</span>
                </div>
                <div className="col-md-9">
                  <div className="float-right text-danger">
                    {errors.review && touched.review && errors.review}
                  </div>
                  <SubmitReview
                    name="review"
                    onBlur={handleBlur}
                    value={values.review}
                    onChange={handleChange}
                    className="form-control"
                    cols="58"
                    rows="3"
                  />
                  <SubmitButton
                    className={`${
                      lng === "arabic" ? "text-left" : "float-right"
                    }`}
                  >
                    {isSubmitting ? (
                      <div
                        className="spinner-border spinner-border-sm"
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : (
                      strings.product.submitReview
                    )}
                  </SubmitButton>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    );
  } else return null;
}
const SubmitReview = Styled.textarea`
  border: 1px solid #E8E8E8;
`;

const SubmitButton = Styled.button`
    background: #bd1723;
    border: none ;
    color: #fff;
    margin-top: 1rem;
    padding: .4rem;
`;
export default AddReview;

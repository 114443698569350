import React from "react";
import ReactDOM from "react-dom";
import App from "./components/App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import { store, persistor } from "./store/store";
import Strings from "./Localization/LNG";
import { PersistGate } from "redux-persist/integration/react";
import _ from "lodash";
// import { render } from "react-snapshot";

// import { hydrate, render } from "react-dom";
Strings.setLanguage(localStorage.getItem("lng") || "en");
// window._ = _;

// const AC = (
//   <Provider store={store}>
//     <PersistGate persistor={persistor}>
//       <App />
//     </PersistGate>
//   </Provider>
// );

// const rootElement = document.getElementById("root");
// if (rootElement.hasChildNodes()) {
//   hydrate(AC, rootElement);
// } else {
//   render(AC, rootElement);
// }

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();

import {
  ADD_TO_COMPARE,
  REMOVE_FROM_COMPARE,
  SELECT_ALL_TO_COMPARE,
  ADD_MULTIPLE_TO_COMPARE,
  HIDE_SAME_FEATURE,
  HILIGHT_DIFFERENCE,
  DELETE_FROM_COMPARE
} from "../actions/types";
import _ from "lodash";

const INITIAL_STATE = {
  items: {},
  selectedProduct: {},
  selectAll: true,
  hideSameFeature: false,
  highliteDifference: false
};

const compareReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_TO_COMPARE:
      if (
        state.selectedProduct[action.payload.id] &&
        state.items[action.payload.id]
      ) {
        return state;
      }
      return {
        ...state,
        items: { ...state.items, [action.payload.id]: action.payload },
        selectedProduct: {
          ...state.selectedProduct,
          [action.payload.id]: action.payload
        }
      };
    case REMOVE_FROM_COMPARE:
      const newSelectedItems = _.omit(state.selectedProduct, action.payload.id);
      return {
        ...state,
        items: state.items,
        selectedProduct: newSelectedItems,
        selectAll: false
      };
    case SELECT_ALL_TO_COMPARE:
      if (state.selectAll) {
        return {
          ...state,
          items: { ...state.items },
          selectAll: false,
          selectedProduct: {}
        };
      } else {
        return {
          ...state,
          items: { ...state.items },
          selectAll: true,
          selectedProduct: { ...state.items }
        };
      }
    case DELETE_FROM_COMPARE:
      const newSelectedItem = _.omit(state.selectedProduct, action.payload);
      const newItems = _.omit(state.items, action.payload);
      return {
        ...state,
        items: newItems,
        selectedProduct: newSelectedItem
      };
    case ADD_MULTIPLE_TO_COMPARE:
      const newState = {
        ...state,
        items: { ...state.items, ...action.payload },
        selectedProduct: { ...state.selectedProduct, ...action.payload }
      };
      return newState;
    case HIDE_SAME_FEATURE:
      return {
        ...state,
        hideSameFeature: !state.hideSameFeature
      };
    case HILIGHT_DIFFERENCE:
      return {
        ...state,
        highliteDifference: !state.highliteDifference
      };
    default:
      return state;
  }
};

export default compareReducer;

import { GET_BRANDS_AND_BANNERS } from '../actions/types'

const brands = (state = [], action) => {
    switch (action.type) {
        case GET_BRANDS_AND_BANNERS:
            return action.payload
        default:
            return state
    }
}


export default brands
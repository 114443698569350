import { createStore, applyMiddleware, compose } from "redux";
import reduxThunk from "redux-thunk";
import reducer from "./reducers";
import logger from "redux-logger";
import { persistStore } from "redux-persist";
import queryString from "query-string";
// import { createStateSyncMiddleware } from "redux-state-sync";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const isAuth = localStorage.getItem("token") ? true : false;
const user = localStorage.getItem("user");
const middleware = [reduxThunk];
const searchParams = window.location.href.split("?")[1];
const trim = queryString.parse(searchParams).s;

export const store = createStore(
  reducer,
  {
    lng: localStorage.getItem("lng") || "en",
    search: { term: trim ? trim : null, imgUrl: null, img: null }
  },
  composeEnhancers(applyMiddleware(...middleware))
);

export const persistor = persistStore(store);

export default { store, persistor };

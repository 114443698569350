import React from "react";

const ServerError = props => {
  return (
    <div className="text-center p-5">
      <img
        src={require("../../assets/svg/server_down_s4lk.svg")}
        style={{ height: "40vh", maxWidth: "100%" }}
      />
      <h4 className="mt-4">
        Sorry , Unable to Fetch This Product Right Now ,mey be you have a
        problem with you your Internet Connection
        <button
          className="btn btn-link"
          onClick={() => {
            window.location.reload();
          }}
        >
          try again{" "}
        </button>
      </h4>
    </div>
  );
};

export default ServerError;

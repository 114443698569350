import {
  ADD_TO_WISH_LIST,
  ADD_TO_SELECTED_WISH_LIST,
  SELECT_ALL_WISH_LIST,
  REMOVE_SELECTED_WISHLIST,
  SORTING_BY_RATING,
  REMOVE_WISHLIST,
  CLEAR_SELECTED_WISHLIST
} from "./types";

export const addToWishList = product => {
  console.log(product);
  return { type: ADD_TO_WISH_LIST, payload: product };
};

export const addToSelectWishList = product => {
  return { type: ADD_TO_SELECTED_WISH_LIST, payload: product };
};

export const selectAll = () => {
  return { type: SELECT_ALL_WISH_LIST };
};

export const clearSelect = () => {
  return { type: CLEAR_SELECTED_WISHLIST };
};

export const removerSelectedWishList = () => {
  return { type: REMOVE_SELECTED_WISHLIST };
};

export const sortingByRating = () => {
  return { type: SORTING_BY_RATING };
};
export const removeFromWishlist = productId => dispatch => {
  dispatch({ type: REMOVE_WISHLIST, payload: productId });
};

import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import LoginForm from "../auth/Login/LoginForm";
import { useSelector, useDispatch } from "react-redux";

import { closeLoginMdal } from "../../store/actions/UiAction";
import strings from "../../Localization/LNG";
const LoginModal = props => {
  const isLogedIn = useSelector(state => state.user.isAuth);
  const isLoginModalOpen = useSelector(state => state.ui.loginModal);
  const message = useSelector(state => state.ui.loginMessage);
  const lng = useSelector(state => state.lng);
  const dispatch = useDispatch();
  if (isLogedIn) {
    if (props.handleClick) {
      props.handleClick();
    }
    dispatch(closeLoginMdal());
  }
  return (
    <div className="text-center">
      <Modal
        show={isLoginModalOpen}
        keyboard={true}
        onEscapeKeyDown={() => {
          if (props.handleClick) {
            props.handleClick();
          }
          dispatch(closeLoginMdal());
        }}
        onHide={() => {
          if (props.handleClick) {
            props.handleClick();
          }
          dispatch(closeLoginMdal());
        }}
      >
        <button
          onClick={() => {
            if (props.handleClick) {
              props.handleClick();
            }
            dispatch(closeLoginMdal());
          }}
          style={{ fontSize: "2rem" }}
          type="button"
          className="close"
          aria-label="Close"
        >
          <span aria-hidden="true" className="float-right mr-3">
            &times;
          </span>
        </button>
        <div className="p-3">
          <h5 className={`${lng === "arabic" ? "text-right" : ""}`}>
            {/* {action === "drower" ? "login" : strings.loginFirst} */}
            {message}
          </h5>
          <LoginForm />
        </div>
        {/* )} */}
      </Modal>
    </div>
  );
};

// const backButton = {
//   cursor: "pointer",
//   border: "none",
//   padding: "8px",
//   borderRadius: "6px",
//   width: "11rem",
//   color: "white",
//   background: "#5B5756"
// };

export default LoginModal;

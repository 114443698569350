import React, { useState, useEffect } from "react";
import ShowCase from "./ShowCase/ShowCase";
import _ from "lodash";
import CardBox from "./ShowCase/CardBox";
import { Zoom } from "react-reveal";
import Rater from "react-rater";
import Footer from "../Footer/Footer";
import CopyRight from "../copyRight/copyRight";
import NewsLetter from "../Home/newsLetter/newsLetter";
import { useDispatch, useSelector } from "react-redux";

import renderHTML from "react-render-html";
import Sidebar from "./SideBar";
import AddReview from "./addReview";
import DetailsPath from "./DetailsPath";
import LogoSpinner from "../utils/LogoSpinner";
import FARQModal from "../utils/FARQModal";
import axios from "../../services/axios";
import SupplierModal from "./supplierModal";
import ShareModal from "../utils/ShareModal";
import ShareButtons from "../utils/ShareButtons";
import strings from "../../Localization/LNG";
import ServerError from "../utils/ServerError";
import { openModal, openLoginModal } from "../../store/actions/UiAction";
import CompanyProfile from "./CompantProfile";
import MetaTags from 'react-meta-tags';
function ProductDetails(props) {
  const [showDetails, setShowDetails] = useState(true);
  const [showPolicy, setShowPolicy] = useState(false);
  const [type, setType] = useState(null);
  const [product, setProduct] = useState(null);
  const dispatch = useDispatch();
  const [quentity, setQuentity] = useState(
    product ? product.minimum_quantity : null
  );
  const [showModal, setShowModal] = useState(false);
  const [tradeAssurance, setTradeAssurance] = useState(false);
  const [ifFob, setIfFob] = useState(false);
  const [selectedPrice, setSelectedPrice] = useState(null);
  const [shareModal, setShareModal] = useState(false);
  const isAuth = useSelector((state) => state.user.isAuth);
  const hasError = useSelector((state) => state.products.error);
  const lng = useSelector((state) => state.lng);
  const isAppLoading = useSelector((state) => state.ui.loadingApp);
  const [supplierModal, setSupplierModal] = useState(false);
  const userRoules = useSelector((state) => state.user.user);
  const { currency } = useSelector((state) => state.currency);
  useEffect(() => {
    async function doWork() {
      const res = await axios.get(`/api/product/${props.match.params.slug}`);
      setProduct(res.data);
      setQuentity(res.data.data.minimum_quantity);
    }
    doWork();
  }, [props.match.params.slug]);
  const renderViews = (reviews) => {
    /*document.title = product
      ? `GIT-ZONE.com | ${product.data.name}`
      : "Loading ....";*/
    if (product.data.reviews.length > 0) {
      return (
        <ul className="list-unstyled mt-4">
          <h5
            className={`${lng === "arabic" ? "text-right" : ""}`}
            style={{ color: "#bd1723" }}
          >
            {strings.product.reviews}{" "}
          </h5>
          {_.map(product.data.reviews, (review) => {
            return (
              <li className="media mt-3 border-bottom review_card">
                <img
                  src={
                    review.author_photo
                      ? review.author_photo
                      : "https://git-zone.appssquare.com/assets/corals/images/avatars/avatar_0.png"
                  }
                  className="mr-3"
                  height="60"
                  alt="avatar"
                />
                <div className="media-body p-3">
                  <h6 className="mt-0 mb-1" style={{ color: "#bd1723" }}>
                    {review.author_name} -
                    <span className="review-date mr-1 text-muted">
                      {review.created_at}
                    </span>
                    <span className="float-right">
                      <Rater
                        interactive={false}
                        total={5}
                        rating={review.rating}
                      />
                    </span>
                  </h6>
                  <p className="text-muted mt-2">{review.body}</p>
                </div>
              </li>
            );
          })}
        </ul>
      );
    } else {
      
    return (
      <div className="wrapper">
        <MetaTags>
          <title>GIT-ZONE.com | {product.data.name}</title>
          <meta name="description" content="{product.data.name}" />
          <meta property="og:title" content="{product.data.name}" />
          <meta property="og:image" content="{product.data.images['0'].url}" />
        </MetaTags>
        <div className="content">{product.data.name}</div>
      </div>
    );
    }
  };
  const renderShareButtons = () => (
    <div className={`pt-3 ${lng === "arabic" ? "text-right" : ""} `}>
      <span
        className={`font-weight-bold ${lng === "arabic" ? "ml-2" : "mr-2"}`}
      >
        {strings.product.share}
      </span>
      <ShareButtons product={product.data} />
    </div>
  );
  const renderDetails = () => {
    if (showDetails) {
      return (
        <div className="p-4">
          {product.data.description ? (
            <>
              <h5
                className={`${lng === "arabic" ? "text-right" : ""}`}
                style={{ color: "#bd1723" }}
              >
                {strings.product.productDescription}
              </h5>
              <p
                className={`${
                  lng === "arabic"
                    ? "text-right overflow-hidden"
                    : "overflow-hidden"
                }`}
              >
                {renderHTML(product.data.description)}
              </p>
            </>
          ) : null}
          {renderViews()}
          {isAuth && <AddReview slug={product.data.slug} />}
        </div>
      );
    } else {
      return <CompanyProfile product={product} />;
      // return null;
    }
  };
  if (!isAppLoading && product) {
    return (
      <div dir={`${lng === "arabic" ? "rtl" : "ltr"}`}>
        <FARQModal
          type={type}
          onClose={() => setShowModal(false)}
          isOpen={showModal}
          product={product.data}
        />
        <SupplierModal
          isOpen={supplierModal}
          onClose={() => setSupplierModal(false)}
          product={product.data}
        />
        <ShareModal
          product={product.data}
          isOpen={shareModal}
          onClose={() => setShareModal(false)}
        />
        <DetailsPath productName={product.data.name} />
        <div className="container mt-4">
          <div className="row">
            <div className="col-lg-8 col-md-12">
              <Zoom left>
                <ShowCase
                  ifFob={ifFob}
                  onChangeFob={() => setIfFob(!ifFob)}
                  urls={props.urls}
                  onDecreesQuantity={() => {
                    const { minimum_quantity } = product.data;
                    if (quentity <= minimum_quantity) {
                      return;
                    }
                    if (quentity < 2) {
                      return;
                    }
                    setQuentity(quentity - 1);
                  }}
                  product={product.data}
                  quentity={quentity}
                  onChangeQuantity={(e) => {
                    if (+e > product.data.stock) {
                      return;
                    }
                    setQuentity(+e);
                  }}
                  onIncressQuantity={() => {
                    if (quentity > +product.data.stock - 1) {
                      return;
                    }
                    setQuentity(quentity + 1);
                  }}
                  tradeAssurance={tradeAssurance}
                />
              </Zoom>
              {product.data.caption && (
                <div
                  className={`short_description ${
                    lng === "arabic" ? "text-right" : ""
                  } `}
                >
                  <h5 className="main-color">
                    {strings.product.shortDescription}
                  </h5>
                  <p className="mt-2">{product.data.caption}</p>
                </div>
              )}
              <div
                className={`short_description ${
                  lng === "arabic" ? "text-right" : ""
                } `}
              >
                <h5 className="main-color"> {strings.product.companyName} </h5>
                <div
                  className="mt-2"
                  dangerouslySetInnerHTML={{
                    __html: product.data.company_name,
                  }}
                />

                {/* <p className="mt-2">{product.data.company_name}</p> */}
              </div>
              <div className="share">{renderShareButtons()}</div>
              <div className="description  mt-5">
                <ul className="nav nav-tabs">
                  <li className="nav-item">
                    <button
                      className={`nav-link tap ${showDetails ? "active" : ""} `}
                      onClick={() => {
                        setShowDetails(true);
                        setShowPolicy(false);
                      }}
                    >
                      {strings.product.productDetails}
                    </button>
                  </li>
                  <li className="nav-item">
                    <button
                      className={`nav-link tap ${showPolicy ? "active" : ""} `}
                      onClick={() => {
                        setShowDetails(false);
                        setShowPolicy(true);
                      }}
                    >
                      {strings.product.companyProfile}
                    </button>
                  </li>
                </ul>
                <div>{renderDetails()}</div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 ">
              <Zoom right>
                <CardBox
                  tradeAssurance={tradeAssurance}
                  ifFob={ifFob}
                  urls={props.urls}
                  quentity={quentity}
                  onChangeTrade={() => {
                    if (!isAuth) {
                      dispatch(openLoginModal(strings.loginFirst));
                      return;
                    }
                    if (isAuth) {
                      const isImporter = _.some(
                        userRoules.roles,
                        (r) => r.name === "importer"
                      );
                      if (!isImporter) {
                        dispatch(
                          openModal(strings.permistionMessages.mustImporter)
                        );
                        return;
                      }
                    }
                    setTradeAssurance(!tradeAssurance);
                  }}
                  onShare={() => setShareModal(true)}
                  openFarqModal={(selectedType) => {
                    if (selectedType === "supplier") {
                      setSupplierModal(true);
                      axios.post(`/api/contact/${product.data.slug}`);
                      return;
                    }
                    setShowModal(true);
                    setType(selectedType);
                  }}
                  product={product.data}
                />
              </Zoom>
              <Sidebar
                banner={product.banner}
                bannerHref={product.banner_href}
                relatedProducts={product.related_products}
              />
            </div>
          </div>
        </div>
        <div className="mt-4">
          <NewsLetter />
          <Footer />
          <CopyRight />
        </div>
      </div>
    );
  } else if (hasError) {
    return <ServerError />;
  } else {
    return <LogoSpinner />;
  }
}

export default ProductDetails;

import React, { useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { closeTutorialModal } from "../../store/actions/UiAction";
import "./style.scss";
import Slider from "react-slick";

import _ from "lodash";
const settings = {
  dots: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  initialSlide: 0
};
export default function TutorialModel() {
  const isOpen = useSelector(state => state.ui.openTutorialModal);
  const dispatch = useDispatch();
  const lng = useSelector(state => state.lng);
  const { urls } = useSelector(state => state.urls);
  const [isEnd, setIsEnd] = useState(false);
  const sliderRef = useRef();
  const is_app_opend = localStorage.getItem("is_app_opens");
  if (!is_app_opend) {
    return (
      <Modal
        show={isOpen}
        size="lg"
        dir={`${lng === "arabic" ? "rtl" : "ltr"}`}
        keyboard={true}
      >
        <div className="skip_button">
          <button
            className="tutorial_skip_button float-right"
            onClick={() => {
              dispatch(closeTutorialModal());
            }}
          >
            Skip
          </button>
        </div>
        <Slider
          beforeChange={e => {
            if (e === urls.guied_wizard.length - 1) {
              setIsEnd(true);
            }
          }}
          ref={sliderRef}
          {...settings}
        >
          {_.map(urls.guied_wizard, url => {
            return (
              <div className="w-100 h-100">
                <img className="img-fluid" src={url} alt="" />
              </div>
            );
          })}
        </Slider>
        <div className="skip_button__">
          <button
            className="tutorial_skip_button float-right ml-3"
            onClick={() => {
              if (isEnd) {
                dispatch(closeTutorialModal());
                return;
              }
              sliderRef.current.slickNext();
            }}
          >
            {isEnd ? "Close" : "Next"}
          </button>
          <button
            className="tutorial_skip_button float-right"
            onClick={() => {
              sliderRef.current.slickPrev();
              setIsEnd(false);
            }}
          >
            Previous
          </button>
        </div>
      </Modal>
    );
  } else return null;
}

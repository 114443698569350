const arabicTerms = {
  nav: {
    home: "الصفحة الرئيسية",
    services: "خدمات",
    aboutUs: "نبذة عنا",
    connectUs: "الاتصال بنا",
    privacyPolicy: "سياسة الخصوصية",
    tutorial: "مواد",
    news: "الأخبار",
    plans: "خطط الاشتراك",
    abuse: "الإبلاغ عن إساءة",
    currancy: {
      dollar: "دولار",
      lira: "ليرة",
    },
    auth: "تسجيل الدخول / التسجيل",
    login: "التسجيل",
    reqister: "تسجيل حساب جديد",
  },
  searchErrorMessage: "أدخل نصًا أو ارفع صورة",
  categorie: "الفئات",
  search: "البحث",
  allCategories: "كل الفئات",
  otherCategories: "كل الفئات",
  share: "شارك المنتج ",
  login: {
    login: "تسجيل الدخول",
    signup: "إنشاء حساب جديد",
    email: "البريد الالكتروني",
    password: "الرقم السري",
    forgetPassword: "نسيت كلمة المرور",
    google: "جوجل",
    profile: "الملف الشخصي",
    logout: "تسجيل خروج",
    loginMessage: "سجل دخول لحسابك",
  },
  sectionsTitle: {
    hotDeals: "عروض خاصة",
    forLimitTime: "عروض لفترة محدودة فقط!",
    bestSeller: "الأفضل مبيعًا",
    dealsOf: "عروض",
    theMonth: "الشهر الحالي",
    productsCategories: "فئات المنتجات",
    readyToShip: "جاهز للشحن",
    specialItems: "منتجات خاصة",
    currancyConventer: "تحويل العملات",
  },
  bestQuotationForm: {
    name: "الاسم",
    phone: "رقم الهاتف",
    country: "الدولة",
    email: "البريد الالكتروني",
    bestQuotationTitle: " مستعد للخطوة التالية؟ طلب الأسعار",
    productName: "اسم المنتج",
    unitAmount: "كمية الوحدة",
    description: "الوصف",
    addressTo: "عنوان الوجهة",
    paymentTerms: "شروط السداد",
    submitButtonText: "طلب أسعار ",
    Date_per_each_mile_stone: " تاريخ كل مرحلة",
    date: "التاريخ",
    cachOnDelivery: "الدفع عند الاستلام",
    inAdvance: "الدفع مقدم",

    hint:
      "هل يثير اهتمامك أحد المنتجات؟ املأ النموذج وسنُرسل إليك عرضًا مُفصلاً بأسعار المنتج الذي تستفسر عنه. ",
    byInstallment: "بالتقسيط ",
    requestPricing: "طلب سعر",
  },
  brands: {
    title: "شركاء النجاح",
  },
  featurs: {
    importing: {
      title: "الاستيراد",
      text: `وفر الوقت ومشقة السفر للعديد من البلاد لتوريد المنتجات لشركتك، وابدأ في توريدها من سوق البيع بالجملة عبر الإنترنت. تمنحك منصة GIT-ZONE.com  صلاحية الوصول لآلاف الموردين في مواقع مختلفة، بالإضافة إلى نطاق واسع من الأصناف التي يمكنك الاستيراد من بينها`,
    },
    exporting: {
      title: "التصدير",
      text: ` اعرض منتجك بالصورة التي يستحقها وابدأ في استعراضه عبر الإنترنت. تتمتع أسواق البيع بالجملة عبر الإنترنت بسجل مثبت وسمعة طيبة تخولها بزيادة الربحية وعرض المنتجات.  في GIT-ZONE.com، نوفر لك منصة لعرض منتجاتك والتواصل بسهولة وأمان مع المشترين.‎ ‎‎ ‎ألق نظرة على`,
    },
    investment: {
      title: "الاستثمار",
      text: ` تعد GIT-ZONE.com هي أول منصة تجارة إلكترونية في الشرق الأوسط، وتغطي أفريقيا وأوروبا وبالأساس أي مكان بالعالم.‎ ‎ ولذا، إذا كنت تطمح إلى الاستثمار في مجال أعمال معين في أي بلد، فسوف نساعدك على مواءمة فكرتك مع خطة الأعمال المناسبة`,
    },
    tradeAssurance: {
      title: "الضمان التجاري",
      text: ` نحن نعيد "الثقة" إلى عالم الأعمال مرة أخرى.  وسواء إذا كنت مشتريًا أو بائعًا، فسوف نتولى إجراء الاستقصاء بالنيابة عنك.  تحد "خدمة ضمان التجارة" الخاصة بنا من عمليات الاحتيال في المعاملات التجارية بالنسبة لجميع الأطراف المعنية`,
    },
  },
  shipmentQuotation: {
    title: "وفر واشحن مع GIT-ZONE.com",
    productName: "اسم المنتج",
    categories: "الفئات",
    quantity: "الكمية",
    toLocation: "موقع الوجهة",
    fromLocation: "موقع الصدور",
    requestShipment: "طلب شحن",
    hint:
      "سنساعدك على شحن المنتج الذي طلبته. املأ النموذج وسنُرسل إليك عرض الأسعار المعني بالشحن.",
    shippingMethods: {
      shippingMethods: "طرق الشحن",
      landTransportation: "النقل البري",
      airTransportation: " النقل الجوي",
      oceanTransportation: " النقل عبر المحيط",
    },
    submitButtonText: "طلب",
  },
  newsLetter: {
    title: `اعرف المزيد عن أحدث العروض !
    `,
    form: {
      email: "أدخل عنوان البريد الإلكتروني الخاص بك",
      submit: "إرسال",
    },
    keepInTouch: "البقاء على إطلاع!",
  },
  footer: {
    info: `هي أول منصة للتجارة الإلكترونية في الشرق الأوسط تهدف إلى سد الفجوة بين المشترين والموردين في الأسواق المحلية والعالمية.`,
    company: {
      title: "الشركة",
      siteMap: "خريطة الموقع",
      manual: "الدليل",
      tutorial: "البرنامج التعليمي",
      aboutUs: "نبذة عنا",
      abouse: "الإبلاغ عن إساءة",
    },
    policyInfo: {
      title: "معلومات السياسة",
      faq: "الأسئلة المتداولة",
      privacy: "سياسة GIT-ZONE.com",
      terms: "الشروظ والاحكام",
      plans: "خطط الاشتراك",
    },
  },
  formValidation: {
    productRequried: "اسم المنتج مطلوب",
    categoryRequired: "الفئة مطلوبة  ",
    quantityRequired: "الكمية مطلوبة",
    fromLocationRequired: "موقع الصدور مطلوب",
    toLocationRequired: "موقع الوجهة مطلوب",
    shippingMothodRequired: "طريقة الشحن مطلوبة",
    unitAmountRequired: "عدد الوحدات مطلوب",
    descriptionRequired: "الوصف مطلوب",
    addressRequired: "عنوان البريد الإلكتروني مطلوب",
    emailRequired: "ادخل البريد الالكتروني",
    emailInvalid: "البريد الإلكتروني غير صالح",
    passwordRequired: "كلمة المرور مطلوبة",
    mustMoreThanOne: "يجب أن يكون أكثر من 1",
    paymentTermsRequired: "شروط السداد مطلوبة",
    dateRequired: "التاريخ مطلوب",
    productUrlRequired: "url المنتج مطلوب",
    urlNotValid: "Url ليس صحيح",
  },
  conntactus: {
    leaveMessage: "اترك رسالتك",
    title: `نحن هنا لمساعدتك، لا تتردد في إرسال رسالة بريد إلكتروني أو رسالة إلينا أو حتى الاتصال بنا باستخدام بيانات الاتصال التالية:`,
    fristName: "الاسم الكامل",
    email: "البريد الالكتروني",
    phoneNumber: "رقم الهاتف",
    messageType: "نوع الرسالة",
    subject: "الموضوع",
    yourMessage: "رسالتك",
    branch: "فرع",
    address: "العنوان",
    phone: "رقم الهاتف",
    messageTypeOp: {
      sales: "مبيعات",
      markting: "تسويق",
      customerService: "خدمة العملاء",
      operations: "عمليات",
    },
    validation: {
      emailRequired: "البريد الإلكتروني مطلوب",
      fullNameRequire: "الاسم الكامل مطلوب ",
      emailInvalid: "البريد الإلكتروني غير صالح",
      phoneNumber: "الهاتف مطلوب ",
      messageType: "نوع الرسالة مطلوب",
      subjectRequired: "الموضوع مطلوب",
      messageRequired: "الرسالة مطلوبة",
    },
    submitButton: "إرسال الرسالة",
    //successMessage: "تم إرسال رسالتك"
    successMessage:
      "تم إرسال رسالتك بنجاح و سنقوم بالتواصل معكم فى أقرب وقت ممكن",
  },
  path: {
    home: "الصفحة الرئيسية",
    news: "الاخبار",
    contactUs: "الاتصال بنا",
    aboutUs: " نبذة عنا",
    privacyPolicy: "سياسة الخصوصية",
    terms: "الشروط والأحكام",
    services: "خدمات",
    checkout: "عربة التسوق",
    products: "منتجات",
    search: "بحث",
    wishlist: "قائمة الرغبات",
    cart: "عربة التسوق",
    faq: "اسئلة شائعة",
    compare: "مقارنة",
  },
  product: {
    addToCompare: "اضف الي المقارنة",
    protectOrder: "حماية الطلب من الدفع إلى التسليم",
    tradeAssurance: "ضمان التجارة",
    startOrder: " اتم عملية الشراء",
    shipping: "قم بالشحن",
    share: "شارك",
    shortDescription: "وصف قصير",
    piecesMinOrder: "الحد الادنى للطلب",
    companyData: "عن الشركة ",
    pieceMinOrder: "الحد الادنى للطلب",
    showCase: "  بالنسبة لتسعير المنتج أو التخصيص أو أي استفسارات أخرى:",
    rfqExporter: "RFQ من المصدر",
    productDetails: "تفاصيل المنتج",
    businessType: "نوع العمل",
    totalEmployees: "عدد الموظفين",
    yearEstablished: "سنة التأسيس",
    productCertification: "شهادة المنتج",
    trademarks: "العلامة التجارية",
    companyProfile: "تفاصيل الشركة",
    verifiedCompany: "شركة محققة",
    productDescription: "تفاصيل المنتج",
    reviews: "مراجعات",
    addReview: "اضف تعليق",
    reviewShowCase: " سيتم نشر تعليقك علنًا على الويب. أعرف أكثر",
    rating: "التقييم",
    yourReview: "تقيمك",
    relatedProduct: "منتجات ذات صلة",
    forOnePrice: "للمنتج الواحد",
    minPice: "لكل قطعة",
    stockInAndOut: "حالة المخزون",
    avilable: "متوفر",
    notAvilable: "غير متوفر",
    quantity: "الكمية",
    piece: "قطعة",
    sample: "عينة :",
    productionCapacity: "السعة الإنتاجية",
    qualityControl: "رقابة جودة",
    RDCapacity: "قدرة البحث والتطوير",
    tradeCapacity: "القدرة التجارية",
    sampleShowCase: "3 قطع مجانا",
    free: "مجانا",
    buySample: "شراء عينة",
    companyOverview: "عن الشركة",
    companyName: "اسم الشركة",
    companyMail: "البريد الالكتروني",
    companyPhone: "هاتف الشركة",
    companyLocation: "عنوان الشركة",
    title: "Title",
    notFound: "غير متوفر",
    submitReview: "انشر مراجعتك",
    exporterName: "اسم المصدر",
    customization: "التخصيص :",
    customizationShowCase: "جد ادني قطعة واحدة للشحن",
    customizationLogo: "شعار",
    customizationPackaging: " التعبئة والتغليف ",
  },
  abuseReport: {
    title: " الإبلاغ عن إساءة استخدام",
    fullname: "الاسم الكامل",
    email: "البريد الاالكتروني",
    phone: "رقم الهاتف",
    order: "رقم الطلب",
    abuseType: "نوع إساءة الاستخدام",
    evidence: "عناوين url للأدلة",
    types: {
      importer: "المستورد",
      exporter: "مصدر",
      tradeAccurance: "ضمان التجارة",
      shipment: "الشحن",
      investment: "استثمار",
      others: "آخر",
      phone: "رقم الهاتف مطلوب",
    },
    abuseDetails: "تفاصيل إساءة الاستخدام",
    validation: {
      fullName: "الاسم الكامل مطلوب",
      orderRquired: "رقم الطلب مطلوب",
      emailRequired: "البريد الإلكتروني مطلوب",
      emailNotValid: "البريد الإلكتروني غير صالح",
      evidenceRequired: " الأدلة مطلوبة ",
      abuseDetailsRequired: "تفاصيل إساءة الاستخدام مطلوبة",
    },
    submitButton: "ارسال",
    /*successMessage: "تم إرسال إبلاغك بنجاح"*/
    successMessage:
      "تم إستلام بلاغك بنجاح, سنقوم بدراسة الحالة و الرجوع لكم فى اقرب وقت ممكن",
  },
  compare: {
    noProductAdded: "لم يتم اضافة اي منتجات للمقارنة",
    hideSameFeatures: "إخفاء نفس الميزات",
    hilightDeifference: "تمييز الفرق",
    fillterBy: "صنف بواسطة",
    compareError: "يمكنك فقط مقارنة المنتجات في نفس الفئة",
    allFeatures: "كل المواصفات",
    productFeature: "مواصفات المنتج",
    exporterFeature: "مواصفات المصدر",
    price: "السعر",
    rangePriceFrom: "نطاق السعر من",
    rangePriceTo: "سعر النطاق إلى",
    samplePrice: "سعر العينة",
    minOrderQuentity: "كمية الطلب",
    fobPrice: "سعر الشحن",
    customeOption: "خيار التحصيص",
    rate: "التقييم",
    companyName: "اسم الشركة",
    exporterTitle: "لقب المصدر",
    exporterName: "اسم المصدر",
    companyPhone: "هاتف الشركة",
    companyLocation: "عنوان الشركة",
    companyMail: "البريد الالكتروني",
  },
  popUps: {
    loginSuccess:
      "لقد سجَّلت الدخول بنجاح ارجع إلى الصفحة الرئيسية وقم بالإرسال",
    submitSuccess: "قمت بإرسال طلبك بنجاح و سيتم الرد عليكم فى أقرب وقت ممكن",
    commingSoon: "قريبًا........  ",
  },
  checkout: {
    billing: "معلومات الدفع",
    shiping: "الشحن",
    order: "الطلب",
    payment: "الدفع",
    billingDetails: "معلومات الدفع",
    diffrentAddress: "اشحن الي عنوان اخر",
    next: "التالي",
    fristName: "الاسم الاول",
    lastName: "اسم العائلة",
    companyName: "اسم الشركة",
    country: "الدولة",
    streetAddress: "عنوان الشارع",
    apartment: "رقم الشقة , رقم العمارة  , رقم المبني ",
    city: "المدينة",
    state: 'الدولة / المحافظة "',
    zib: "الرمز البريدي",
    orderNote: "اضف نوتة",
    validation: {
      fristName: "الاسم الاول مطلوب",
      lastName: `اسم العائلة مطلوب`,
      country: "الدولة مطلوبة",
      street: "عنوان الشارع مطلوب",
      city: "المدينة مطلوبة",
    },
  },
  countinuReading: "المزيد",
  cart: {
    productDetails: "تفاصيل المنتج",
    quantity: "الكمية",
    price: "السعر",
    total: "الاجمالي",
    romove: "حذف",
    samplePrice: "سعر العينة",
    checkout: "اتمام الشراء",
    proceedWithExporter: "تابع مع المصدر",
    useCoupon: "استخدم كوبون",
    tradeAccuranceOrder: "ضمان التجارة",
    peice: "قطعة",
    rfqRequest: "RFQ طلب",
    withoutShipment: "بدون شحن",
  },
  wishlistP: {
    addToCompare: "اضافة الي المقارنة",
    selectAll: "تحديد الكل",
    contactExporter: "الاتصال بالمصدر",
    defaultSorting: "رتب حسب",
    sortByRate: "التقييم",
  },
  faq: {
    frequentlyAskedQuestions: "أسئلة شائعة",
    text: ` أبجد هوز حطي كلمن سعفص قرشت ثخذ ضظغ`,
    qaExporter: "اسئلة شائعة للمصدر",
    qaImporter: "اسئلة شائعة للمستورد",
    qaInvestor: "اسئلة شائعة للمستثمر",
  },
  rfqModal: {
    amount: "الكمية",
    productDescription: "تفاصيل المنتج",
    payment: "عدد المدفوعات",
    productPercent: "نسبة المنتج",
    mony: "المال",
    country: "الدولة",
    city: "المدينة",
    address: "العنوان",
    shippingInfo: "معلومات الشحن",
    submitRfq: "ارسل طلبك",
    zib: "الرمز البريدي",
    specialSpecifications: "مواصفات خاصة ",
    paymentType: "شروط السداد",
    phone: "الهاتف",
    description: "تفاصيل المنتج",
    from: "من",
    to: "الي",
    note: "ملاحظات حول الشحن",
    typeOfTransportation: "طريقة الشحن",
    validation: {
      amountMustMoreOne: "ادخل كمية اكبر من 1",
      amount: "الكمية مطلوبة",
      productDetails: "التفاصيل مطلوبة",
      payment: "معلومات الدفع مطلوبة",
      productPercent: "المنتج في المئة المطلوبة",
      mony: "المال مطلوب",
      address: "العنوان مطلوب",
      city: "المدينة مطلوبة",
      country: "الدولة مطلوبة",
      phone: "الهاتف مطلوب",
      shippingFromRequired: "تاريخ بداية الشحن مطلوب",
      shippingToRequired: "تاريخ صدور الشحن مطلوب",
      typeOfTransportationRequired: "طريقة الشحن مطلوبة",
      paymentType: "طريقة السداد مطلوبة",
    },
  },
  or: "او",
  learnmore: "اعرف اكثر",
  signupNow: "سجل دخول الان",
  ok: "حسنا",
  wishlistEmpty: "قائمة الأمنيات فارغة",
  cartEmpty: "عربة التسوق فارغة",
  seeMore: "المزيد ...",
  seeLess: "اقل ...",
  loginFirst: "يجب ان تسجل الدخول اولا",
  addToCart: "اضف الي عربة التسوق",
  addedToCart: "تمت الاضافة الي عربية التسوق",
  searchP: {
    filterBy: "صنف بواسطة",
    stockStatus: "حالة المخزون",
    searchShowCase: 'نتائج البحث من "المنتج"',
    inStock: "في المخزن",
    outStock: "خارج المخزن",
    companys: "الشركات",
    readyToShip: "جاهز للشخن",
    ready: "جاهز",
    for: "عن",
    in: "في",
    notReady: "غير جاهز",
    rangePrice: "السعر",
    quantity: "الحد الأدنى من الكمية",
    location: "موقع المصدر",
    less: "اقل من",
    rate: "معدل التقييم",
    above: "اعلي من",
    priceMinOrder: "قطعة للطلب",
    productEmpaty: "لا توجد منتجات",
    showing: "عرض",
    of: "من",
    products: "منتج",
    sorting: {
      default: "رتب حسب",
      byName: "الاسم",
      byPrice: "السعر",
      bypopularity: "حسب الشعبية",
      newest: "بواسطة أحدث",
      bestReview: "أفضل مراجعات",
      priceHighToLow: "السعر من اللاعلي الي الاقل",
      priceLowToHigh: "السعر من الاقل الي الاعلي",
      byRate: "التقييم",
    },

    countrys: {
      egypt: "مصر",
      china: "الصين",
      russia: "روسيا",
      germany: "المانيا",
      other: "آخر",
    },
  },
  permistionMessages: {
    mustImporter: "يجب ان تسجل الدخول كمستورد اولا للاستمرار ",
  },
  plans: {
    plans: "خطط",
    subscription: "الإشتراك",
    choosePlan: "اختر الخطة",
  },
  tradeAssuranceReq: {
    name: "الاسم",
    email: "البريد الالكتروني",
    request: "الطلب",
    url: "الرابط",
    phone: "رقم الهاتف",
    joinUs: "انضم الينا",
    requestQuotation: "طلب اقتباس",
    reqTradeAssurance: "طلب ضمان التجارة",
    validation: {
      phoneRequired: "الهاتف مطلوب",
      nameRequired: "الاسم مطلوب",
      emailRequired: "البريد الالكتروني مطلوب ",
      emailNotValid: "البريد الالكتروني غير صحيح",
      request: "الطلب مطلوب",
      urlNotValid: "الرابط ليس صحيح",
    },
  },
  utils: {
    //thanks: "شكرا"
    thanks: "تم الإشتراك بنجاح!إنتظر أخر تحديثاتنا على بريدك الإليكترونى",
  },
  services: {
    importing: "المستورد",
    exporting: "المصدر",
    investment: "استثمار",
    tradeAssurance: "ضمان التجارة",
    showCase: `صممت لمساعدتك في تطوير أعمالك. وفي هذا القسم، سوف نساعدك على إلقاء نظرة فاحصة على الخدمات التي نوفرها عبر منصتنا لتيسير التجارة الآمنة`,
  },
  aboutUs: {
    welcome: "مرحبًا بك في GIT-ZONE.com. ",
    showCase: `أول منصة للتجارة الإلكترونية في الشرق الأوسط تهدف إلى سد الفجوة بين المشترين والموردين في الأسواق المحلية والعالمية. وقد صُممت منصة GIT-ZONE.com لتيسير التجارة الآمنة لجميع الأطراف المعنية، وذلك من خلال خلق بيئة عمل آمنة وقائمة على التكنولوجيا ومستدامة لكل من المشترين والموردين والمستثمرين.`,
    letter: `
    يمكنك أن تجد القيمة المقترحة لمنصتنا فيما تتمتع به من نطاق واسع من الأصناف المتوفرة بأسعار تنافسية، وإتاحة التواصل بسهولة بين جميع الأطراف، وسياسة "الحجم لا يهم" المقدمة للمشترين، فضلًا عن جودة المنتجات والخدمات المضمونة حتى بعد البيع.
      `,
    showCae2: ` لا تقتصر منصتنا على صنف أو بلد بعينه، ولذا يتمتع المشترون والموردون لدينا بتجارة بلا حدود. وتتنوع أصنافنا من الإلكترونيات حتى الملابس والإكسسورات والمنتجات الزراعية والمزيد غيرها، ويمكنك إلقاء نظرة سريعة علي هذه الأصناف بسهولة عبر `,
    here: "HERE",
    showcae3:
      "إذا كنت أحد المشترين أو الموردين أو المستثمرين، فيمكننا بالتأكيد مساعدتك في تطوير أعمالك.",
    contactUsNow: "اتصل بنا الآن",
    here: "هنا",
    vision: "الرؤية",
    visionDetails: ` نسعى لسد الفجوة بين المشترين والبائعين من خلال ما نوفره من "تجارة بلا حدود" لا تقتصر على الشرق الأوسط فحسب، بل تمتد أيضًا لبقية البلدان الأفريقية وبلاد العالم أجمع.
    ويمكن إنجاز كل ذلك عبر تيسير التجارة الآمنة بشكل أكبر من خلال منصة سهلة الاستخدام وقائمة على التكنولوجيا.`,
    mission: "المهمة",
    missionDetails: `نحن نهتم في GIT-ZONE.com بمعاملاتك التجارية بدءًا من لحظة تسجيلك على منصتنا. وسواء إذا كنت موردًا أو مشتريًا أو مستثمرًا، فإن مهمتنا تتمحور حول تزويدك بالأدوات التي ستيسر معاملاتك التجارية وتنجزها بكفاءة وفي الوقت المناسب.`,
    values: "القيم",
    valuesDetails: ` سعينا إلى مشاركة مجموعة قيمنا علنًا حتى نساعدك على التعرف أكثر على منصتنا ونعطيك لمحة عن الطريقة التي ننجز بها أعمالنا.`,
    quality: "الجودة ",
    qualityD: `مع خدمة "ضمان التجارة" الخاصة بنا، نحن نضمن لك معاملات تجارية دون احتيال مع الحد من تعرضها لأي مخاطر.
    `,
    diversity: "التنوع ",
    diversityD: ` نوفر نطاقًا واسعًا من منتجات التي يمكن المتاجرة فيها. ونوفر جميع المنتجات بعروض وأسعار تنافسية حرصًا على مصلحة مستخدمي منصتنا.`,
    passion: "الشغف ",
    passionD: `نحلم أحلامًا كبيرة، وسنرتقي بأعمالك معنا!`,
    importing: "الاستيراد",
    importingD: `وفر الوقت ومشقة السفر للعديد من البلاد لتوريد المنتجات لشركتك، وابدأ في توريدها من سوق البيع بالجملة عبر الإنترنت. وتمنحك منصة GIT-ZONE.com  صلاحية الوصول لآلاف الموردين في مواقع مختلف، بالإضافة إلى نطاق واسع من الأصناف التي يمكنك الاستيراد من بينها.‎ `,
    learnMore: "‏‏تعرّف على المزيد‎ ‎",
    tradeAssurance: "ضمان التجارة",
    tradeAssuranceD: `نحن نعيد "الثقة" إلى عالم الأعمال مرة أخرى.  وسواء إذا كنت مشتريًا أو بائعًا، فسوف نتولى إجراء الاستقصاء بالنيابة عنك.  تحد "خدمة ضمان التجارة" الخاصة بنا من عمليات الاحتيال في المعاملات التجارية بالنسبة لجميع الأطراف المعنية.`,
    signUpNow: `التسجيل الآن!‎ ‎`,
    exporter: " التصدير",
    exporterD: ` اسمح بعرض منتجك بالصورة التي يستحقها وابدأ في استعراضه عبر الإنترنت. تتمتع أسواق البيع بالجملة عبر الإنترنت بسجل مثبت وسمعة طيبة تخولها بزيادة الربحية وعرض المنتجات.  في GIT-ZONE.com، نوفر لك منصة لعرض منتجاتك والتواصل بسهولة وأمان مع المشترين.‎ألق نظرة على `,
    investment: "الاستثمار",
    investmentD: `تعد GIT-ZONE.com هي أول منصة تجارة إلكترونية في الشرق الأوسط، وتغطي أفريقيا وأوروبا وبالأساس أي مكان بالعالم.‎ ‎ ولذا، إذا كنت تطمح إلى الاستثمار في مجال أعمال معين في أي بلد، فسوف نساعدك على مواءمة فكرتك مع خطة الأعمال المناسبة.`,
  },
  map: {
    capital: "العاصمة",
    language: "اللغة",
    area: "منطقة",
    growthRate: "معدل النمو",
    moreInfo: "المزيد",
    askforConsultation: "طلب التشاور",
    downloadAsPDF: "تحميل بصيغة PDF",
    submit: "ارسل",
    phone: "الهاتف",
    note: "ملاحظات",
    population: "تعداد السكان",
    currency: "العملة",
  },
  privacyPolicy: {
    _1: {
      title: "حرص GIT-ZONE.com على الاهتمام بك!",
      body: `إن إحدى المهمات الأساسية لمنصة GIT-ZONE.com هي خلق منصة تجارة إلكترونية آمنة وقائمة على التكنولوجيا لجميع التجار، ونحن حريصون على حماية بياناتك الشخصية وتأمين كل خطوة في كل معاملة تجارية تجريها على منصتنا. وهذا القسم من المنصة يستعرض سياسة الخصوصية الخاصة بنا، ويفسر كذلك جمعنا لبياناتك الشخصية وكيفية ومكان استخدامها.  `,
    },
    _2: {
      title: "ما البيانات التي نجمعها وكيف نحميها؟",
      body_1: `كجزء من تسجيلك على منصة GIT-ZONE.com، ستكون مطالبًا بتوفير بيانات شخصية معينة كاسمك و/أو عنوان الشحن و/أو عنوان البريد الإلكتروني و/أو رقم هاتفك وأي بيانات مشابهة. وفضلًا عن ذلك، كي نتمكن من التحقق من هويتك، قد نحتاج لطلب وثيقة صالحة لإثبات هويتك، وسنحتاج كذلك لجمع بيانات مالية محددة منك كتفاصيل بطاقة الائتمان الخاصة بك و/أو حسابك البنكي. ونحن نستخدم هذه البيانات المالية لأغراض الفوترة. وعقب تسجيلك على الموقع، يجب عليك عدم نشر أي بيانات شخصية (بما في ذلك أي معلومات مالية) في أي مكان على الموقع.`,
      body_2: `تقع على عاتقك مسئولية حماية اسم المستخدم وكلمة المرور الخاصين وتأمينهما على GIT-ZONE.com. ونحن نوصي باختيار كلمة مرور قوية مع تغييرها بشكل متكرر. ونرجو عدم استخدام نفس تفاصيل تسجيل الدخول (البريد الإلكتروني وكلمة المرور) عبر عدة مواقع ويب.`,
      body_3:
        "هذا يشير إلى أننا نطبق مجموعة متنوعة من الإجراءات التي تشمل عرض استخدام خادم آمن. وبعد أية معاملة، لن يتم تخزين بياناتك الخاصة (كبطاقات الائتمان والبيانات المالية وإلخ) على خوادمنا. ويتم فحص خوادمنا ومواقعنا على الويب بشكل روتيني لحمايتك عبر الإنترنت",
    },
    _3: {
      title: "حقوق النشر",
      body:
        "إن كل المحتويات المضمنة في هذا الموقع أو المتاحة عبر مدخل GIT-ZONE.com كالنصوص والرسومات والشعارات ورموز الأزرار والصور ومقاطع الفيديو والصوت والتنزيلات الرقمية والتحويلات البرمجية للبيانات والبرامج تعد ملكًا لمنصة GIT-ZONE.com أو موردي محتوياتها، وتخضع لحماية قوانين حقوق النشر الدولية. ويعتبر التحويل البرمجي لكل المحتويات المضمنة في أو المتاحة عبر موقع GIT-ZONE.com ملكيةً حصرية لمنصة GIT-ZONE.com ومحميةً بموجب قوانين حقوق النشر الدولية.. كما تعد كل البرامج المستخدمة في أي خدمة على منصة GIT-ZONE.com هي ملكية لمنصة GIT-ZONE.com أو موردي برامجها، وتخضع لحماية قوانين حقوق النشر الدولية.",
    },
    _4: {
      title: "روابط الأطراف الخارجية",
      body: `قد نقوم بتضمين أو عرض منتجات أو خدمات لأطراف خارجية على موقعنا على الويب. وقد تُستخدم هذه المنتجات/الخدمات الخاصة بالأطراف الخارجية لشحن المنتجات و/أو جمع المدفوعات و/أو الحماية من الاحتيال و/أو تقليل المخاطر التي تتعرض لها بطاقات الائتمان. كما أن كل موقع من مواقع هذه الأطراف الخارجية لديه سياسة خصوصية منفصلة ومستقلة. ولذلك، فنحن لا نتحمل أي مسئولية قانونية حيال محتويات وأنشطة هذه المواقع التي تم تضمين روابطها. وبالرغم من ذلك، نحن نسعى لحماية تكامل موقعنا، ونرحب بأي تعليقات حول هذه المواقع.`,
    },
    _5: {
      title: "هل نستخدم ملفات تعريف الارتباط؟",
      body_1: `نعم. تعتبر ملفات تعريف الارتباط هي ملفات صغيرة ينقلها موقع أو موفر خدمته إلى محرك الأقراص الثابتة بجهاز الكمبيوتر الخاص بك من خلال مستعرض الويب لديك (إذا سمحت بذلك عبر إعداداتك). حيث يسمح ذلك للمواقع أو موفري الخدمة بالتعرف على المستعرض الخاص بك، بالإضافة إلى تسجيل بيانات معينة وتذكرها.`,
      body_2: `ونحن نستخدم ملفات تعريف الارتباط لتساعدنا على تذكر العناصر الموجودة في سلة التسوق الخاصة بك ومعالجتها، واستيعاب تفضيلاتك وحفظها من أجل الزيارات المستقبلية، بالإضافة إلى التحويل البرمجي للبيانات المجمعة حول نسبة استخدام الموقع وتفاعله، وذلك حتى نتمكن من توفير تجارب وأدوات أفضل على الموقع من أجلك في المستقبل. كما نضمن لك أن استخدامنا لملفات تعريف الارتباط  لايشمل أي تفاصيل شخصية أو خاصة، وأنها خالية من أية فيروسات. وإذا كنت تريد البحث عن المزيد من المعلومات حول ملفات تعريف الارتباط، فتفضل بزيارة `,
      body_3: ` ، أو إذا كنت تريد البحث عن إزالتها من المستعرض الخاص بك، فتفضل بزيارة `,
      body_4: `نحن نستخدم "إحصاءات Google"، وهي خدمة إحصاءات للويب توفرها شركة Google لتساعدنا حتى نفهم بشكل أفضل كيفية اشتراك المستخدمين في مدخل GIT-ZONE.com. وتستخدم "إحصاءات Google" ملفات تعريف الارتباط لتجميع معلومات حول استخدام موقعنا على الويب. وتُستخدم هذه المعلومات للتحويل البرمجي للتقارير وإنشاء خدمات لتساعدنا على تحسين موقعنا على الويب والخدمات المقرنة به. وتكشف التقارير عن اتجاهات مواقع الويب دون تحديد كل زائر بشكل فردي. كم أن المعلومات المنشأة بواسطة ملفات تعريف الارتباط الخاصة بـ Google حول استخدامك لموقعنا على الويب (بما في ذلك عنوان IP الخاص بك) قد يتم نقلها إلى شركة Google وتخزينها بواسطتها على خوادم في الولايات المتحدة. وبالإضافة إلى ذلك، قد تنقل Google أيضًا هذه المعلومات إلى أطراف خارجية إذا لزم الأمر بموجب القانون، أو حيثما تعالج هذه الأطراف الخارجية المعلومات بالنيابة عن Google. ولن تقوم Google بإقران عنوان IP الخاص بك بأي بيانات أخرى لدى Google.`,
    },
    _6: {
      title: "تقارير الإبلاغ عن أي إساءة على GIT-ZONE.com",
      body: `الرجاء الإبلاغ عن أي مشاكل من أي نوع أو أي انتهاكات لـ "اتفاقية المستخدم" هذه إلى لموقع GIT-ZONE.com. وإذا كنت تعتقد أنه قد جرى انتهاك حقوق الملكية الفكرية الخاصة بك، فيرجى إخطار GIT-ZONE.com بذلك. ودون الإخلال بأي حقوق أو تعويضات أخرى تخص GIT-ZONE.com بموجب "اتفاقية المستخدم" هذه أو القانون، قد تقيد GIT-ZONE.com أو تعلق أو تسحب صلاحية وصول المستخدم إلى الموقع و/أو عضوية المستخدم بالموقع أو تزيل المحتوى المستضاف. فضلًا عن أن منصة GIT-ZONE.com يمكنها اختيار اتخاذ خطوات تقنية و/أو قانونية أخرى ضد المستخدمين الذين يخلقون المشاكل، أو تقع على عاتقهم أي مسئولية قانونية ممكنة من أي نوع، والذين ينتهكون حقوق الملكية الفكرية للأطراف الخارجية أو الذين لا يتصرفون طبقًا لـ "اتفاقية المستخدم" هذه أو سياساتنا.`,
    },
  },
};

export default arabicTerms;

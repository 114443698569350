import React, { useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHeart,
  faExchangeAlt,
  faShareAlt,
  faShoppingCart
} from "@fortawesome/free-solid-svg-icons";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import { Popover, OverlayTrigger } from "react-bootstrap";
import { faHeart as faRegularHeart } from "@fortawesome/free-regular-svg-icons";
import { useSelector } from "react-redux";
import { addToWishList } from "../../../store/actions/wishListActions";
import { useDispatch } from "react-redux";
import { addToCart } from "../../../store/actions/cartActions";
import { addToCompare } from "../../../store/actions/compareActions";
import strings from "../../../Localization/LNG";
import { openFileNotUploded } from "../../../store/actions/UiAction";
import {
  openLoginModal,
  openModal,
  CheckNotIpload
} from "../../../store/actions/UiAction";

function makeAnimation(cartRef) {
  if (!cartRef.current.classList.contains("loading")) {
    cartRef.current.classList.add("loading");
    setTimeout(() => cartRef.current.classList.remove("loading"), 3700);
  }
}

function CardBox({
  product,
  openFarqModal,
  urls,
  onChangeTrade,
  onShare,
  tradeAssurance,
  history,
  quentity,
  ifFob
}) {
  const [addedToCart, setAddedToCart] = useState(false);
  const dispatch = useDispatch();
  const Ifwishlist = useSelector(state => state.wishlist.items[product.id]);
  const lng = useSelector(state => state.lng);
  const isAuth = useSelector(state => state.user.isAuth);
  const [openTradeAssurenceModel, , setOpenTradeAssurenceModel] = useState(
    false
  );
  const userRoules = useSelector(state => state.user.user);
  const [shipping, setShipping] = useState(true);
  const cartRef = useRef();
  const onAddToCart = () => {
    if (shipping) {
      product.shipment = shipping;
    }
    if (tradeAssurance) {
      const newProduct = { ...product, trade_value: +urls.trade_assurance };
      dispatch(addToCart(newProduct, quentity, tradeAssurance, ifFob));
    } else if (ifFob) {
      const productPrice = _.add(
        parseInt(product.regular_price),
        parseInt(product.fob_price)
      );
      const newProduct = { ...product, regular_price: productPrice.toString() };
      dispatch(addToCart(newProduct, quentity, tradeAssurance, ifFob));
    } else if (tradeAssurance && ifFob) {
      const productPrice = _.add(
        parseInt(product.regular_price),
        parseInt(product.fob_price),
        parseInt(urls.trade_assurance)
      );
      const newProduct = { ...product, regular_price: productPrice.toString() };
      dispatch(addToCart(newProduct, quentity, tradeAssurance, ifFob));
    } else {
      dispatch(
        addToCart(product, quentity, tradeAssurance, ifFob, false, cartRef)
      );
    }
  };
  const onAddToWishList = () => {
    dispatch(addToWishList(product));
  };
  const onOpenExporterModal = () => {
    if (isAuth) {
      const isImporter = _.some(userRoules.roles, r => r.name === "importer");
      if (!isImporter) {
        dispatch(openModal(strings.permistionMessages.mustImporter));
        return;
      }
      openFarqModal("supplier");
    } else {
      dispatch(openLoginModal(strings.loginFirst));
    }
  };
  const openRefqModal = () => {
    if (isAuth) {
      if (userRoules) {
        const isImporter = _.some(userRoules.roles, r => r.name === "importer");
        if (!isImporter) {
          dispatch(openModal(strings.permistionMessages.mustImporter));
          return;
        }
      }
      const { docs_uploaded, approved } = userRoules;
      if (!docs_uploaded) {
        dispatch(openFileNotUploded());
        return;
      }
      if (approved === "waiting" || approved === "rejected") {
        dispatch(
          openModal(
            approved === "waiting"
              ? "Your account must be approved first"
              : "your registration has been rejected ,Please check your email "
          )
        );
        return;
      }
      openFarqModal("exporter");
    } else {
      dispatch(openLoginModal(strings.loginFirst));
    }
  };
  const startOrder = () => {
    dispatch(openModal(strings.popUps.commingSoon));
    return;
    if (isAuth) {
      const isImporter = _.some(userRoules.roles, r => r.name === "importer");
      if (!isImporter) {
        dispatch(openModal(strings.permistionMessages.mustImporter));
        return;
      }
      if (!product.ready_to_ship) {
        dispatch(
          openModal("Product is not ready to ship place , RFQ from exporter ")
        );
        return;
      }
      if (!userRoules.docs_uploaded) {
        dispatch(CheckNotIpload());
        return;
      }
      const { approved } = userRoules;
      if (approved === "waiting" || approved === "rejected") {
        dispatch(
          openModal(
            approved === "waiting"
              ? "Your account must be approved first"
              : "your registration has been rejected ,Please check your email "
          )
        );
        return;
      }
      history.push(
        `/checkout/${product.id}?quentity=${quentity}&slug=${product.slug}${
          tradeAssurance ? "&trade_accurance=true" : ""
        }${shipping ? "&shiping=true" : ""} &order_direct=true`
      );
    } else {
      dispatch(openLoginModal(strings.loginFirst));
    }
  };
  return (
    <div
      className={`border cart_box p-4 ${lng === "arabic" ? "text-right" : ""} `}
    >
      <div className="btn-group">
        <button
          onClick={() => {
            dispatch(addToCompare(product));
            history.push("/compare");
          }}
          className={`primary-button add_to_c ${lng === "arabic" ? "ar" : ""}`}
          style={
            lng === "arabic"
              ? { fontSize: "14px", borderLeft: ".9px solid #eee" }
              : { fontSize: "14px", borderRight: ".9px solid #eee" }
          }
        >
          {strings.product.addToCompare}
        </button>
        <button
          onClick={() => {
            dispatch(addToCompare(product));
            history.push("/compare");
          }}
          className={`primary-button add_to_compare_ex ${
            lng === "arabic" ? "ar" : ""
          } `}
          style={{
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0
          }}
        >
          <FontAwesomeIcon icon={faExchangeAlt} />
        </button>
        <button
          onClick={onAddToWishList}
          className="mr-2 ml-2 border card_button_icon"
        >
          <FontAwesomeIcon icon={Ifwishlist ? faHeart : faRegularHeart} />
        </button>
        <button onClick={onShare} className="border bg-none card_button_icon">
          <FontAwesomeIcon icon={faShareAlt} />
        </button>
      </div>
      <div className="content mt-5">
        <p style={{ fontSize: "14px" }}>{strings.product.protectOrder}</p>
        <div className="custom-control custom-checkbox">
          <input
            type="checkbox"
            className="custom-control-input"
            checked={tradeAssurance}
            id="customCheck1"
            onChange={onChangeTrade}
          />
          <label
            style={{ fontSize: "12px", lineHeight: "24px" }}
            className="custom-control-label trade_assurence_link "
            htmlFor="customCheck1"
          ></label>
          <OverlayTrigger
            trigger="hover"
            key="top"
            placement="top"
            overlay={
              <Popover id={`popover-positioned-top`}>
                <Popover.Title as="h3">
                  {strings.product.tradeAssurance}
                </Popover.Title>
                <Popover.Content>
                  Trade Assurance basically aims to ensure that the order you
                  receive fulfills the obligations specified in your contract
                  with a participating supplier.
                </Popover.Content>
              </Popover>
            }
          >
            <label
              style={{ fontSize: "12px", lineHeight: "24px" }}
              className=" trade_assurence_link "
            >
              {strings.product.tradeAssurance}
            </label>
          </OverlayTrigger>
        </div>
        <div className="custom-control custom-checkbox mt-2">
          <input
            type="checkbox"
            className="custom-control-input"
            checked={shipping}
            id="shippingInput"
            onChange={() => setShipping(!shipping)}
          />
          <label
            style={{ fontSize: "12px", lineHeight: "24px" }}
            className="custom-control-label"
            htmlFor="shippingInput"
          >
            {strings.product.shipping}
          </label>
        </div>
        <div className="text-center mt-4">
          {+product.stock > 0 ? (
            <div onClick={() => onAddToCart()} className={`btn-group`}>
              <button
                className={`add-to-card-details cart_btn  ${
                  lng === "arabic" ? "ar" : ""
                } `}
                ref={cartRef}
              >
                <span> {strings.addToCart} </span>
                <div className="cart">
                  <svg viewBox="0 0 36 26">
                    <polyline points="1 2.5 6 2.5 10 18.5 25.5 18.5 28.5 7.5 7.5 7.5"></polyline>
                    <polyline points="15 13.5 17 15.5 22 10.5"></polyline>
                  </svg>
                </div>
              </button>
              <button
                className={`cart_icon_btn  ${lng === "arabic" ? "ar" : "en"}`}
              >
                <FontAwesomeIcon icon={faShoppingCart} />
              </button>
            </div>
          ) : null}
          {product.ready_to_ship && +product.stock > 0 ? (
            <div className="order-button mt-4">
              <button className="primary-button" onClick={startOrder}>
                {strings.product.startOrder}
              </button>
            </div>
          ) : null}
          <div className="mt-5">
            <p
              className={` cart_box_text ${lng === "arabic" ? "" : ""} `}
              style={{ fontSize: "14px" }}
            >
              {strings.product.showCase}
            </p>
          </div>
          <button
            className="primary-button"
            style={{ width: "15rem", fontSize: "14px" }}
            onClick={onOpenExporterModal}
          >
            {strings.wishlistP.contactExporter}
          </button>
          <button
            className="primary-button mt-3"
            style={{ width: "15rem", fontSize: "14px" }}
            onClick={openRefqModal}
          >
            {strings.product.rfqExporter}
          </button>
        </div>
      </div>
    </div>
  );
}
export default withRouter(CardBox);

import React from "react";
import ReactDOM from "react-dom";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../store/actions/UiAction";
import strings from "../../Localization/LNG";
const ModalCom = props => {
  const dispatch = useDispatch();
  const isModalShow = useSelector(state => state.ui.openModal);
  const message = useSelector(state => state.ui.message);
  const lng = useSelector(state => state.lng);
  return ReactDOM.createPortal(
    <div onClick={() => dispatch(closeModal())}>
      <Modal
        show={isModalShow}
        dir={`${lng === "arabic" ? "rtl" : "ltr"}`}
        onHide={() => dispatch(closeModal())}
        onEscapeKeyDown={() => dispatch(closeModal())}
        keyboard={true}
      >
        <div className="p-5 text-center" onClick={e => e.stopPropagation()}>
          <h4> {message} </h4>
          <button
            onClick={() => dispatch(closeModal())}
            className="mt-3"
            style={backButton}
          >
            {strings.ok}
          </button>
        </div>
      </Modal>
    </div>,
    document.querySelector("#modal")
  );
};

const backButton = {
  cursor: "pointer",
  border: "none",
  padding: "8px",
  borderRadius: "6px",
  width: "11rem",
  color: "white",
  background: "#5B5756"
};

export default ModalCom;

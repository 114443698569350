import React from "react";
import { useSelector } from "react-redux";

function LogoutIframe(props) {
  const isIframeOpen = useSelector(state => state.ui.logoutIframe);
  const { urls } = useSelector(state => state.urls);
  if (isIframeOpen) {
    return (
      <iframe
        hidden
        src={`${urls.profile_url}/LOGOUT`}
        frameBorder="0"
      ></iframe>
    );
  } else return null;
}

export default LogoutIframe;

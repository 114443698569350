import { CHANGE_CURRENCY } from "../actions/types";

const INITIAL_STATE = {
  currency: {
    symbol: "$",
    name: "US Dollar",
    symbol_native: "$",
    decimal_digits: 2,
    rounding: 0,
    code: "USD",
    name_plural: "US dollars"
  }
};

const currencyReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CHANGE_CURRENCY:
      return {
        ...state,
        currency: action.payload
      };
    default:
      return state;
  }
};

export default currencyReducer;

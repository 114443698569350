import React from "react";

const LogoSpinner = () => {
  return (
    <div className="spinner_contener">
      <img height="150" src={require("../../assets/gif/gif.gif")} alt="" />
    </div>
  );
};

export default LogoSpinner;

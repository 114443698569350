import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { useSelector, useDispatch } from "react-redux";
import "./footer.css";
import {
  openAbuseModal,
  openModal,
  openTutorialSlider
} from "../../store/actions/UiAction";
import Strings from "../../Localization/LNG";
import { Link, withRouter } from "react-router-dom";
function FooterComponent(props) {
  const lng = useSelector(state => state.lng);
  const urls = useSelector(state => state.urls.urls);
  const dispatch = useDispatch();
  const commingSoon = () => {
    dispatch(openModal(Strings.popUps.commingSoon, null));
  };
  useEffect(() => {
  }, [""]);
  return (
    <footer dir={`${lng === "arabic" ? "rtl" : "ltr"}`}>
      <div className="container text-white">
        <div className="row pt-2">
          <div className="col-md-5 mt-5 mb-5">
            <div className="row mb-4 text-left">
              <div className="col-lg-4 footer-logo">
                <img
                  onClick={() => props.history.push("/")}
                  src={require("../../assets/icons/w logo.png")}
                  alt=""
                  style={{ cursor: "pointer" }}
                />
              </div>
              <div
                className={`col footer_company_title ${
                  lng === "arabic" ? "text-right mr-2" : "text-left ml-2"
                }`}
              >
                <p style={{ width: "90%" }}>{Strings.footer.info}</p>
              </div>
            </div>
            <div className="mobile-store text-center">
              <a onClick={commingSoon} href="##">
                <img
                  style={{ width: "110px" }}
                  src={require("../../assets/svg/Download_on_the_App_Store_Badge.svg")}
                  alt=""
                />
              </a>
              <a onClick={commingSoon} href="##">
                <img
                  src={require("../../assets/svg/Google_Play_Store_badge_EN.svg")}
                  className="mr-2 ml-2"
                  style={{ width: "110px" }}
                  alt=""
                />
              </a>
            </div>
          </div>
          <div
            className={`col footer-section ${
              lng === "arabic" ? "text-right pr-4" : "text-left pl-4"
            } `}
          >
            <h6>{Strings.footer.company.title}</h6>
            <ul className="p-0 mt-4">
              <li>
                <Link target="_blank" to="/subscription-plans">
                  {Strings.footer.policyInfo.plans}
                </Link>
              </li>
              <li>
                <Link to="/home/faq" target="_blank">
                  {Strings.footer.policyInfo.faq}
                </Link>
              </li>
              <li>
                <Link to="/aboutus" target="_blank">
                  {Strings.footer.company.aboutUs}
                </Link>
              </li>
              <li>
                <Link to="/sitemap" target="_blank">
                  {Strings.footer.company.siteMap}
                </Link>
              </li>
              <li>
                <a
                  onClick={e => {
                    e.preventDefault();
                    dispatch(openTutorialSlider());
                  }}
                  href="##"
                >
                  {Strings.footer.company.tutorial}
                </a>
              </li>
            </ul>
          </div>
          <div
            className={`col footer-section ${
              lng === "arabic" ? "text-right" : "text-left"
            } `}
          >
            <h6>{Strings.footer.policyInfo.title} </h6>
            <ul className="p-0 mt-4">
              <li className="">
                <a href="##" onClick={() => dispatch(openAbuseModal())}>
                  {Strings.nav.abuse}
                </a>
              </li>
              <li className="">
                <Link to="/home/privacy-policy" target="_blank">
                  {Strings.footer.policyInfo.privacy}
                </Link>
              </li>
              <li>
                <a target="_blank" href={urls.guied_url}>
                  {Strings.footer.company.manual}
                </a>
              </li>
              <li>
                <Link to="/terms" target="_blank">
                  {Strings.footer.policyInfo.terms}
                </Link>
              </li>
            </ul>
          </div>
          <div className="col hot-line-col">
            <div className="">
              <div className="mt-3 mb-2 info">
                <a href="mailto:info@GIT-ZONE.com" className="text-white">
                  <FontAwesomeIcon icon={faEnvelope} /> info@GIT-ZONE.com
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default withRouter(FooterComponent);

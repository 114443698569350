import React from "react";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  WhatsappShareButton,
  WhatsappIcon
} from "react-share";
import { useSelector } from "react-redux";
export default function ShareButtons({ product }) {
  const lng = useSelector(state => state.lng);
  return (
    <>
      <FacebookShareButton
        quote={product.name}
        className={`d-inline-block rounded-lg ${
          lng === "arabic" ? "ml-2" : ""
        } `}
        url={document.URL}
      >
        <FacebookIcon size={32} />
      </FacebookShareButton>
      <TwitterShareButton
        className="d-inline-block ml-2 rounded-lg"
        url={document.URL}
        title={product.name}
      >
        <TwitterIcon size={32} />
      </TwitterShareButton>
      <LinkedinShareButton
        className="d-inline-block ml-2 rounded-lg"
        url={document.URL}
      >
        <LinkedinIcon size={32} />
      </LinkedinShareButton>
      <WhatsappShareButton
        title={product.name}
        className="d-inline-block ml-2 rounded-lg"
        url={document.URL}
      >
        <WhatsappIcon size={32} />
      </WhatsappShareButton>
    </>
  );
}

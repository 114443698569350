import React from "react";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import { getProduct } from "../../store/actions/productsAction";
import { useSelector, useDispatch } from "react-redux";
import strings from "../../Localization/LNG";
import Price from "../utils/Price";

function SideBar(props) {
  const dispatch = useDispatch();
  const lng = useSelector(state => state.lng);
  const onClick = slug => {
    props.history.push(`/product/${slug}`);
    dispatch(getProduct(slug));
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  };
  const { banner, relatedProducts, bannerHref } = props;
  return (
    <>
      <div className="banner mt-3">
        {banner ? (
          <img
            onClick={() => window.open(bannerHref ? bannerHref : "")}
            src={banner}
            className="img-fluid w-100 banner_img"
            alt=""
          />
        ) : null}
      </div>
      <div className="related_products border p-3 mt-4">
        <p
          className={`${lng === "arabic" ? "text-right" : ""}`}
          style={{ color: "#bd1723" }}
        >
          {strings.product.relatedProduct}
        </p>
        <ul className="list-unstyled">
          {_.map(relatedProducts, product => {
            return (
              <div
                onClick={() => window.open(`/product/${product.slug}`)}
                key={product.id}
                style={{ cursor: "pointer" }}
              >
                <li className="media">
                  <img
                    style={{ maxHeight: "4rem" }}
                    style={{ width: "4rem" }}
                    src={
                      product.url
                        ? product.url
                        : require("../../assets/placeholder/200X200.jpg")
                    }
                    className="mr-3 align-self-start img-fluid"
                    alt={product.slug}
                  />
                  <div
                    className={`media-body ${
                      lng === "arabic" ? "text-right mr-2" : ""
                    } `}
                  >
                    <p className="mt-0 mb-1"> {product.name} </p>
                    <p style={{ color: "#bd1723" }}>
                      <Price price={product.regular_price} />
                      <span> {strings.product.forOnePrice} </span>
                    </p>
                    <p className="text-muted">
                      <Price price={product.range_price_from} /> -
                      <Price price={product.range_price_to} />
                      {strings.product.minPice}
                    </p>
                  </div>
                </li>
                <hr />
              </div>
            );
          })}
        </ul>
      </div>
    </>
  );
}

export default withRouter(SideBar);

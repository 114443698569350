import { GET_CATEGORIES, LOADING_CATEGORY } from "../actions/types";

const categories = (state = { loading: false, categories: [] }, action) => {
  switch (action.type) {
    case GET_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
        loading: false
      };
    case LOADING_CATEGORY:
      return {
        ...state,
        loading: true
      };
    default:
      return state;
  }
};

export default categories;

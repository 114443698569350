import {
  ADD_TO_WISH_LIST,
  ADD_TO_SELECTED_WISH_LIST,
  SELECT_ALL_WISH_LIST,
  REMOVE_SELECTED_WISHLIST,
  SORTING_BY_RATING,
  REMOVE_WISHLIST,
  CLEAR_WISHLIST,
  CLEAR_SELECTED_WISHLIST,
  REMOVE_FROMSELECTED__WISH
} from "../actions/types";
import _ from "lodash";
const INITIAL_STATE = {
  items: {},
  selectedWishList: {},
  selectedAll: false
};

const wishListReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_TO_WISH_LIST:
      const product = action.payload;
      if (state.items[product.id]) {
        return state;
      }
      const store = {
        ...state,
        items: { ...state.items, [action.payload.id]: product },
        selectedAll: false
      };
      return store;
    case ADD_TO_SELECTED_WISH_LIST:
      if (state.selectedWishList[action.payload.id]) {
        const newSelected = _.omit(state.selectedWishList, action.payload.id);
        return {
          ...state,
          items: state.items,
          selectedWishList: newSelected,
          selectedAll: false
        };
      }
      return {
        ...state,
        items: { ...state.items },
        selectedWishList: {
          ...state.selectedWishList,
          [action.payload.id]: action.payload
        }
      };
    // case REMOVE_FROMSELECTED__WISH:

    case SELECT_ALL_WISH_LIST:
      if (!state.selectedAll) {
        return {
          ...state,
          selectedWishList: { ...state.items },
          selectedAll: true
        };
      } else {
        return {
          ...state,
          selectedWishList: {},
          selectedAll: false
        };
      }
    case REMOVE_SELECTED_WISHLIST:
      const newItems = _.omit(state.items, Object.keys(state.selectedWishList));
      const newStore = {
        ...state,
        items: newItems
      };
      return newStore;
    case REMOVE_WISHLIST:
      const afterDelete = _.omit(state.items, action.payload);
      const afterDeleteinSelected = _.omit(
        state.selectedWishList,
        action.payload
      );
      console.log(afterDeleteinSelected);
      console.log(action.payload);
      const newWish = {
        ...state,
        items: afterDelete,
        selectedWishList: afterDeleteinSelected
      };
      return newWish;
    case SORTING_BY_RATING:
      console.log("sort");
      const products = Object.values(state.items);
      const sortedProducts = products.sort((a, b) => {
        return b.rating - a.rating;
      });
      const geting = convertArrayToObject(sortedProducts, "id");
      return {
        ...state,
        items: Object.assign([], geting).reverse()
      };
    case CLEAR_WISHLIST:
      return {
        ...state,
        items: {},
        selectedWishList: {},
        selectedAll: false
      };
    case CLEAR_SELECTED_WISHLIST:
      return {
        ...state,
        selectedWishList: {}
      };
    default:
      return state;
  }
};
const convertArrayToObject = (array, key) => {
  const initialValue = {};
  return array.reduce((obj, item) => {
    return {
      ...obj,
      [item[key]]: item
    };
  }, initialValue);
};
export default wishListReducer;

import React from "react";

function PathComponent(props) {
  return (
    <nav
      aria-label="breadcrumb "
      className="path mt-2 border-top border-bottom "
    >
      <ol className="breadcrumb container  m-auto">{props.children}</ol>
    </nav>
  );
}

export default PathComponent;

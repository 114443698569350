import { SET_LNG } from '../actions/types';
import stings from '../../Localization/LNG';

const lng = (state = stings.getLanguage(), action) => {
    switch (action.type) {
        case SET_LNG:
            return action.payload
        default:
            return state
    }
}

export default lng;
import React from "react";
import { Modal } from "react-bootstrap";
import strings from "../../Localization/LNG";
import ShareButtons from "./ShareButtons";
import { useSelector } from "react-redux";

export default function ShareModal({ isOpen, product, onClose }) {
  const lng = useSelector(state => state.lng);
  return (
    <Modal
      size="sm"
      scrollable={true}
      show={isOpen}
      centered={true}
      dir={`${lng === "arabic" ? "rtl" : "ltr"}`}
      onHide={onClose}
      keyboard={true}
      onEscapeKeyDown={onClose}
      aria-labelledby="example-custom-modal-styling-title"
    >
      <button
        style={{ fontSize: "2rem" }}
        type="button"
        className="close"
        onClick={onClose}
        aria-label="Close"
      >
        <span aria-hidden="true" className="float-right mr-3">
          &times;
        </span>
      </button>
      <div className="text-center">
        <div className="">
          <h4 className="font-weight-bold main-color"> {strings.share} </h4>
        </div>
        <div className="p-3">
          <ShareButtons product={product} />
        </div>
      </div>
    </Modal>
  );
}

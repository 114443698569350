import React from "react";
import strings from "../../Localization/LNG";

const Badge = () => {
  return (
    <p className="text-dark mt-3">
      {strings.product.verifiedCompany}
      <img
        className="img-fluid"
        width="40px"
        src={require("../../assets/icons/verified.svg")}
        alt=""
      />
    </p>
  );
};

export default Badge;

import {
  OPEN_MODAL,
  CLOSE_MODAL,
  LOADING_APP,
  APP_LOADED,
  COLSE_ABUSE_MODAL,
  OPEN_ABUSE_MODAL,
  OPEN_LOGIN_MODAL,
  CLOSE_LOGIN_MODAL,
  OPEN_MAP_MODAL,
  CLOSE_MAP_MODAL,
  USER_FILE_NOT_UPLODED,
  CLOSE_FILE_NOT_UPLODED,
  OPEN_TRADE_ASSRANCE_MODAL,
  CLOSE_TRADE_ASSRANCE_MODAL
} from "./types";

export const openModal = (message, action) => {
  return { type: OPEN_MODAL, payload: message, action };
};

export const closeModal = () => {
  return { type: CLOSE_MODAL };
};

export const loadingApp = () => {
  return { type: LOADING_APP };
};

export const appLoaded = () => {
  return { type: APP_LOADED };
};

export const openAbuseModal = () => {
  return { type: OPEN_ABUSE_MODAL };
};

export const closeAbuseModal = () => {
  return { type: COLSE_ABUSE_MODAL };
};

export const openLoginModal = message => {
  return { type: OPEN_LOGIN_MODAL, payload: message };
};

export const closeLoginMdal = () => {
  return { type: CLOSE_LOGIN_MODAL };
};

export const openMapModal = () => {
  return { type: OPEN_MAP_MODAL };
};

export const closeMapModal = () => {
  return { type: CLOSE_MAP_MODAL };
};

export const CheckNotIpload = () => (dispatch, getState) => {
  const { user } = getState().user;
  if (user && user.docs_uploaded === false) {
    dispatch({ type: USER_FILE_NOT_UPLODED });
  }
};

export const openFileNotUploded = () => (dispatch, getState) => {
  dispatch({ type: USER_FILE_NOT_UPLODED });
};

export const closeFileNotUploded = () => {
  return { type: CLOSE_FILE_NOT_UPLODED };
};

export const openTradeAussurance = () => {
  return { type: OPEN_TRADE_ASSRANCE_MODAL };
};

export const closeTradeAssuranceModal = () => {
  return { type: CLOSE_TRADE_ASSRANCE_MODAL };
};

export const openConsultationModel = location => {
  return { type: "ConsultationModel", payload: location };
};

export const closeconsultationModel = () => {
  return { type: "closeConsultationModel" };
};

export const openTutorialModel = () => {
  return { type: "OPEN_TUTORIAL_MODAL" };
};

export const closeTutorialModal = () => {
  localStorage.setItem("is_app_opens", true);
  return { type: "CLOSE_TUTORIAL" };
};

export const openTutorialSlider = () => {
  return { type: "OPEN_TUTORIAL_SLIDER" };
};

export const closeTutorialSlider = () => {
  return { type: "CLOSE_TUTORIAL_SLIDER" };
};

import React from "react";
import Strings from "../../../Localization/LNG";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { signout } from "../../../store/actions/Login";
import LoginForm from "./LoginForm";
import strings from "../../../Localization/LNG";
import { openLogoutIframe } from "../../../store/actions/Login";

class Login extends React.Component {
  isAuthStateRender = () => {
    if (this.props.isAuth) {
      const token = localStorage.getItem("token")
        ? localStorage.getItem("token").split(" ")[1]
        : this.props.user.token;
      return (
        <React.Fragment>
          <li className="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle text-white"
              href="#"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <img width="20px" src={this.props.user.picture_thumb} alt="" />{" "}
              {this.props.user.name}
              <FontAwesomeIcon
                style={{ fontSize: "10px" }}
                icon={faChevronDown}
                className={`${this.props.lng === "arabic" ? "mr-2" : "ml-2"}`}
              />
            </a>
            <div
              className={`dropdown-menu ${
                this.props.lng === "arabic" ? "text-right" : ""
              } `}
              aria-labelledby="navbarDropdown"
            >
              <a
                target="_blank"
                className="dropdown-item"
                href={
                  this.props.urls
                    ? `${this.props.urls.profile_url}/${token}`
                    : ""
                }
              >
                {strings.login.profile}
              </a>
              <a
                className="dropdown-item"
                onClick={() => {
                  this.props.signout();
                  this.props.openLogoutIframe();
                }}
                href="##"
              >
                {strings.login.logout}
              </a>
            </div>
          </li>
        </React.Fragment>
      );
    } else {
      return (
        <li
          className="nav-item   dropdown"
          style={{ fontWeight: "bold", fontSize: "14px" }}
        >
          <a
            className={`nav-link dropdown-toggle text-white  ${
              this.props.lng === "arabic" ? "ar" : ""
            }`}
            href="#"
            id="navbarDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <img
              height="19px"
              className={` ${this.props.lng === "arabic" ? "ml-1" : "mr-1"}`}
              src={require("../../../assets/icons/user-icon.png")}
              alt=""
            />
            {Strings.nav.auth}
            <FontAwesomeIcon
              style={{ fontSize: "10px" }}
              icon={faChevronDown}
              className={`${this.props.lng === "arabic" ? "mr-2" : "ml-2"}`}
            />
          </a>
          <div
            style={this.props.lng === "arabic" ? ArDrop : EnloginDrop}
            className="dropdown-menu login_drob shadow-lg  bg-white rounded"
            aria-labelledby="navbarDropdown"
          >
            <LoginForm urls={this.props.urls} />
          </div>
        </li>
      );
    }
  };
  render() {
    return <>{this.isAuthStateRender()}</>;
  }
}

function mapStateToProps(state) {
  return {
    error: state.user.error,
    isAuth: state.user.isAuth,
    isLoading: state.user.isLoading,
    lng: state.lng,
    user: state.user.user,
  };
}

const EnloginDrop = {
  width: "23rem",
  position: "absolute",
  left: "-167px",
  border: "none",
};
const ArDrop = {
  width: "23rem",
  position: "absolute",
  border: "none",
};

export default connect(mapStateToProps, { signout, openLogoutIframe })(Login);

import axiso from "axios";

const selectedLng = () => {
  const lng = localStorage.getItem("lng");
  if (lng === "arabic") {
    return "ar";
  } else if (lng === "english") {
    return "en";
  } else if (lng === "french") {
    return "fr";
  } else if (lng === "germany") {
    return "de";
  } else if (lng === "turkey") {
    return "tr";
  } else {
    return lng;
  }
};
// https://back.git-zone.com
// baseURL: "https://git-zone.appssquare.com",

export const BASE_URL = "https://back.git-zone.com"; //"http://127.0.0.1:8000"; //"https://dev-back.git-zone.com"; //"https://dev-back.git-zone.com"; //

const token = localStorage.getItem("token");
const instanse = axiso.create({
  baseURL: BASE_URL,
  headers: {
    "X-Platform": "web",
    "X-Currency": localStorage.getItem("currency") || "USD",
    "X-Language": selectedLng(),
    Authorization: token,
  },
});

instanse.interceptors.response.use((value) => {
  return value;
});

export default instanse;

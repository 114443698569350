const en = {
  nav: {
    home: "Home",
    services: "Services",
    aboutUs: "About Us",
    connectUs: "Contact Us",
    plans: "Subscription Plans",
    abuse: "Report Abuse",
    news: "News",
    languages: {
      arabic: "العربية",
      english: "English",
      turkey: "Türkçe",
      germany: "Deutsch ",
      french: "Français",
    },
    currancy: { dollar: "Dollar", lira: "Lira" },
    auth: "Login / Register",
    login: "Login",
    reqister: "Register",
  },
  searchErrorMessage: "Enter a Text or Upload an Image ",
  categorie: "CATEGORIES",
  search: "Search",
  otherCategories: "Other Categories",
  allCategories: "All Categories",
  share: "Share It ",
  login: {
    login: "Login",
    signup: "Create a New Account",
    email: "Email",
    password: "Password",
    forgetPassword: "Forget Password",
    required: "Required",
    emailNotValid: "Invalid Email Address",
    google: "Google",
    profile: "Profile",
    logout: "Logout",
    loginMessage: "Login to Your Account",
  },
  sectionsTitle: {
    hotDeals: "Hot Deals",
    forLimitTime: "Offers For Limited Time Only !",
    bestSeller: "Best Sellers",
    dealsOf: "Deals Of",
    theMonth: "The Month",
    productsCategories: "Products Categories",
    readyToShip: "Ready to Ship",
    specialItems: "Special Items",
    currancyConventer: "Currency Converter",
  },
  bestQuotationForm: {
    name: "Name",
    phone: "Phone Number",
    country: "country",
    email: "Email",
    bestQuotationTitle: "Next Step? Request Pricing",
    productName: "Product Name",
    unitAmount: "Unit Amount",
    description: "Description",
    addressTo: "Address to",
    hint:
      "Interested in a Product? Fill Out The Form and We Will Send you a Detailed Quotation For The Product You Are Inquiring About.",
    requestPricing: "REQUEST PRICING",
    paymentTerms: "Payment Terms",
    date: "Date",
    submitButtonText: "Request For Quotation",
    Date_per_each_mile_stone: " Date Per Each Mile Stone",
    cachOnDelivery: "Cash on Delivery",
    inAdvance: "In Advance",
    byInstallment: "By Installment",
  },
  brands: { title: "Partners of Success" },
  featurs: {
    importing: {
      title: "Importing",
      text:
        "Save the time and hassle of travelling to multiple countries to source product for your business and start sourcing products from the wholesale market online. GIT-ZONE.com is giving you access to thousands of suppliers, in different locations and a wide-range of categories to import from. ",
    },
    exporting: {
      title: "Exporting",
      text:
        "Give Your Product The Exposure It Deserves And Start Showcasing it Online. Wholesale Online Markets Have a Proven Record of Increasing Profitability and Exposure of Products. In GIT-ZONE.com We Provide You with The platform to Feature Your Products and Easily and Safely Communicate with the Buyers.",
    },
    investment: {
      title: "Investment",
      text:
        "GIT-ZONE.com is The First E-commerce Platform in The Middle East We Are Covering Africa, Europe and Basically Anywhere in The Whole World. So If You Are Eager to Invest in a Certain Business in Any Country we’ll Help you Match Your Idea With The Right Business Plan",
    },
    tradeAssurance: {
      title: "TRADE ASSURANCE",
      text:
        "We Are Bringing TRUST to The Business Again. Whether You Are a Buyer or a Seller we’ll Make the Inspection For You. Our Trade Assurance Service Reduces Fraud in Business Transactions for All Parties Concerned",
    },
  },
  shipmentQuotation: {
    title: "SAVE-UP AND SHIP WITH GIT-ZONE.com!",
    productName: "Product Name",
    categories: "Categories",
    quantity: "Quantity",
    toLocation: "To Location",
    fromLocation: "From Location",
    productLink: "Product URL",
    requestShipment: "REQUEST SHIPEMENT",
    hint:
      "We will Help you To Ship Your Product. Fill Out The Form And We Will Send You The Quotation For Shipment.",
    shippingMethods: {
      shippingMethods: "Shipping Methods",
      landTransportation: "Land Transportation",
      airTransportation: " Air Transportation",
      oceanTransportation: " Ocean Transportation",
    },
    submitButtonText: " Request For Quotation",
  },
  newsLetter: {
    title: "Interested in Special Deals ? Sign Up!",
    form: { email: "Enter Your Email Address", submit: "Submit" },
    keepInTouch: "Stay Updated ! ",
  },
  footer: {
    info:
      "Is The First E-commerce Platform in The Middle East to Bridge The Gap Between The Buyers and Suppliers in Local and Global Markets.",
    company: {
      title: "About GIT-ZONE.com",
      siteMap: "Site Map",
      manual: "Manual",
      tutorial: "Tutorial",
      aboutUs: "About Us",
    },
    policyInfo: {
      title: "GIT-ZONE.com Policy",
      faq: "FAQs",
      privacy: "Privacy Policy",
      terms: "Terms & Conditions",
      plans: "Subscription Plans",
    },
  },
  formValidation: {
    productRequried: "Product Name is Required",
    categoryRequired: "Category Required ",
    quantityRequired: "Quantity Required",
    fromLocationRequired: "From Location Required",
    toLocationRequired: "To Location Required",
    shippingMothodRequired: "Shipping Method Required",
    unitAmountRequired: "Unit Amount Required",
    descriptionRequired: "Description Required",
    addressRequired: "Address Required",
    emailRequired: "Email Address Required",
    emailInvalid: "Email Not Valid",
    passwordRequired: "Password Required",
    paymentTermsRequired: "Payment Required",
    dateRequired: "Date Required",
    productUrlRequired: "Product URL Required",
    urlNotValid: "URL Not Valid",
  },
  conntactus: {
    leaveMessage: "Have a Question? We’ll Help!",
    title:
      "Whether You Need The Support Team or Our Sales Team, We’ll Take Care of Your Request !",
    fristName: "Full Name",
    email: "Email",
    phoneNumber: "Phone Number",
    messageType: "Message Type",
    subject: "Subject",
    yourMessage: "Your Message",
    branch: "Branch",
    address: "Address",
    phone: "Phone",
    messageTypeOp: {
      sales: "Sales",
      markting: "Marketing",
      customerService: "Customer Services",
      operations: "Operations",
    },
    validation: {
      emailRequired: "Email Required",
      fullNameRequire: "Full Name Required",
      emailInvalid: "Email Not Valid",
      phoneNumber: "Phone Required",
      messageType: "Message Type Required",
      subjectRequired: "Subject Required",
      messageRequired: "Message Required",
    },
    submitButton: "Send Message",
    //successMessage: "Your Messge has been sent",
    successMessage: "Your Message Has Been Sent. We’ll Contact You Shortly",
  },
  path: {
    home: "Home",
    news: "News",
    contactUs: "Contact Us",
    aboutUs: " About Us",
    privacyPolicy: "Privacy Policy",
    terms: "Term & Conditions",
    services: "Services",
    checkout: "Checkout",
    search: "Search",
    products: "Products",
    wishlist: "Wish List",
    cart: "Cart",
    faq: "FAQ'S",
    compare: "Compare",
  },
  product: {
    addToCompare: "Add to Compare",
    protectOrder: "Protect Your Orders From Payment to Delivery",
    tradeAssurance: "Trade Assurance",
    startOrder: " START ORDER",
    shipping: "Shipping",
    showCase: "For Product Pricing, Customization, or Other Inquiries:",
    share: "Share",
    shortDescription: "Short Description",
    piecesMinOrder: "(Minimum. Order)",
    pieceMinOrder: "(Minimum. Order)",
    rfqExporter: "RFQ FROM EXPORTER",
    productDetails: "Product Details",
    companyData: "Company Data",
    companyProfile: "Company Profile",
    verifiedCompany: "Verified Company",
    businessType: "Business Type",
    totalEmployees: "Total Employees",
    yearEstablished: "Year Established",
    productCertification: "Product Certification",
    trademarks: "Trademarks",
    productDescription: "Product Description",
    productionCapacity: "Production Capacity",
    qualityControl: "Quality Control",
    RDCapacity: "R&D Capacity",
    tradeCapacity: "Trade Capacity",
    reviews: "Reviews",
    addReview: "ADD A REVIEW",
    reviewShowCase:
      " Your Review Will Be Posted Publicly on The Web. Learn More",
    rating: "Rating",
    yourReview: " Your Review",
    relatedProduct: "Related Products",
    forOnePrice: "Per",
    minPice: " Per Piece",
    stockInAndOut: "Stock In/Out",
    avilable: "Available",
    notAvilable: "Not Available",
    quantity: "Quantity",
    piece: "Piece",
    sample: "Samples :",
    sampleShowCase: " Pieces",
    buySample: "Buy Sample",
    companyOverview: "Company Overview",
    companyName: "Company Name ",
    companyMail: "Company Email",
    companyPhone: "Company Phone",
    companyLocation: "Company Location",
    title: "Title",
    submitReview: "SUBMIT",
    notFound: "Not Found",
    exporterName: "Exporter Name",
    customization: "Customization :",
    customizationLogo: "Logo ",
    free: "Free",
    customizationPackaging: " Packaging ",
  },
  abuseReport: {
    title: "ABUSE REPORT",
    fullname: "Full Name",
    email: "Email",
    phone: "Phone No.",
    order: "Order No.",
    abuseType: "Abuse Type",
    types: {
      importer: "Importer",
      exporter: "Exporter",
      tradeAccurance: "Trade Assurance",
      investment: "Investment",
      shipment: "Shipment",
      others: "Other",
    },
    evidence: "Evidence URL's",
    abuseDetails: "Abuse Details",
    validation: {
      fullName: "Full Name Required",
      orderRquired: "Order Required",
      emailRequired: "Email Required",
      emailNotValid: "Email Not Valid",
      evidenceRequired: "Evidence Required",
      abuseDetailsRequired: "Abuse Details Required",
      phone: "Phone Required",
    },
    submitButton: "SUBMIT",
    /*successMessage: "Your Report Sent Successfully",*/
    successMessage:
      "Your Report Has Been Submitted We’ll look into the case and get back to you shortly",
  },
  popUps: {
    loginSuccess: "You Logged in Successfully Back to Home and Submit",
    submitSuccess:
      "Your Request Has Been Sent The Quotation will be sent your way shortly",
    commingSoon: "COMMING SOON........",
  },
  checkout: {
    billing: "Billing",
    shiping: "Shipping",
    order: "Order",
    payment: "Payment",
    billingDetails: "BILLING DETAILS",
    diffrentAddress: "SHIP TO A DIFFRENT ADDRESS",
    next: "Next",
    fristName: "First Name",
    lastName: "Last Name",
    companyName: "Company Name",
    country: "Country",
    streetAddress: "Street Address",
    apartment: "Apartment, Suite, Unit Etc (Optional) ",
    city: "Town / City",
    state: "State/ Country",
    zib: "Postcode/ Zip",
    orderNote: "Order Note",
    validation: {
      fristName: "First Name Required",
      lastName: "Last Name Required",
      country: "Country Required",
      street: "Street Address Required",
      city: "City Required",
    },
  },
  compare: {
    noProductAdded: "Compare Is Empty",
    hideSameFeatures: "Hide Same Features",
    fillterBy: "Filter By",
    hilightDeifference: "Highlight Difference",
    compareError: "You Can Only Compare Products in The Same Category",
    allFeatures: "ALL FEATURES",
    productFeature: "Product Features",
    exporterFeature: "Exporter Features",
    price: "Price",
    rangePriceFrom: "Range price from",
    rangePriceTo: "Range price to",
    samplePrice: "Sample price",
    minOrderQuentity: "Min order quantity",
    fobPrice: "FOB Price",
    customeOption: "Custom Option",
    rate: "Rate",
    companyName: "Company name",
    exporterTitle: "Exporter Title",
    exporterName: "Exporter Name",
    companyPhone: "Company Phone",
    companyLocation: "Company Location",
    companyMail: "Company Mail",
  },
  cart: {
    productDetails: "Product Details",
    quantity: "Quantity",
    price: "Price",
    samplePrice: "Sample Price",
    total: "Total",
    romove: "REMOVE",
    checkout: "CHECKOUT",
    proceedWithExporter: "PROCEED WITH THE EXPORTER",
    useCoupon: " Use Coupon",
    tradeAccuranceOrder: "Trade Assurance Order",
    peice: "Piece",
    rfqRequest: "RFQ Request",
    withoutShipment: "Without Shipment",
  },
  wishlistP: {
    addToCompare: "Add to Compare",
    selectAll: "Select All",
    contactExporter: "CONTACT EXPORTER",
    defaultSorting: "DEFAULT SORTING",
    sortByRate: "By Rating",
  },
  faq: {
    frequentlyAskedQuestions: "Frequently Asked Questions",
    text:
      "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Odio\n    consequatur aliquam, amet natus dolores ad vel quis, dolorem eius\n    autem voluptatibus necessitatibus ipsa modi consectetur molestiae!\n    Sed nesciunt repellendus unde.",
    qaExporter: "Q&A FOR EXPORTER",
    qaImporter: " Q&A FOR IMPORTETR",
    qaInvestor: "Q&A FOR INVESTOR",
  },
  countinuReading: "Continue Reading",
  or: "or",
  wishlistEmpty: "Wish List Is Empty",
  cartEmpty: "Cart Is Empty",
  ok: "Ok",
  seeMore: "See More ...",
  seeLess: "See Less ...",
  loginFirst: "You Must Login First ",
  addToCart: "ADD TO CART",
  learnmore: "Learn More ",
  signupNow: "Sign Up Now!",
  addedToCart: "ADDED TO CART",
  searchP: {
    filterBy: "FILTER By",
    stockStatus: "STOCK STATUS",
    searchShowCase: "Search Result",
    companys: "Companies",
    for: "for",
    in: "in",
    inStock: "In Stock",
    outStock: "Out Stock",
    rangePrice: "PRICE RANGE",
    quantity: "MINIMUM QUANTITY",
    location: "EXPORTER LOCATION",
    readyToShip: "READY TO SHIP",
    ready: "Ready",
    notReady: "Not Ready",
    less: "Under",
    rate: "Rate",
    above: "Above",
    showing: "SHOWING",
    priceMinOrder: "Piece (Min. Order)",
    of: "OF",
    sorting: {
      default: "DEFAULT SORTING",
      byName: "BY NAME",
      byPrice: "BY PRICE",
      bypopularity: "BY POPULARITY",
      newest: "BY NEWEST",
      bestReview: "BEST REVIEW",
      priceHighToLow: "PRICE HIGH TO LOW",
      priceLowToHigh: "PRICE LOW TO HIGH",
      byRate: "BY RATE",
    },
    products: "PRODUCTS",
    productEmpaty: "No Products Found",
    countrys: {
      egypt: "Egypt",
      china: "China",
      russia: "Russia",
      germany: "Germany",
      other: "Other",
    },
  },
  rfqModal: {
    amount: "Quantity",
    productDescription: "Product Details",
    payment: "No. Of Payments",
    productPercent: "Percent Of Product",
    mony: "Money",
    country: "Country",
    city: "City",
    address: "Address",
    zib: "Postal Code",
    phone: "Phone",
    shippingInfo: "Shipping Info",
    from: "From",
    submitRfq: "SUBMIT RFQ",
    to: "To",
    note: "Notes About Shipment",
    description: "Description",
    typeOfTransportation: " Type Of Transportation",
    paymentType: "Payment Type",
    specialSpecifications: "Special Specifications ",
    validation: {
      amountMustMoreOne: "Quantity Must Be More than 1",
      amount: "Quantity Required",
      productDetails: "Product Details Required",
      payment: "Payment Required",
      productPercent: "Product Percent Required",
      mony: "Money Required",
      address: "Address Required",
      city: "City Required",
      country: "Country Required",
      phone: "Phone Required",
      shippingFromRequired: "Shipping from Required",
      shippingToRequired: "Shipping to Required",
      typeOfTransportationRequired: "Type of Transportation Required",
      paymentType: "Payment Type Required",
    },
  },
  permistionMessages: { mustImporter: "You Must Login as Importer First" },
  plans: {
    plans: "PLANS OF",
    subscription: "SUBSCRIPTION",
    choosePlan: "Choose Plan",
  },
  tradeAssuranceReq: {
    name: "Name",
    email: "Email",
    request: "Request",
    url: "URL",
    phone: "Phone Number",
    joinUs: "Join Us",
    requestQuotation: "Request Quotation",
    reqTradeAssurance: "Request trade assurance",
    validation: {
      phoneRequired: "Phone Required",
      nameRequired: "Name Required",
      emailRequired: "Email Required ",
      emailNotValid: "Email Not Valid",
      request: "Request Required",
      urlNotValid: "URL Not Valid",
    },
  },
  //utils: { thanks: "Thanks" },
  utils: {
    thanks: "Successfully Subscribed! Latest Updates Delivered to Your Inbox ",
  },
  services: {
    importing: "Importing",
    exporting: "Exporting",
    investment: "Investment",
    tradeAssurance: "Trade Assurance",
    showCase: `is designed to help you get your business to the next level. In this section,
    we will help you have a closer look at the services we provide
    through our platform to make wholesome trading easier.
`,
  },
  aboutUs: {
    welcome: "Welcome to GIT-ZONE.com, ",
    showCase: `The first e-commerce platform in the Middle East to bridge the
      gap between the buyers and suppliers not only in local markets,
      but also global ones as well. GIT-ZONE.com platform is designed
      to make wholesome trading easier for all parties concerned, by
      creating a safe, technology-driven, and sustainable business
      environment for buyers, suppliers and investors.`,
    letter: `
      The value proposition of our platform is found in the wide range
                of categories offered at competitive rates, easy communication
                between all parties, a size-doesn’t-matter order policy for
                buyers, and a guaranteed quality of product and service even
                after sale.
      `,
    showCae2: `Our platform is not limited to a certain category or a certain
      country so that our buyers and suppliers can enjoy borderless
      trading. Our categories range from electronics to apparel,
      accessories, agriculture and many more which you can have a
      glimpse on easily from
      `,
    here: "HERE",
    showcae3:
      "If you are a buyer, supplier, or an investor we can definitely help you take your business to the next level.",
    contactUsNow: "CONTACT US NOW!",
    vision: "VISION",
    visionDetails: `Bridging the Gap between buyers and sellers where we offer
    Borderless Trading not only in the Middle East, but also
    extend it to the rest of Africa and the whole world. All of
    which can be done by making wholesome trading easier through
    our user-friendly yet technology-driven platform . `,
    mission: "MISSION",
    missionDetails: `In GIT-ZONE.com we take care of your business transaction from
    the moment you register on our platform. Whether you are a
    supplier, buyer, or an investor our mission is to provide you
    with tools that will ease the trading process and get it done
    timely & efficiently.`,
    values: "VALUES",
    valuesDetails: `We aimed to share our set of values publicly so that we help
    you know more about us and give you a glimpse on how we do our
    business.`,
    quality: "Quality",
    qualityD: `With our Trade Assurance
    service we guarantee you a business transaction with fraud
    prevention and risk reduction.`,
    diversity: "Diversity",
    diversityD: `providing a wide range of
    categories to trade on. All categories are provided in
    competitive offerings at competitive rates for the benefit
    of our users.`,
    passion: "Passion",
    passionD: `We dream big and we are
    taking your business with us!`,
    importing: "IMPORTER",
    importingD: `Save the time and hassle of travelling to multiple countries
    to source product for your business and start sourcing
    products from the wholesale market online. GIT-ZONE.com is
    giving you access to thousands of suppliers, in different
    locations and a wide range of categories to import from. `,
    learnMore: "Learn More",
    tradeAssurance: "TRADE ASSURANCE",
    tradeAssuranceD: ` We are bringing TRUST to the business again. Whether you are
    a buyer or a seller we’ll make the inspection for you. Our
    Trade Assurance Service reduces fraud in business
    transaction/s for all parties concerned.`,
    signUpNow: `Sign Up Now!`,
    exporter: "EXPORTER",
    exporterD: `Give your product the exposure it deserves and start
    showcasing it online. Wholesale online markets have a proven
    record of increasing profitability and exposure of products.
    In GIT-ZONE.com we provide you with a platform to feature
    your product/s and easily and safely communicate with the
    buyers. Have a look on our`,
    investment: "Investment",
    investmentD: `GIT-ZONE.com is the first e-commerce platform in the Middle
    East we are covering Africa, Europe and basically anywhere
    in the whole world. So if you are eager to invest in a
    certain business in any country we’ll help you match your
    idea with the right business plan.`,
  },
  map: {
    capital: "Capital",
    language: "Language",
    area: "Area",
    growthRate: "Growth Rate",
    moreInfo: "More info",
    askforConsultation: "Ask for consultation",
    downloadAsPDF: "Download as PDF",
    submit: "Submit",
    phone: "Phone",
    note: "Note",
    population: "Population",
    currency: "Currency",
  },
  privacyPolicy: {
    _1: {
      title: "GIT-ZONE.com Cares!",
      body: `As one of the main missions of GIT-ZONE.com is to create a safe, technology-driven e-commerce platform for all traders, we are keen on protecting your personal information and securing every step of every business transaction you do on our platform. This section of the platform showcases our privacy policy and further explain our collection of your personal information and how and where it might be used.`,
    },
    _2: {
      title: "What information do we collect & how do we protect it?",
      body_1: `As part of your registration on GIT-ZONE.com, you will be asked to provide certain personal information, such as your name, shipping address, email address and/or telephone number and other similar information. Additionally, in order for us to verify your identity, we may need to request a valid proof of identification, we will also need to collect selected financial information from you, such as your credit card and/or bank account details. We use this financial information for billing purposes. Following your registration on the site, you should not post any personal information (including any financial information) anywhere on the site`,
      body_2: `You are responsible for your own username and password safety and security on GIT-ZONE.com. We recommend choosing a strong password and changing it frequently. Please do not use the same login details (email and password) across multiple websites.`,
      body_3: `That said, we do implement a variety of security measures including offering the use of a secure server. After any transaction, your private information (credit cards, financials, etc.) will not be stored on our servers. Our servers and website are security scanned routinely to protect you online.`,
    },
    _3: {
      title: "Copyright",
      body:
        "All content included on this site in or made available through GIT-ZONE.com portal, such as text, graphics, logos, button icons, images, audio clips, video clips, digital downloads, data compilations, and software, is the property of GIT-ZONE.com or its content suppliers and protected by international copyright laws. The compilation of all content included in or made available through GIT-ZONE.com site is the exclusive property of GIT-ZONE.com and protected by international copyright laws. All software used in any GIT-ZONE.com Service is the property of GIT-ZONE.com or its software suppliers and protected by international copyright laws",
    },
    _4: {
      title: "Third Party Links",
      body: ` We may include or offer third-party products or services on our website. These third-party product/services may be used for shipment of the product/s, payment collection, fraud protection, and/or credit risk reduction. These third-party sites have separate and independent privacy policies. We, therefore, have no responsibility or liability for the content and activities of these linked sites. Nonetheless, we seek to protect the integrity of our site and welcome any feedback about these sites.`,
    },
    _5: {
      title: "Do we use cookies?",
      body_1: `Yes. Cookies are small files that a site or its service provider transfers to your computers hard drive through your Web browser (if you have allowed it via your settings). This enables the sites or service providers systems to recognize your browser and capture and remember certain information.`,
      body_2: `We use cookies to help us remember and process the items in your shopping cart, understand and save your preferences for future visits and compile aggregate data about site traffic and site interaction so that we can offer better site experiences and tools for you in the future. We assure you that our use of cookies does not contain any personal or private details and are free from viruses. If you want to find out more information about cookies, go to `,
      body_3: ` or to find out about removing them from your browser, go to`,
      body_4: `We use Google Analytics, a web analytics service provided by Google, Inc. (“Google”) to help us better understand how users engage with GIT-ZONE.com portal. Google Analytics uses cookies in order to collect information on the usage of our website. This information is used to compile reports and create services to help us improve our website and the services associated with it. The reports disclose website trends without identifying individual visitors. The information generated by the Google cookie about your use of our website (including your IP address) may be transmitted to and stored by Google on servers in the United States. Google may also transfer this information to third parties where required to do so by law, or where such third parties process the information on Google's behalf. Google will not associate your IP address with any other data held by Google.`,
    },
    _6: {
      title: "Reports of Any Abuse on GIT-ZONE.com.",
      body: `Please report problems of any kind or violations of this User Agreement to GIT-ZONE.com. If you believe that your intellectual property rights have been violated, please notify GIT-ZONE.com. Without prejudice to any other rights and remedies of GIT-ZONE.com under this User Agreement or at law, GIT-ZONE.com may limit, suspend or withdraw a user's access to the site and/or a user's membership of the site or remove hosted content. Also, GIT-ZONE.com. can choose to take other technical and/or legal steps against users who create problems or possible legal liabilities of any kind, who infringe the intellectual property rights of third parties or who act inconsistently with this User Agreement or our policies.`,
    },
  },
};

export default en;

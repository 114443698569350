import React from "react";
import { Modal } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { closeFileNotUploded } from "../../store/actions/UiAction";
import { signout } from "../../store/actions/Login";

const IsFileUploded = ({ urls }) => {
  const isFileUploded = useSelector((state) => state.ui.profileError);
  const user = useSelector((state) => state.user.user);
  const dispatch = useDispatch();
  const token = localStorage.getItem("token")
    ? localStorage.getItem("token").split(" ")[1]
    : user
    ? user.token
    : null;
  return (
    <Modal
      show={isFileUploded}
      keyboard={true}
      onEscapeKeyDown={() => dispatch(closeFileNotUploded())}
      onHide={() => dispatch(closeFileNotUploded())}
    >
      <button
        onClick={() => {
          dispatch(closeFileNotUploded());
        }}
        style={{ fontSize: "2rem" }}
        type="button"
        className="close"
        aria-label="Close"
      >
        <span aria-hidden="true" className="float-right mr-3">
          &times;
        </span>
      </button>
      <div className="p-3 text-center">
        <h4> You Need To Upload Your Official Documents First </h4>
        <div className="btn-group p-3">
          <a
            href={`${
              urls ? (urls.profile_url ? urls.profile_url : null) : null
            }/${token}`}
            style={uploadSt}
            className="upload_doc text-white mr-2"
          >
            Upload documents
          </a>
          <button
            onClick={() => {
              dispatch(closeFileNotUploded());
            }}
            style={backButton}
          >
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
};
export const uploadSt = {
  background: "#bd1723",
  color: "white",
  padding: ".5rem 3rem",
  border: "none",
  borderRadius: "6px",
  fontWeight: "bold",
};

export const backButton = {
  cursor: "pointer",
  border: "none",
  padding: "8px",
  borderRadius: "6px",
  padding: ".5rem 3rem",
  color: "white",
  background: "#5B5756",
};
export default IsFileUploded;

import { CHANGE_UNDER_50, CHANGE_FROM_50_TO_100 } from "../actions/types";
import _ from "lodash";
const INITIAL_STATE = {
  priceQuery: null,
  selectedCategory: [],
  price: [],
  quantity: [],
  isClearing: false
};

const filterReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "ON_CHANGE_PRICE":
      return {
        ...state,
        price: [...state.price, action.payload]
      };
    case "ON_REMOVE_PRICE":
      const filterDate = _.filter(
        state.price,
        p => p.from !== action.payload.from
      );
      return {
        ...state,
        price: filterDate
      };
    case "ON_CHANGE_QUANTITY":
      return {
        ...state,
        quantity: [...state.quantity, action.payload]
      };
    case "ON_REMOVE_QUANTITY":
      const filterQuentiry = _.filter(
        state.quantity,
        p => p.from !== action.payload.from
      );
      return {
        ...state,
        quantity: filterQuentiry
      };
    case "ON_SELECT_CATEGORY":
      return {
        ...state,
        selectedCategory: [...state.selectedCategory, action.payload]
      };
    case "ON_DESLETC_CATEGORI":
      const categoiFilter = _.filter(state.selectedCategory, cate => {
        return cate.id !== action.payload;
      });
      return {
        ...state,
        selectedCategory: categoiFilter
      };
    case "_clear_category":
      return {
        ...state,
        selectedCategory: []
      };
    case "ON_CLEAR":
      return {
        ...state,
        isClearing: true
      };
    case "disclear":
      return {
        ...state,
        isClearing: false
      };
    default:
      return state;
  }
};

export default filterReducer;

/*

case CHANGE_UNDER_50:
      return {
        ...state,
        price: {
          ...state.price,
          under50: !state.price.under50
        },
        priceQuery: action.payload
      };
    case CHANGE_FROM_50_TO_100:
      return {
        ...state,
        price: {
          ...state.price,
          from50And100: !state.price.from50And100
        },
        priceQuery: action.payload
      };
    case "dis_100":
      return {
        ...state,
        price: {
          ...state.price,
          from50And100: false
        }
      };
    case "ON_SELECT_CATEGORY":
      return {
        ...state,
        selectedCategory: [{ ...state.selectedCategory, slug: action.payload }]
      };
  */

const INITAL_STATE = {
  urls: {}
};

const URLS_Reducer = (state = INITAL_STATE, action) => {
  switch (action.type) {
    case "GETING_URLS":
      return {
        ...state,
        urls: action.payload
      };
    default:
      return state;
  }
};

export default URLS_Reducer;

import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import currencys from "./CurrencyArray";
import { changeCurrency } from "../../store/actions/currencyAction";
import getSymbolFromCurrency from "currency-symbol-map";

export default function CurrencySelect() {
  const { currency } = useSelector(state => state.currency);
  const lng = useSelector(state => state.lng);
  const dispatch = useDispatch();
  return (
    <li key="i989ew" className="nav-item dropdown">
      <a
        className="nav-link dropdown-toggle text-white"
        href="#"
        id="navbarDropdown"
        role="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <span> {getSymbolFromCurrency(currency.code)} </span>
        {currency.name}
        <FontAwesomeIcon
          style={iconDown}
          icon={faChevronDown}
          className={`${lng === "arabic" ? "mr-2" : "ml-2"}`}
        />
      </a>
      <div
        className={`dropdown-menu ${
          lng.name === "arabic" ? "text-right" : " "
        } `}
        aria-labelledby="navbarDropdown"
      >
        {currencys.map(currency => {
          return (
            <a
              className="dropdown-item"
              href="##"
              onClick={() => {
                dispatch(changeCurrency(currency));
              }}
            >
              {currency.name}
            </a>
          );
        })}
      </div>
    </li>
  );
}
const iconDown = {
  fontSize: "10px"
};

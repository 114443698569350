import React, { useEffect, useState, Suspense } from "react";
import "./App.scss";
import GlobalStyle from "./style/globalStyle";
import TutorialModel from "./TutorialModel/TutorialModel";
import "./responsive.css";
import NavBar from "./Navbar/Navbar";
//import { StripeProvider } from "react-stripe-elements";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
//import { Firstlogin } from "../store/actions/FirstLogin";
import { getProfile } from "../store/actions/Login";
import axios from "../services/axios";
import { getCategories } from "../store/actions/gategoriesActions";
import Modal from "./utils/Modal";
import SearchBar from "./SearchBar/SearchBar";
import { Helmet } from "react-helmet";
import {
  loadingApp,
  appLoaded,
  openTutorialModel,
} from "../store/actions/UiAction";
import ProductDetails from "./ProductDetails/ProductDetails";
import "react-datepicker/dist/react-datepicker.css";

import LogoSpinner from "./utils/LogoSpinner";
import moment from "moment";
import AbuseModal from "./utils/AbuseModal";
import PageNotFound from "./PageNotFound/PageNotFound";
import LoginModal from "./utils/LoginModal";
import MapModal from "./utils/MapModal";
import IsFileUploded from "./utils/isFileUploded";
import { urlsAction } from "../store/actions/URLS_action";
import LogoutIframe from "./utils/LogoutIframe";
import queryString from "query-string";
import ErrorBoundary from "./utils/ErrorBoundry";
import TutorialSlider from "./utils/TutorialSlider";
import strings from "../Localization/LNG";
// import MessengerCustomerChat from "react-messenger-customer-chat";
console.log('app',localStorage.getItem("token"));
const HomePage = React.lazy(() => import("./Home/HomePage"));
const WishList = React.lazy(() => import("./wishlist/Wishlist"));
const Cart = React.lazy(() => import("./Cart/Cart"));
const News = React.lazy(() => import("./News/News"));
const Compare = React.lazy(() => import("./compare/Compare"));
const Search = React.lazy(() => import("./search/Search"));
const AboutUs = React.lazy(() => import("./aboutUs/AboutUs"));
const PrivacyPolicy = React.lazy(() => import("./PrivacyPolicy/PrivacyPolicy"));
const ContactUs = React.lazy(() => import("./ContactUs/ContactUs"));
const FQA = React.lazy(() => import("./FQA/FQA"));
const Checkout = React.lazy(() => import("./checkout/Checkout"));
const Plans = React.lazy(() => import("./Plans/Plans"));
const Terms = React.lazy(() => import("./Terms/Terms"));
const Services = React.lazy(() => import("./ٍServices/Services"));
const Preview = React.lazy(() => import("./Preview/ProductDetails"));
const Confirmation = React.lazy(() => import("./Confirmation/Confirm"));
const Token = React.lazy(() => import("./Token/Token"));
const SiteMap = React.lazy(() => import("./Sitemap/SiteMap"));
const Exporter = React.lazy(() => import("./Exporter/Exporter"));
async function getUrls() {
  const res = await axios.get("/api/greetings");
  return res.data;
}
window.moment = moment;

function App(props) {
  const [urls, setUrl] = useState(null);
  const dispatch = useDispatch();
  const lng = useSelector((state) => state.lng);
  const isAppLoading = useSelector((state) => state.ui.loadingApp);

  // const user = useSelector(state => state.user.user);

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  if (urlParams.has("lng")) {
    const lng = urlParams.get("lng");
    strings.setLanguage(lng);
    localStorage.setItem("lng", lng);
  }

  useEffect(() => {
    async function doWork() {
      dispatch(loadingApp());
      await dispatch(getProfile());
      //await dispatch(Firstlogin());
      await dispatch(getCategories());
      await getUrls().then(async (data) => {
        await dispatch(urlsAction(data.data));
        await setUrl(data.data);
        await dispatch(appLoaded());
        //dispatch(openTutorialModel());
      });
    }
    doWork();
  }, [""]);
  return (
    <React.Fragment>
        <ErrorBoundary>
          <BrowserRouter>
            {isAppLoading ? "" : <NavBar urls={urls} />}
            <Modal />
            <LogoutIframe />
            <AbuseModal />
            <LoginModal />
            <TutorialModel />
            <TutorialSlider />
            <MapModal />
            <IsFileUploded urls={urls} />
            <Switch>
              <div>
                <div className="container">
                  <SearchBar />
                </div>
                <Route
                  exact
                  path="/"
                  render={(props) => (
                    <Suspense fallback={<LogoSpinner />}>
                      <HomePage {...props} />
                    </Suspense>
                  )}
                />
                <Route
                  path="/product/:slug"
                  render={(props) => <ProductDetails {...props} urls={urls} />}
                />
                <Route
                  path="/search/"
                  render={(props) => (
                    <Suspense fallback={<LogoSpinner />}>
                      {" "}
                      <Search {...props} />{" "}
                    </Suspense>
                  )}
                />
                <Route
                  path="/confirm/:slug"
                  render={(props) => (
                    <Suspense fallback={<LogoSpinner />}>
                      <Confirmation {...props} />
                    </Suspense>
                  )}
                />
                <Route
                  path="/token/:code"
                  render={(props) => (
                    <Suspense fallback={<LogoSpinner />}>
                      <Token {...props} />
                    </Suspense>
                  )}
                />
                <Route
                  path="/preview/:slug"
                  render={(props) => (
                    <Suspense fallback={<LogoSpinner />}>
                      <Preview {...props} />
                    </Suspense>
                  )}
                />
                <Route
                  path="/cart"
                  render={(props) => (
                    <Suspense fallback={<LogoSpinner />}>
                      <Cart urls={urls} {...props} />
                    </Suspense>
                  )}
                />
                <Route
                  path="/investor"
                  render={(props) => (
                    <Suspense fallback={<LogoSpinner />}>
                      <Exporter />
                    </Suspense>
                  )}
                />
                <Route
                  path="/wishlist"
                  render={(props) => (
                    <Suspense fallback={<LogoSpinner />}>
                      <WishList {...props} />
                    </Suspense>
                  )}
                />
                <Route
                  path="/news"
                  render={(props) => (
                    <Suspense fallback={<LogoSpinner />}>
                      <News {...props} />
                    </Suspense>
                  )}
                />
                <Route
                  path="/compare"
                  render={(props) => (
                    <Suspense fallback={<LogoSpinner />}>
                      <Compare {...props} />
                    </Suspense>
                  )}
                />
                <Route
                  path="/aboutus"
                  render={(props) => (
                    <Suspense fallback={<LogoSpinner />}>
                      <AboutUs {...props} />
                    </Suspense>
                  )}
                />
                <Route
                  path="/contact-us"
                  render={(props) => (
                    <Suspense fallback={<LogoSpinner />}>
                      <ContactUs {...props} />
                    </Suspense>
                  )}
                />
                <Route
                  path="/home/privacy-policy"
                  render={(props) => (
                    <Suspense fallback={<LogoSpinner />}>
                      <PrivacyPolicy {...props} />
                    </Suspense>
                  )}
                />
                <Route
                  path="/home/faq"
                  render={(props) => (
                    <Suspense fallback={<LogoSpinner />}>
                      <FQA {...props} />
                    </Suspense>
                  )}
                />
                {/* <Route
                  path="/checkout/:slug"
                  render={props => (
                    <Suspense fallback={<LogoSpinner />}>
                      <Checkout urls={urls} {...props} />
                    </Suspense>
                  )}
                /> */}
                <Route
                  path="/terms"
                  render={(props) => (
                    <Suspense fallback={<LogoSpinner />}>
                      <Terms {...props} />
                    </Suspense>
                  )}
                />
                <Route
                  path="/subscription-plans"
                  render={(props) => (
                    <Suspense fallback={<LogoSpinner />}>
                      <Plans {...props} />
                    </Suspense>
                  )}
                />
                <Route
                  path="/services"
                  render={(props) => (
                    <Suspense fallback={<LogoSpinner />}>
                      <Services {...props} />
                    </Suspense>
                  )}
                />
                <Route
                  path="/sitemap"
                  render={(props) => (
                    <Suspense fallback={<LogoSpinner />}>
                      <SiteMap {...props} />
                    </Suspense>
                  )}
                />
              </div>
            </Switch>
            <GlobalStyle ar={lng === "arabic" ? true : false} />
          </BrowserRouter>
        </ErrorBoundary>
    </React.Fragment>
  );
}

export default App;

import React from "react";
import strings from "../../Localization/LNG";

export default function PicesText({ product_unite }) {
  return (
    <span>
      {product_unite
        ? product_unite
        : `${strings.product.forOnePrice} ${strings.product.piece}`}
    </span>
  );
}

import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import axios from "../../../services/axios";
import { openModal } from "../../../store/actions/UiAction";
import "./newsLetter.scss";
import {
  faTwitter,
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faYoutube
} from "@fortawesome/free-brands-svg-icons";
import strings from "../../../Localization/LNG";
function NewsLetterComponent() {
  const dispatch = useDispatch();
  const lng = useSelector(state => state.lng);
  const [email, setEmail] = useState("");
  const [emailerror, setEmailerror] = useState(null);

  return (
    <div
      className="news_letter text-white container"
      dir={`${lng === "arabic" ? "rtl" : "ltr"}`}
    >
      <div className="pt-3 pl-3 pr-3 mt-2">
        <div className="row">
          <div className="col-lg-8 col-md-12">
            <div className="row">
              <FontAwesomeIcon size="2x" icon={faPaperPlane} />
              <div
                className={`col-md-3  news_title ${
                  lng === "arabic" ? "ar" : ""
                }`}
              >
                <p
                  className={`${
                    lng === "arabic" ? "text-right newsLetter-ar" : "text-left"
                  } ${lng === "turkey" || lng === "germany" ? "lower_l" : ""} `}
                >
                  {strings.newsLetter.title}
                </p>
              </div>
              <div className=" col-lg-8 col-sm-12">
                <form
                  onSubmit={e => {
                    e.preventDefault();
                    if (
                      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)
                    ) {
                      return;
                    }
                    axios
                      .post("/api/newsletter", { email: email })
                      .then(res => {
                        console.log(res);
                        dispatch(openModal(strings.utils.thanks));
                        setEmail("");
                      })
                      .catch(e => {
                        setEmailerror(e.response.message);
                      });
                  }}
                  className="w-100 news_letter_form"
                >
                  <div className={`${
                        lng === "turkey" || lng === "germany" ? "input-group news_input_group lower_l" : "input-group news_input_group"
                      }`}>
                    <input
                      type="email"
                      value={email}
                      required
                      onChange={e => setEmail(e.target.value)}
                      placeholder={strings.newsLetter.form.email}
                    />
                    <div className={`${
                        lng === "turkey" || lng === "germany" ? "input-group-prepend h-100 lower_l" : "input-group-prepend h-100"
                      }`}>
                      <button
                        type="submit"
                        className="h-100"
                        style={{ textTransform: "uppercase" }}
                      >
                        {strings.newsLetter.form.submit}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-12 text-center">
            <div className="row socila text-center">
              <p
                className={` ${
                  lng === "germany" ? "lower_l" : lng ==='french'?"":"mt-2"
                }`}
                style={{fontFamily: "gunar-bold",width:'30%'}}
              >
                {strings.newsLetter.keepInTouch}
              </p>
              <div className="ml-1">
                <div className="icon rounded-circle  d-inline-block">
                  <a
                    href="https://www.facebook.com/GITZONEcom/"
                    target="_blank"
                  >
                    <FontAwesomeIcon icon={faFacebookF} />{" "}
                  </a>
                </div>
                <div className="icon rounded-circle  d-inline-block">
                  <a
                    href="https://www.instagram.com/gitzonecom/"
                    target="_blank"
                  >
                    <FontAwesomeIcon icon={faInstagram} />{" "}
                  </a>
                </div>
                <div className="icon rounded-circle  d-inline-block">
                  <a
                    target="_blank"
                    href="https://www.youtube.com/channel/UCqGmkZeYttVrDTmrjQ9iazA"
                  >
                    <FontAwesomeIcon icon={faYoutube} />{" "}
                  </a>
                </div>
                <div className="icon rounded-circle  d-inline-block">
                  <a
                    target="_blank"
                    href="https://www.linkedin.com/company/gitzonecom/"
                  >
                    <FontAwesomeIcon icon={faLinkedinIn} />{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewsLetterComponent;

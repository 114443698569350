import {
  AUTH_ERROR,
  LOGIN_USER,
  LOADING,
  SIGNOUT,
  LOGOUT_IFRAME
} from "../actions/types";

const INITIALSTATE = {
  isAuth: false,
  isLoading: false,
  user: null,
  error: null
};
const user = (state = INITIALSTATE, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return {
        ...state,
        isAuth: true,
        isLoading: false,
        user: action.payload.data,
        error: null
      };
    case LOADING:
      return {
        ...state,
        isAuth: false,
        isLoading: true,
        error: null
      };
    case AUTH_ERROR:
      return {
        ...state,
        isAuth: false,
        error: action.payload,
        isLoading: false
      };
    case SIGNOUT:
      return {
        ...state,
        isAuth: false,
        isLoading: false,
        user: null,
        error: null
      };
    default:
      return state;
  }
};

export default user;

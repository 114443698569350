const currencys = [
  {
    symbol: "$",
    name: "US Dollar",
    symbol_native: "$",
    decimal_digits: 2,
    rounding: 0,
    code: "USD",
    name_plural: "US dollars"
  },
  {
    symbol: "TL",
    name: "Turkish Lira",
    symbol_native: "TL",
    decimal_digits: 2,
    rounding: 0,
    code: "TRY",
    name_plural: "Turkish Lira"
  },
  {
    symbol: "€",
    name: "Euro",
    symbol_native: "€",
    decimal_digits: 2,
    rounding: 0,
    code: "EUR",
    name_plural: "euros"
  },
  {
    symbol: "EGP",
    name: "Egyptian Pound",
    symbol_native: "ج.م.‏",
    decimal_digits: 2,
    rounding: 0,
    code: "EGP",
    name_plural: "Egyptian pounds"
  }
];
export default currencys;

import {
  ADD_TO_CART,
  REMOVE_FROM_CART,
  CHANGE_QUANTITY,
  DECREASE_QUANTITY,
  REMOVE_WISHLIST,
  OPEN_LOGIN_MODAL,
  USER_FILE_NOT_UPLODED,
  OPEN_MODAL
} from "./types";
import strings from "../../Localization/LNG";
import { isImporter } from "../../components/utils/userRole";

export const addToCart = (
  product,
  quentity,
  tradeAccurance,
  fob,
  sample,
  cartRef
) => (dispatch, getState) => {
  // dispatch({ type: OPEN_MODAL, payload: strings.popUps.commingSoon });
  // return;
  const user = getState().user;
  let isEditable;
  if (product.isEditable === false) {
    isEditable = false;
  } else {
    isEditable = true;
  }
  if (!product.price) {
    product.price = null;
  }
  if (!product.rfqId) {
    product.rfqId = null;
  }
  if (!product.milestoneId) {
    product.milestoneId = null;
  }
  product.isEditable = isEditable;
  if (!product.shipment) {
    product.shipment = false;
  }
  if (user.isAuth) {
    const { approved, docs_uploaded } = user.user;
    const roles = isImporter(user.user);
    if (!roles) {
      dispatch({
        type: OPEN_MODAL,
        payload: strings.permistionMessages.mustImporter
      });
      return;
    }
    if (!docs_uploaded) {
      return dispatch({
        type: USER_FILE_NOT_UPLODED
      });
    }
    if (sample) {
      product.isSample = true;
    } else {
      product.isSample = false;
    }
    if (approved === "waiting" || approved === "rejected") {
      return dispatch({
        type: OPEN_MODAL,
        payload:
          approved === "waiting"
            ? "Your account must be approved first"
            : "your registration has been rejected ,Please check your email "
      });
    }
    if (quentity && product.isEditable === true) {
      if (quentity > product.stock) {
        return;
      }
    }
    console.log("ref", cartRef);
    if (
      cartRef &&
      cartRef.current &&
      cartRef.current.classList &&
      !cartRef.current.classList.contains("loading")
    ) {
      cartRef.current.classList.add("loading");
      setTimeout(() => {
        if (cartRef.current) {
          cartRef.current.classList.remove("loading");
        }
      }, 3700);
    }
    console.log("exporter title", product);
    dispatch({
      type: ADD_TO_CART,
      payload: product,
      quentity,
      trade_accurance: tradeAccurance,
      fob: fob
    });
  } else {
    return dispatch({ type: OPEN_LOGIN_MODAL });
  }
  const wishlist = getState().wishlist.items;
  if (wishlist[product.id]) {
    dispatch({ type: REMOVE_WISHLIST, payload: product.id });
  }
};

export const removeFromCart = productId => {
  return { type: REMOVE_FROM_CART, payload: productId };
};

export const onIncreesQuentity = productId => (dispatch, getState) => {
  const cartState = getState().cart;
  const product = cartState.items[productId];
  if (+product.quantity > +product.stock - 1) {
    return;
  }
  if (product.isSample) {
    if (+product.quantity >= +product.sample_quantity) {
      return;
    }
  }
  dispatch({ type: ADD_TO_CART, payload: product });
};

export const onChangeQuantity = (product, quantity) => dispatch => {
  if (quantity) {
    if (+quantity > +product.stock) {
      return;
    }
  }

  dispatch({
    type: CHANGE_QUANTITY,
    quantity: quantity < 1 ? 1 : +quantity,
    payload: product
  });
};

export const decreaseQuantity = product => (dispatch, getState) => {
  const selectedProduct = getState().cart.items[product.id];
  if (+selectedProduct.quantity < +selectedProduct.minimum_quantity + 1) {
    return;
  }
  dispatch({ type: DECREASE_QUANTITY, payload: product });
};

export const addAllToCart = () => (dispatch, getState) => {
  const state = getState().wishlist.selectedWishList;
  const newSate = Object.values(state);
  newSate.forEach(e => {
    dispatch({ type: ADD_TO_CART, payload: e });
    dispatch({ type: REMOVE_WISHLIST, payload: e.id });
  });
};

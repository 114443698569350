import React, { useState } from 'react';
import { connect } from 'react-redux';
import { login } from '../../../store/actions/Login';
import { Formik } from 'formik';
import axios from '../../../services/axios';
import { GoogleLogin } from 'react-google-login';
import { openModal } from '../../../store/actions/UiAction';
import Strings from '../../../Localization/LNG';
import { useDispatch, useSelector } from 'react-redux';
import { getProfile } from '../../../store/actions/Login';
import Iframe from './Iframe';
import strings from '../../../Localization/LNG';
import * as Yup from 'yup';
const validation = Yup.object().shape({
  email: Yup.string()
    .required(Strings.formValidation.emailRequired)
    .email(Strings.formValidation.emailInvalid),
  password: Yup.string().required(Strings.formValidation.passwordRequired),
});

function LoginForm(props) {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.user.isLoading);
  const authError = useSelector((state) => state.user.error);
  const [openIframe, setIframe] = useState(false);
  const lng = useSelector((state) => state.lng);
  const [token, setToken] = useState(null);
  const { urls } = useSelector(state => state.urls);
  //console.log('=========Manf===========',urls.register_url);
  const onGoogleLoginSuccess = async (res) => {
    const user = {
      type: 'google',
      id: res.googleId,
      name: res.profileObj.name,
      email: res.profileObj.email,
      image: res.profileObj.imageUrl,
    };
    try {
      const response = await axios.post('/api/user/socialLogin', user);
      if (response.data.data.confirmed) {
        localStorage.setItem('token', `Bearer ${response.data.data.token}`);
        localStorage.setItem('user', JSON.stringify(response.data.data));
        dispatch(getProfile());
      }
      setToken(response.data.data.token);
      setIframe(true);
    } catch (error) {
      dispatch(openModal('A confirmation E-mail is sent to your mailbox. Please click the link in the email.'));
    }
  };
  return (
    <div className='p-3' dir={`${lng === 'arabic' ? 'rtl' : 'ltr'}`}>
      {openIframe && <Iframe url={`${props.urls.register_url}/${token}`} />}
      <Formik
        initialValues={{ email: '', password: '' }}
        onSubmit={async (values, { setSubmitting }) => {
          await props.login(values);
        }}
        validationSchema={validation}
      >
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          touched,
          errors,
        }) => (
          <form className='text-center' onSubmit={handleSubmit}>
            <div style={{ color: '#A10001' }} className='errorMessage'>
              {authError ? authError : null}
            </div>
            <div className='error'>
              {errors.email && touched.email && errors.email}
            </div>
            <input
              type='email'
              onBlur={handleBlur}
              onChange={handleChange}
              name='email'
              value={values.email}
              style={loginFormStyle}
              placeholder={Strings.login.email}
            />
            <div className='error'>
              {errors.password && touched.password && errors.password}
            </div>
            <input
              type='password'
              className='mt-2'
              onBlur={handleBlur}
              onChange={handleChange}
              name='password'
              value={values.password}
              placeholder={Strings.login.password}
              style={loginFormStyle}
            />
            <div className='login_btn mt-3 w-100'>
              <button disabled={isLoading} type='submit' style={signButn}>
                {isLoading ? (
                  <>
                    <div
                      className='spinner-border spinner-border-sm'
                      role='status'
                    >
                      <span className='sr-only'>Loading...</span>
                    </div>
                  </>
                ) : (
                  Strings.login.login
                )}
              </button>
              <span className='ml-3'> {strings.or} </span>
              <GoogleLogin
                style={{ width: '8rem', marginLeft: '2rem', height: '2.7rem' }}
                className='text-center ml-3 google_login'
                clientId='155348214126-tb9ld95tfc86kucmt21ft9olb2mm4sbp.apps.googleusercontent.com'
                buttonText={Strings.login.google}
                onSuccess={onGoogleLoginSuccess}
                onFailure={(res) => console.log('error', res)}
                cookiePolicy={'single_host_origin'}
              />
            </div>
            <div className='mt-2' style={{ fontWeight: 'bold' }}>
              <a
                target='_blank'
                style={{
                  color: '#BD1723',
                  textDecoration: 'underline',
                  fontSize: '14px',
                }}
                href={urls ? urls.forgetpassword_url : '#'}
              >
                {Strings.login.forgetPassword}a
              </a>
              <div className='text-center'>
                <a
                  target='_blank'
                  style={{
                    color: '#BD1723',
                    textDecoration: 'underline',
                    fontSize: '14px',
                  }}
                  className='mt-2'
                  href={urls ? urls.register_url : '#'}
                >
                  {Strings.login.signup}
                </a>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    isLoading: state.user.isLoading,
  };
}

const loginFormStyle = {
  width: '100%',
  background: '#EEEEEE',
  border: 'none',
  padding: '.5rem',
  borderRadius: '.3rem',
  outline: 'none',
};

const signButn = {
  border: 'none',
  height: '2.7rem',
  color: 'white',
  background: '#BD1723',
  width: '7rem',
  borderRadius: '4px',
};

export default connect(mapStateToProps, { login })(LoginForm);

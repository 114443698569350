const de = {
  nav: {
    home: "Startseite",
    services: "Dienstleistungen",
    aboutUs: "Über uns",
    connectUs: "Kontaktieren Sie uns",
    news: "Neuigkeiten",
    abuse: "Missbrauch melden",
    plans: " Abo Pläne",
    news: "Neuigkeiten",
    auth: {
      auth: "Anmelden Registrieren",
      login: "Einloggen",
      reqister: "Registrieren",
    },
    languages: {
      arabic: "العربية",
      english: "English",
      turkey: "Türkçe",
      germany: "Deutsch ",
      french: "Français",
    },
    currancy: { dollar: "Dollar", lira: "Lira" },
    auth: "Einloggen /Registrieren",
    login: "Einloggen",
    reqister: "Registrieren",
  },
  searchErrorMessage: "Geben Sie einen Text ein oder laden Sie ein Bild hoch",
  categorie: "KATEGORIEN",
  search: "Suche",
  otherCategories: "Andere Kategorien",
  allCategories: "Alle Kategorien",
  share: "Teilen",
  login: {
    login: "Einloggen",
    signup: "Neues Konto erstellen",
    email: "Email",
    password: "Passwort",
    forgetPassword: "Passwort vergessen",
    required: "Erforderlich",
    emailNotValid: "Ungültige E-Mail-Adresse",
    google: "Google",
    profile: "Profil",
    logout: "Ausloggen",
    loginMessage: "Melde dich in deinem Konto an",
  },
  sectionsTitle: {
    hotDeals: "heiße Angebote",
    forLimitTime: "Angebote nur für begrenzte Zeit!",
    bestSeller: "Bestseller",
    dealsOf: "Angebote von",
    theMonth: "der Monat",
    productsCategories: "Produktkategorien",
    readyToShip: "Versandfertig",
    specialItems: "Besondere Artikel",
    currancyConventer: "Währungsrechner",
  },
  bestQuotationForm: {
    bestQuotationTitle: "Nächste Schritte? Preisliste anfordern",
    productName: "Produktname",
    name: "Naam",
    phone: "Telefoonnummer",
    country: "land",
    email: "E-mail",
    unitAmount: "Stückbetrag",
    description: "Beschreibung",
    addressTo: "richten an",
    paymentTerms: "Zahlungsbedingungen",
    submitButtonText: "Angebotsanfrage",
    date: "Datum",
    hint:
      "An einem Produkt interessiert? Füllen Sie das Formular aus und wir senden Ihnen ein ausführliches Preisangebot für das Produkt, nach dem Sie fragen.",
    Date_per_each_mile_stone: "Datum pro Meilenstein",
    cachOnDelivery: "Barzahlung bei Lieferung",
    inAdvance: "Im Voraus",
    requestPricing: "PREISE ANFORDERN",
    byInstallment: "In Raten",
  },
  brands: { title: "Die richtigen Partner für den Erfolg" },
  featurs: {
    importing: {
      title: "Import",
      text:
        "Sparen Sie sich die Zeit und den Aufwand in mehrere Länder zu reisen, um Produkte für Ihr Unternehmen zu entdecken und starten Sie im Online Großhandelsmarkt von GIT-ZONE.com, Produkte zu entdecken. GIT-ZONE.com gewährt Ihnen Zugriff zu tausenden von Lieferanten, in verschiedenen Orten und gewährt eine große Anzahl an Kategorien von denen Sie importieren können. ",
    },
    exporting: {
      title: "Export",
      text:
        "Geben Sie Ihrem Produkt die Aufmerksamkeit, die es verdient, und präsentieren Sie es online. Online-Großhandelsmärkte haben nachweislich eine steigende Rentabilität und Exposition von Produkten bewiesen. Mit GIT-ZONE.com bieten wir Ihnen eine Plattform, auf der Sie Ihre Produkte präsentieren und einfach und sicher mit den Käufern kommunizieren können. Schauen Sie sich unsere.",
    },
    investment: {
      title: "Investition",
      text:
        "GIT-ZONE.com ist die erste E-Commerce-Plattform im Nahen Osten. Wir decken Afrika, Europa und bzw. überall auf der Welt ab. Wenn Sie also in einem Land investieren möchten, helfen wir Ihnen, Ihre Idee mit dem richtigen Geschäftsplan abzustimmen.",
    },
    tradeAssurance: {
      title: "Handelsabsicherung",
      text:
        "Wir bringen wieder das Vertrauen ins Geschäft. Unabhängig davon, ob Sie Käufer oder Verkäufer sind, führen wir die Inspektion für Sie durch. Unser Handelsabsicherungsdienst reduziert den Betrug im Geschäftsverkehr für alle Beteiligten.",
    },
  },
  shipmentQuotation: {
    title: "Jetzt sparen und mit GIT-ZONE.com liefern",
    productName: "Produktname",
    categories: "Kategorien",
    quantity: "Menge",
    toLocation: "Zum Standort",
    fromLocation: "Vom Standort",
    productLink: "Produkt-URL",
    requestShipment: "VERSAND ANFORDERN",
    hint:
      "Wir helfen Ihnen beim Versand Ihres Produkts. Füllen Sie das Formular aus und wir senden Ihnen ein Preisangebot für den Versand. ",
    shippingMethods: {
      shippingMethods: "Versandarten",
      landTransportation: "Landtransport",
      airTransportation: "Lufttransport",
      oceanTransportation: "ozeanransport",
    },
    submitButtonText: "Angebotsanfrage",
  },
  newsLetter: {
    title: "Sonderangeboten? Anmelden!",
    form: { email: "Geben sie ihre E-Mailadresse ein", submit: "einreichen" },
    keepInTouch: "Bleiben Sie auf dem Laufenden!",
  },
  footer: {
    info:
      "ist die erste E-Commerce-Plattform im Nahen Osten, die die Kluft zwischen Käufern und Lieferanten auf lokalen und globalen Märkten überbrückt.",
    company: {
      title: "UNTERNEHMEN",
      siteMap: "Seitenverzeichnis",
      manual: "Handbuch",
      tutorial: "Tutorial",
      aboutUs: "Über uns ",
    },
    policyInfo: {
      title: "RICHTLINIEN",
      faq: "Häufig gestellte Fragen",
      privacy: "Datenschutzbestimmungen",
      terms: "Terms & amp; Bedingungen",
      plans: "Abo Pläne",
    },
  },
  formValidation: {
    productRequried: "Produktname ist erforderlich",
    categoryRequired: "Kategorie erforderlich",
    quantityRequired: "Erforderliche Menge",
    fromLocationRequired: "Vom Standort erforderlich",
    toLocationRequired: "Zum Standort erforderlich",
    shippingMothodRequired: "Versandart erforderlich",
    unitAmountRequired: "Erforderliche Stückmenge",
    descriptionRequired: "Beschreibung Erforderlich",
    addressRequired: "Adresse erforderlich",
    emailRequired: "E-Mail-Adresse: (Pflichtfeld)",
    emailInvalid: "E-Mail nicht gültig",
    passwordRequired: "Passwort erforderlich",
    paymentTermsRequired: "Zahlung erforderlich",
    dateRequired: "Datum erforderlich",
    productUrlRequired: "Produkt-URL erforderlich",
    urlNotValid: "URL nicht gültig",
  },
  conntactus: {
    leaveMessage: "Sie haben eine Frage? Wir helfen!",
    title:
      "Egal, ob Sie das Support-Team oder unser Verkaufsteam benötigen, wir kümmern uns um Ihre Anfrage!",
    fristName: "Vorname",
    email: "Email",
    phoneNumber: "Telefonnummer",
    messageType: "Nachrichtentyp",
    subject: "Gegenstand",
    yourMessage: "Ihre Nachricht",
    branch: "Ast",
    address: "Adresse",
    phone: "Telefon",
    messageTypeOp: {
      sales: "Der Umsatz",
      markting: "Marketing",
      customerService: "Kundenservice",
      operations: "Operationen",
    },
    validation: {
      emailRequired: "Email (erforderlich)",
      fullNameRequire: "Vollständiger Name erforderlich",
      emailInvalid: "E-Mail nicht gültig",
      phoneNumber: "Telefon erforderlich",
      messageType: "Nachrichtentyp erforderlich",
      subjectRequired: "Betreff (erforderlich)",
      messageRequired: "Nachricht benötigt",
    },
    submitButton: "Nachricht senden",
    successMessage:
      "Ihre Nachricht wurde gesendet Wir werden uns in Kürze bei Ihnen melden",
  },
  path: {
    home: "Startseite",
    news: "Neuigkeiten",
    contactUs: "Kontaktieren Sie uns",
    aboutUs: " Über uns",
    privacyPolicy: "Datenschutz-Bestimmungen",
    terms: "Term & Conditions",
    services: "Dienstleistungen",
    checkout: "Checkout",
    search: "Suche",
    products: "Produkte",
    wishlist: "Wunschzettel",
    cart: "Einkaufswagen",
    faq: "Häufig gestellte Fragen",
    compare: "Vergleichen Sie",
  },
  product: {
    addToCompare: "Hinzufügen zum vergleichen",
    protectOrder:
      "Schützen Sie Ihre Bestellungen von der Zahlung bis zur Lieferung",
    tradeAssurance: "Handelssicherung",
    startOrder: "BESTELLUNG STARTEN",
    shipping: "Versand",
    showCase: "Für Produktpreise, Anpassungen oder andere Anfragen:",
    share: "Teilen",
    shortDescription: "kurze Beschreibung",
    piecesMinOrder: "Stück (min. Bestellung)",
    pieceMinOrder: "Stück (min. Bestellung)",
    rfqExporter: "RFQ VOM EXPORTER",
    productDetails: "Produktdetails",
    companyData: "Firmen Daten",
    companyProfile: "Firmenprofil",
    verifiedCompany: "Verifiziertes Unternehmen",
    businessType: "Unternehmensart",
    totalEmployees: "Mitarbeiter insgesamt",
    yearEstablished: "Gründungsjahr",
    productCertification: "Produktzertifizierung",
    trademarks: "Warenzeichen",
    productDescription: "Produktbeschreibung",
    productionCapacity: "Produktionskapazität",
    qualityControl: "Qualitätskontrolle",
    RDCapacity: "F & E-Kapazität",
    tradeCapacity: "Handelskapazität",
    reviews: "Bewertungen",
    addReview: "BEWERTUNG HINZUFÜGEN",
    reviewShowCase:
      "Ihre Bewertung wird im Internet veröffentlicht. erfahren Sie mehr",
    rating: "Bewertung",
    yourReview: "Deine Bewertung",
    relatedProduct: "Verwandte Produkte",
    forOnePrice: "Pro Stück",
    minPice: "Pro Stück",
    stockInAndOut: "Lager innen/ aussen",
    avilable: "Verfügbar",
    notAvilable: "Nicht verfügbar",
    quantity: "Menge",
    piece: "Stück",
    sample: "Proben :",
    sampleShowCase: "Stücke",
    buySample: "Probe kaufen",
    companyOverview: "Unternehmensüberblick",
    companyName: "Name der Firma",
    companyMail: "Firmen-E-Mail",
    companyPhone: "Firmentelefon",
    companyLocation: "Firmenstandort",
    title: "Titel",
    submitReview: "EINREICHEN",
    notFound: "Not Found",
    exporterName: "Name des Exporteurs",
    customization: "Anpassung :",
    customizationLogo: "Logo ",
    free: "Kostenlos",
    customizationPackaging: "Verpackung",
  },
  abuseReport: {
    title: "Missbrauchsbericht",
    fullname: "Vollständiger Name",
    email: "Email",
    phone: "Telefonnummer.",
    order: "Best.-Nr.",
    abuseType: "Missbrauchstyp",
    types: {
      importer: "Importer",
      exporter: "Exporteur",
      tradeAccurance: "Handelssicherung",
      investment: "Investition",
      shipment: "Sendung",
      others: "Andere",
    },
    evidence: "Beweis Urls",
    abuseDetails: "Missbrauch Details",
    validation: {
      fullName: "Full Name Required",
      orderRquired: "Order Required",
      emailRequired: "Email erforderlich",
      emailNotValid: "Email ungültig",
      evidenceRequired: "Evidence Required",
      abuseDetailsRequired: "Missbrauchsdetails erforderlich",
      phone: "Telefon erforderlich",
    },
    submitButton: "EINREICHEN",
    successMessage:
      "Ihr Bericht wurde übermittelt. Wir werden den Fall untersuchen und in Kürze auf Sie zurückkommen",
  },
  Popups: {
    loginSuccess: "Anmeldung erfolgreich",
    submitSuccess:
      "Ihre Anfrage wurde gesendet Das Angebot wird Ihnen in Kürze zugesendet.",
    commingSoon: "KOMMT BALD........",
  },
  checkout: {
    billing: "Rechnung",
    shiping: "Versand",
    order: "Bestellung",
    payment: "PaymZahlungent",
    billingDetails: "Rechnungsdetails",
    diffrentAddress: "Versand zu einer anderen Adresse",
    next: "weiter",
    fristName: "Vorname",
    lastName: "Nachname",
    companyName: "Name Unternehmen",
    country: "Land",
    streetAddress: "Adresse",
    apartment: "Wohnungsnummer optional",
    city: "Stadt",
    state: "Bundesland",
    zib: "Postleitzahl",
    orderNote: "Bemerkung zur Bestellung",
    validation: {
      fristName: "Vorname erforderlich",
      lastName: "Nachname erforderlich",
      country: "Land erforderlich",
      street: "Adresse erforderlich",
      city: "Stadt erforderlich",
    },
  },
  compare: {
    noProductAdded: "keine Produkte hinzugefügt",
    hideSameFeatures: "Gleiche Funktionen ausblenden",
    fillterBy: "Filtern nach",
    hilightDeifference: "Markieren Sie den Unterschied",
    compareError: "Sie können nur Produkte derselben Kategorie vergleichen",
    allFeatures: "ALLE FEATURES",
    productFeature: "Produktmerkmale",
    exporterFeature: "Exporter-Funktionen",
    price: "Preis",
    rangePriceFrom: "Bereichspreis ab",
    rangePriceTo: "Bereichspreis bis",
    samplePrice: "Beispielpreis",
    minOrderQuentity: "Minimale Bestellmenge",
    fobPrice: "FOB Preis",
    customeOption: "Benutzerdefinierte Option",
    rate: "Bewertung",
    companyName: "Name der Firma",
    exporterTitle: "Exporteur Titel",
    exporterName: "Name des Exporteurs",
    companyPhone: "Firmentelefon",
    companyLocation: "Firmentelefon",
    companyMail: "Firmenmail",
  },
  cart: {
    productDetails: "Produktdetails",
    quantity: "Menge",
    price: "Preis",
    samplePrice: "Beispielpreis",
    total: "Gesamt",
    romove: "ENTFERNEN",
    checkout: "AUSCHECKEN",
    proceedWithExporter: "VERFAHREN MIT DEM EXPORTER",
    useCoupon: "Gutschein verwenden",
    tradeAccuranceOrder: "Trade Assurance Order",
    peice: "Stück",
    rfqRequest: "RFQ-Anfrage",
    withoutShipment: "ohne Versand",
  },
  wishlistP: {
    addToCompare: "Hinzufügen zum vergleichen",
    selectAll: "Wählen Sie Alle",
    contactExporter: "KONTAKTIEREN SIE DEN EXPORTER",
    defaultSorting: "STANDARD-SORTIERUNG",
    sortByRate: "Nach Bewertung",
  },
  faq: {
    frequentlyAskedQuestions: "Häufig gestellte Fragen",
    text: ``,
    qaExporter: "Häufig gestellte Fragen-Exporteure",
    qaImporter: "Häufig gestellte Fragen-Importeure",
    qaInvestor: "Häufig gestellte Fragen-Investition",
  },
  countinuReading: "Weiterlesen",
  or: "or",
  wishlistEmpty: "Wunschliste ist leer",
  cartEmpty: " Warenkorb ist leer",
  ok: "Ok",
  seeMore: "Mehr sehen ...",
  seeLess: "Weniger sehen ...",
  loginFirst: "Du musst dich zuerst einloggen ",
  addToCart: "IN DEN WARENKORB LEGEN",
  learnmore: " Erfahren Sie mehr",
  signupNow: "Jetzt registrieren!",
  addedToCart: "Zum Warenkorb hinzugefügt	",
  searchP: {
    filterBy: "Filtern nach",
    stockStatus: "LAGERBESTAND",
    searchShowCase: "Suchergebnis",
    for: "zum",
    in: "im",
    inStock: "Auf Lager",
    companys: "Bedrijven",

    outStock: "Ausverkauft",
    rangePrice: "PREISKLASSE",
    quantity: "MINDESTENS HINZUFÜGEN",
    location: "EXPORTER LOCATION",
    readyToShip: "VERSANDFERTIG",
    ready: "Bereit",
    notReady: "Nicht bereit",
    less: "Unter",
    rate: "Bewertung",
    above: "Über",
    showing: "ZEIGEN",
    priceMinOrder: "Stück (min. Bestellung)",
    of: "VON",
    sorting: {
      default: "STANDARD-SORTIERUNG",
      byName: "NAMENTLICH",
      byPrice: "NACH PREIS",
      bypopularity: "NACH BEVÖLKERUNG",
      newest: "VON NEUESTEN",
      bestReview: "BESTE ÜBERPRÜFUNG",
      priceHighToLow: "Preis hoch bis niedrig",
      priceLowToHigh: "PREIS NIEDRIG BIS HOCH",
      byRate: "NACH RATE",
    },
    products: "Produkte",
    productEmpaty: "Keine Produkte gefunden",
    countrys: {
      egypt: "Ägypten",
      china: "China",
      russia: "Russland",
      germany: "Deutschland",
      other: "Andere",
    },
  },
  rfqModal: {
    amount: "Menge",
    productDescription: "Produktdetails",
    payment: "Anzahl der Zahlungen",
    productPercent: "Prozent des Produkts",
    mony: "Geld",
    country: "Land",
    city: "Stadt",
    address: "Adresse",
    zib: "Postleitzahl",
    phone: "Telefon",
    shippingInfo: "Versandinformation",
    from: "Von",
    submitRfq: "RFQ EINREICHEN",
    to: "Zu",
    note: "Hinweise zum Versand",
    description: "Beschreibung",
    typeOfTransportation: "Art des Transports",
    paymentType: "Zahlungsart",
    specialSpecifications: "Spezielle Spezifikationen",
    validation: {
      amountMustMoreOne: "Der Betrag muss mehr als 1 betragen",
      amount: "Benötigte Menge",
      productDetails: "Produktdetails erforderlich",
      payment: "Zahlung erforderlich",
      productPercent: "Produktprozentsatz erforderlich",
      mony: "Geld erforderlich",
      address: "Adresse erforderlich",
      city: "Stadt erforderlich",
      country: "Land erforderlich",
      phone: "Telefon erforderlich",
      shippingFromRequired: "Versand ab Erforderlich",
      shippingToRequired: "Versand nach Erforderlich",
      typeOfTransportationRequired: "Art des Transports erforderlich",
      paymentType: "Zahlungsart erforderlich",
    },
  },
  permistionMessages: {
    mustImporter: "Sie müssen sich zuerst als Importeur anmelden",
  },
  plans: {
    plans: "Abo Pläne",
    subscription: "",
    choosePlan: "Wählen Sie einen Plan",
  },
  tradeAssuranceReq: {
    name: "Name",
    email: "Email",
    request: "Anfrage",
    url: "Url",
    phone: "Telefonnummer",
    joinUs: "Begleiten Sie uns",
    requestQuotation: "Vraag een offerte aan",
    reqTradeAssurance: "Vraag handelsgarantie aan",
    validation: {
      phoneRequired: "Telefon erforderlich",
      nameRequired: "Name erforderlich",
      emailRequired: "Email (erforderlich) ",
      emailNotValid: "E-Mail nicht gültig",
      request: "Anfrage erforderlich",
      urlNotValid: "URL nicht gültig",
    },
  },
  //utils: { thanks: "Vielen Dank" },
  utils: {
    thanks:
      "Ihre Nachricht wurde gesendet Wir werden uns in Kürze bei Ihnen melden",
  },
  services: {
    importing: "Importieren",
    exporting: "Exportieren",
    investment: "Investition",
    tradeAssurance: "Handelssicherung",
    showCase: `GIT-ZONE.com soll Ihnen dabei helfen, Ihr Unternehmen auf das nächste Level  zu bringen. In diesem Abschnitt helfen wir Ihnen, die Dienstleistungen, die wir in unserer Plattform anbieten, genauer zu betrachten, um Ihnen den vorteilhaften Handel zu vereinfachen.																									
`,
  },
  aboutUs: {
    welcome: "Willkommen bei GIT-ZONE.com, ",
    showCase: `Die erste E-Commerce-Plattform im Nahen Osten, die die Lücke zwischen Käufern und Lieferanten nicht nur auf lokalen, sondern auch auf globalen Märkten schließt. Die Plattform GIT-ZONE.com soll allen Beteiligten den vorteilhaften Handel erleichtern, indem ein sicheres, technologiebetriebenes und nachhaltiges Geschäftsumfeld für Käufer, Lieferanten und Investoren geschaffen wird.`,
    letter: `																									
      Das Wertversprechen unserer Plattform liegt in der Vielzahl der Kategorien, die zu wettbewerbsfähigen Preisen angeboten werden, in der einfachen Kommunikation zwischen allen Parteien und mit garantierten Qualität von Produkt und Service, und das auch nach dem Verkauf.																									
      `,
    showCae2: `Unsere Plattform ist nicht auf eine bestimmte Kategorie oder ein bestimmtes Land beschränkt, sodass unsere Käufer und Lieferanten grenzenlosen Handel genießen können. Unsere Kategorien reichen von Elektronik über Bekleidung, Accessoires, Landwirtschaft und vieles mehr, die Sie																									
      `,
    here: "HIER",
    showcae3:
      "ganz einfach einsehen können.Wenn Sie Käufer, Lieferant oder Investor sind, können wir Ihnen auf jeden Fall helfen, Ihr Unternehmen auf die nächste Stufe zu bringen.",
    contactUsNow: "KONTAKTIEREN SIE UNS JETZT!",
    vision: "Vision",
    visionDetails: `Zur Überbrückung der Lücke zwischen Käufern und Verkäufern, wo wir grenzenloses Handeln nicht nur im Nahen Osten anbieten, sondern auch auf den Rest von Afrika und der ganzen Welt ausdehnen.																									
All dies kann erreicht werden, indem der bekömmliche Handel über unsere benutzerfreundliche und dennoch technologiegetriebene Plattform vereinfacht wird.																									
 `,
    mission: "Mission",
    missionDetails: `In GIT-ZONE.com kümmern wir uns ab dem Moment Ihrer Registrierung auf unserer Plattform, um Ihren Geschäftsvorgang. Unabhängig davon, ob Sie Lieferant, Käufer oder Investor sind, ist es unsere Mission, Ihnen Werkzeuge zur Verfügung zu stellen, die den Handelsprozess vereinfachen und zeitnah und effizient erledigen.`,
    values: "Werte",
    valuesDetails: `Wir wollen unsere Werte öffentlich teilen, damit wir Ihnen helfen, mehr über uns zu erfahren und Ihnen einen Einblick in unsere Geschäftstätigkeit zu geben.`,
    quality: "Qualität",
    qualityD: `Mit unserem Trade Assurance-Service garantieren wir Ihnen einen Geschäftsvorgang mit Betrugsprävention und Risikominderung.`,
    diversity: "Vielfalt",
    diversityD: `bietet eine breite Palette von Kategorien für den Handel. Alle Kategorien werden in wettbewerbsfähigen Angeboten, zu wettbewerbsfähigen Preisen zum Nutzen unserer Benutzer angeboten.`,
    passion: "Leidenschaft",
    passionD: `Wir träumen groß und nehmen Ihr Geschäft mit!`,
    importing: "Import",
    importingD: `Sparen Sie sich die Zeit und den Aufwand in mehrere Länder zu reisen, um Produkte für Ihr Unternehmen zu entdecken und starten Sie im Online Großhandelsmarkt von GIT-ZONE.com, Produkte zu entdecken. GIT-ZONE.com gewährt Ihnen Zugriff zu tausenden von Lieferanten, in verschiedenen Orten und gewährt eine große Anzahl an Kategorien von denen Sie importieren können. `,
    learnMore: " Erfahren Sie mehr",
    tradeAssurance: "Handelsabsicherung",
    tradeAssuranceD: ` Wir bringen wieder das Vertrauen ins Geschäft. Unabhängig davon, ob Sie Käufer oder Verkäufer sind, führen wir die Inspektion für Sie durch. Unser Handelsabsicherungsdienst reduziert den Betrug im Geschäftsverkehr für alle Beteiligten.`,
    signUpNow: `Jetzt registrieren!`,
    exporter: "Export",
    exporterD: `Geben Sie Ihrem Produkt die Aufmerksamkeit, die es verdient, und präsentieren Sie es online. Online-Großhandelsmärkte haben nachweislich eine steigende Rentabilität und Exposition von Produkten bewiesen. Mit GIT-ZONE.com bieten wir Ihnen eine Plattform, auf der Sie Ihre Produkte präsentieren und einfach und sicher mit den Käufern kommunizieren können. Schauen Sie sich unsere`,
    investment: "Investition",
    investmentD: `GIT-ZONE.com ist die erste E-Commerce-Plattform im Nahen Osten. Wir decken Afrika, Europa und bzw. überall auf der Welt ab. Wenn Sie also in einem Land investieren möchten, helfen wir Ihnen, Ihre Idee mit dem richtigen Geschäftsplan abzustimmen.`,
  },
  map: {
    capital: "Kapital",
    language: "Sprache",
    area: "Region",
    growthRate: "Wachstumsrate",
    moreInfo: "Mehr Informationen",
    askforConsultation: "Fragen Sie nach Beratung",
    downloadAsPDF: "Als PDF herunterladen",
    submit: "Bestätigen",
    phone: "Telefon",
    note: "Notiz",
    population: "Veröffentlichung",
    currency: "Währung",
  },
  privacyPolicy: {
    _1: {
      title: "GIT-ZONE.com Cares!",
      body: `As one of the main missions of GIT-ZONE.com is to create a safe, technology-driven e-commerce platform for all traders, we are keen on protecting your personal information and securing every step of every business transaction you do on our platform. This section of the platform showcases our privacy policy and further explain our collection of your personal information and how and where it might be used. - Da eine der Hauptaufgaben von GIT-ZONE.com darin besteht, eine sichere, technologiegetriebene E-Commerce-Plattform für alle Händler zu schaffen, sind wir bestrebt, Ihre persönlichen Daten zu schützen und jeden Schritt jeder von Ihnen getätigten Geschäftstransaktion auf unserer Plattform, abzusichern. In diesem Abschnitt der Plattform werden unsere Datenschutzrichtlinien vorgestellt und unsere Sammlung Ihrer persönlichen Daten sowie deren Verwendung und Verwendungszweck näher erläutert. "																									
    },																									
    _2: {																									
      title: "What information do we collect & how do we protect it?", Welche Informationen sammeln wir und wie schützen wir diese?	`,
      body_1: `-Im Rahmen Ihrer Registrierung auf GIT-ZONE.com werden Sie aufgefordert, bestimmte persönliche Informationen wie Ihren Namen, Ihre Lieferadresse, Ihre E-Mail-Adresse und / oder Telefonnummer sowie ähnliche Informationen anzugeben. Damit wir Ihre Identität überprüfen können, müssen wir möglicherweise einen gültigen Identifikationsnachweis anfordern. Außerdem müssen wir ausgewählte Finanzinformationen von Ihnen erfassen, z. B. Ihre Kreditkarten- und / oder Bankkontodaten. Wir verwenden diese Finanzinformationen zu Abrechnungszwecken. Nach Ihrer Registrierung auf der Website sollten Sie keine persönlichen Informationen (einschließlich finanzieller Informationen) irgendwo auf der Website veröffentlichen.`,
      body_2: `- Sie sind für die Sicherheit Ihres eigenen Benutzernamens und Passworts auf GIT-ZONE.com verantwortlich. Wir empfehlen, ein sicheres Passwort zu wählen und es häufig zu ändern. Bitte verwenden Sie nicht dieselben Anmeldedaten (E-Mail und Passwort) auf mehreren Websites.`,
      body_3: `That said, we do implement a variety of security measures including offering the use of a secure server. After any transaction, your private information (credit cards, financials, etc.) will not be stored on our servers. Our servers and website are security scanned routinely to protect you online. - Das heißt, wir implementieren eine Vielzahl von Sicherheitsmaßnahmen, einschließlich des Angebots der Verwendung eines sicheren Servers. Nach jeder Transaktion werden Ihre privaten Daten (Kreditkarten, Finanzdaten usw.) nicht auf unseren Servern gespeichert. Unsere Server und unsere Website werden regelmäßig auf Sicherheit überprüft, um Sie online zu schützen.	`,
    },
    _3: {
      title: "Copyright",
      body: `All content included on this site in or made available through GIT-ZONE.com portal, such as text, graphics, logos, button icons, images, audio clips, video clips, digital downloads, data compilations, and software, is the property of GIT-ZONE.com or its content suppliers and protected by international copyright laws. The compilation of all content included in or made available through GIT-ZONE.com site is the exclusive property of GIT-ZONE.com and protected by international copyright laws. All software used in any GIT-ZONE.com Service is the property of GIT-ZONE.com or its software suppliers and protected by international copyright laws"" - ""Alle Inhalte, die auf dieser Website im GIT-ZONE.com-Portal enthalten sind oder über das GIT-ZONE.com-Portal zur Verfügung gestellt werden, wie z. B. Text, Grafiken, Logos, Schaltflächensymbole, Bilder, Audioclips, Videoclips, digitale Downloads, Datenzusammenstellungen und Software, sind Eigentum von GIT-ZONE.com oder seinen Inhaltsanbietern und durch internationale Urheberrechtsgesetze geschützt. Die Zusammenstellung aller Inhalte, die auf der Website GIT-ZONE.com enthalten sind oder über diese zur Verfügung gestellt werden, ist das ausschließliche Eigentum von GIT-ZONE.com und durch internationale Urheberrechtsgesetze geschützt Alle in einem GIT-ZONE.com-Dienst verwendete Software ist Eigentum von GIT-ZONE.com oder seinen Softwarelieferanten und durch internationale Urheberrechtsgesetze geschützt. `,
    },
    _4: {
      title: "Third Party Links",
      body: ` We may include or offer third-party products or services on our website. These third-party product/services may be used for shipment of the product/s, payment collection, fraud protection, and/or credit risk reduction. These third-party sites have separate and independent privacy policies. We, therefore, have no responsibility or liability for the content and activities of these linked sites. Nonetheless, we seek to protect the integrity of our site and welcome any feedback about these sites.`,
    },
    _5: {
      title: "Benutzen wir cookies?",
      body_1: `Ja. Cookies sind kleine Dateien, die eine Site oder ihr Dienstanbieter über Ihren Webbrowser auf die Festplatte Ihres Computers überträgt (sofern Sie dies über Ihre Einstellungen zugelassen haben). Auf diese Weise können die Websites oder Dienstanbietersysteme Ihren Browser erkennen und bestimmte Informationen erfassen und speichern`,
      body_2: `We use cookies to help us remember and process the items in your shopping cart, understand and save your preferences for future visits and compile aggregate data about site traffic and site interaction so that we can offer better site experiences and tools for you in the future. We assure you that our use of cookies does not contain any personal or private details and are free from viruses. If you want to find out more information about cookies, go to `,
      body_3: `oder erfahren Sie, wie Sie sie aus Ihrem Browser entfernen können, gehen Sie zu`,
      body_4: ` Wir verwenden Google Analytics, einen Webanalysedienst von Google, Inc. („Google“), um besser zu verstehen, wie Benutzer mit dem GIT-ZONE.com-Portal interagieren. Google Analytics verwendet Cookies, um Informationen über die Nutzung unserer Website zu sammeln. Diese Informationen werden zum Erstellen von Berichten und Erstellen von Diensten verwendet, um unsere Website und die damit verbundenen Dienste zu verbessern. Die Berichte enthüllen Website-Trends, ohne einzelne Besucher zu identifizieren. Die durch das Google-Cookie erzeugten Informationen über Ihre Nutzung unserer Website (einschließlich Ihrer IP-Adresse) werden möglicherweise an Google in den USA übertragen und dort gespeichert. Google kann diese Informationen auch an Dritte weitergeben, wenn dies gesetzlich vorgeschrieben ist oder wenn diese Dritten die Informationen im Auftrag von Google verarbeiten. Google wird Ihre IP-Adresse nicht mit anderen Daten von Google verknüpfen.`,
    },
    _6: {
      title: "Reports of any abuse on GIT-ZONE.com.",
      body: `Bitte melden Sie Probleme jeglicher Art oder Verstöße gegen diese Benutzervereinbarung an GIT-ZONE.com. Wenn Sie der Meinung sind, dass Ihre Rechte an geistigem Eigentum verletzt wurden, benachrichtigen Sie bitte GIT-ZONE.com. Unbeschadet anderer Rechte und Rechtsmittel von GIT-ZONE.com gemäß dieser Benutzervereinbarung oder gesetzlich kann GIT-ZONE.com den Zugriff eines Benutzers auf die Website und / oder die Mitgliedschaft eines Benutzers auf der Website einschränken, sperren oder entziehen oder entfernen gehostete Inhalte. Auch GIT-ZONE.com. Sie können andere technische und / oder rechtliche Schritte gegen Benutzer einleiten, die Probleme oder mögliche rechtliche Verpflichtungen jeglicher Art verursachen, die Rechte an geistigem Eigentum Dritter verletzen oder die inkonsistent mit dieser Benutzervereinbarung oder unseren Richtlinien handeln`,
    },
  },
};

export default de;

import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { closeAbuseModal, openModal } from "../../store/actions/UiAction";
import axios from "../../services/axios";
import strings from "../../Localization/LNG";
import { Formik } from "formik";
import * as Yup from "yup";

function AbuseModal() {
  const IsModalOpen = useSelector(state => state.ui.abuseModal);
  const [loading, setloading] = useState(false);
  const dispatch = useDispatch();
  const lng = useSelector(state => state.lng);
  const validation = Yup.object().shape({
    email: Yup.string()
      .required(strings.abuseReport.validation.emailRequired)
      .email(strings.abuseReport.validation.emailNotValid),
    full_name: Yup.string().required(strings.abuseReport.validation.fullName),
    // order: Yup.string().required(strings.abuseReport.validation.orderRquired),
    evidence: Yup.string().required(
      strings.abuseReport.validation.evidenceRequired
    ),
    abuse_details: Yup.string().required(
      strings.abuseReport.validation.abuseDetailsRequired
    ),
    phone: Yup.string().required(strings.abuseReport.validation.phone)
  });
  return (
    <Modal
      size="lg"
      show={IsModalOpen}
      className="abuse-modal"
      dir={`${lng === "arabic" ? "rtl" : "ltr"}`}
      onHide={() => dispatch(closeAbuseModal())}
      onEscapeKeyDown={() => dispatch(closeAbuseModal())}
    >
      <button
        style={{ fontSize: "2rem" }}
        type="button"
        className="close d-inline-block"
        aria-label="Close"
      >
        <span
          aria-hidden="true"
          onClick={() => dispatch(closeAbuseModal())}
          className="float-right mr-3"
        >
          &times;
        </span>
      </button>
      <Formik
        enableReinitialize
        initialValues={{
          email: "",
          full_name: "",
          phone: "",
          abuse_type: "",
          order: "",
          evidence: "",
          abuse_details: ""
        }}
        onSubmit={async (values, { setSubmitting }) => {
          setloading(true);
          axios.post("/api/abuse", values).then(res => {
            setloading(false);
            dispatch(closeAbuseModal());
            dispatch(openModal(strings.abuseReport.successMessage));
          });
        }}
        validationSchema={validation}
      >
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          touched,
          errors
        }) => (
          <>
            <form className="mt-2" onSubmit={handleSubmit} autoComplete="off">
              <h3 className="font-weight-bold text-center text-uppercase main-color mb-4">
                {strings.abuseReport.title}
              </h3>
              <div className="row mt-2">
                <div className="col-12">
                  <div className="form-group">
                    <input
                      type="text"
                      name="full_name"
                      autoComplete="off"
                      value={values.full_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder={strings.abuseReport.fullname}
                      className="form_input"
                      autoComplete="off"
                    />
                    <div
                      className={`error ${
                        lng === "arabic" ? "float-left" : "float-right"
                      } `}
                    >
                      {errors.full_name &&
                        touched.full_name &&
                        errors.full_name}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 col-sm-12">
                  <div className="form-group">
                    <input
                      className="form_input"
                      type="email"
                      autoComplete="off"
                      autoComplete="off"
                      placeholder={strings.abuseReport.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="email"
                    />
                    <div
                      className={`error ${
                        lng === "arabic" ? "float-left" : "float-right"
                      } `}
                    >
                      {errors.email && touched.email && errors.email}
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-sm-12">
                  <div className="form-group">
                    <input
                      className="form_input"
                      type="number"
                      placeholder={strings.abuseReport.phone}
                      autoComplete="off"
                      onChange={handleChange}
                      autoComplete="off"
                      onBlur={handleBlur}
                      name="phone"
                    />
                    <div
                      className={`error ${
                        lng === "arabic" ? "float-left" : "float-right"
                      } `}
                    >
                      {errors.phone && touched.phone && errors.phone}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 col-sm-12 ">
                  <div className="form-group">
                    <select
                      placeholder={strings.abuseReport.abuseType}
                      name="abuse_type"
                      value={values.abuse_type}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value="" disabled>
                        {strings.abuseReport.abuseType}
                      </option>
                      <option value="importer">
                        {strings.abuseReport.types.importer}
                      </option>
                      <option value="exporter">
                        {strings.abuseReport.types.exporter}
                      </option>
                      <option value="tradeAccurance">
                        {strings.abuseReport.types.tradeAccurance}
                      </option>
                      <option value="investment">
                        {strings.abuseReport.types.investment}
                      </option>
                      <option value="shipment">
                        {strings.abuseReport.types.shipment}
                      </option>
                      <option value="others">
                        {strings.abuseReport.types.others}
                      </option>
                    </select>
                    <div
                      className={`error ${
                        lng === "arabic" ? "float-left" : "float-right"
                      } `}
                    >
                      {errors.abuse_type &&
                        touched.abuse_type &&
                        errors.abuse_type}
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-sm-12 ">
                  <div className="form-group">
                    <input
                      name="order"
                      onChange={handleChange}
                      autoComplete="off"
                      onBlur={handleBlur}
                      value={values.order}
                      type="text"
                      placeholder={strings.abuseReport.order}
                      className="form_input"
                    />
                    <div
                      className={`error ${
                        lng === "arabic" ? "float-left" : "float-right"
                      } `}
                    >
                      {errors.order && touched.order && errors.order}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 col-sm-12">
                  <div className="form-group">
                    <textarea
                      name="evidence"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.evidence}
                      id=""
                      cols="30"
                      autoComplete="off"
                      className="h-auto form_input"
                      rows="3"
                      placeholder={strings.abuseReport.evidence}
                    ></textarea>
                    <div
                      className={`error ${
                        lng === "arabic" ? "float-left" : "float-right"
                      } `}
                    >
                      {errors.evidence && touched.evidence && errors.evidence}
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-sm-12">
                  <div className="form-group">
                    <textarea
                      name="abuse_details"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.abuse_details}
                      className="h-auto form_input"
                      cols="30"
                      rows="3"
                      autoComplete="off"
                      placeholder={strings.abuseReport.abuseDetails}
                    ></textarea>
                    <div
                      className={`error ${
                        lng === "arabic" ? "float-left" : "float-right"
                      } `}
                    >
                      {errors.abuse_details &&
                        touched.abuse_details &&
                        errors.abuse_details}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="text-center">
                    <button type="submit" className="submit_button text-center">
                      {loading ? (
                        <>
                          <div
                            className="spinner-border spinner-border-sm"
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        </>
                      ) : (
                        strings.abuseReport.submitButton
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </>
        )}
      </Formik>
    </Modal>
  );
}

export default AbuseModal;
const backButton = {
  cursor: "pointer",
  border: "none",
  padding: "8px",
  borderRadius: "6px",
  width: "11rem",
  color: "white",
  background: "#5B5756"
};
